import React from 'react';
import PropTypes from 'prop-types';
import createPersistedState from 'use-persisted-state';

import { Wrapper, Content } from './styles';

import SideBar from '~/components/Sidebar';
import Navbar from '~/components/Navbar';

function AdminLayout({ children }) {
  const usePersistedState = createPersistedState('collapsed');
  const [collapsed, setCollapsed] = usePersistedState(true);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Wrapper>
      <SideBar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <Content>
        <Navbar collapsed={collapsed} toggleCollapse={toggleCollapse} />
        {children}
      </Content>
    </Wrapper>
  );
}

export default AdminLayout;

AdminLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
