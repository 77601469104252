import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

import { BsChevronDown } from 'react-icons/bs';

import {
  Header,
  Bloco,
  Footer,
  TagWhite,
  TotalProducts,
  TotalSold,
  Percent,
  Content,
  ProductsTable,
} from './styles';

export default function ReportCategoryCanceled({
  categorie,
  color,
  total_price,
  total_amount,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Bloco>
      <Header color={color} onClick={toggle}>
        <TagWhite />
        <strong>Pedidos Cancelados</strong>
        <Percent>
          <span>
            <BsChevronDown size={20} />
          </span>
        </Percent>
      </Header>
      <Collapse isOpen={isOpen}>
        <Content>
          <ProductsTable borderless>
            <thead>
              <tr>
                <th>Quantidade</th>
                <th>Produto</th>
                <th>Preço</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {categorie.map(
                (canceledCategorie) =>
                  canceledCategorie.products &&
                  canceledCategorie.products.map((product) => (
                    <tr key={product.id}>
                      <td>{product.total_canceled_product_amount}</td>
                      <td>{product.name}</td>
                      <td>R${product.price}</td>
                      <td>R${product.total_canceled_product_price}</td>
                    </tr>
                  ))
              )}
            </tbody>
          </ProductsTable>
        </Content>
      </Collapse>
      <Footer>
        <TotalProducts>
          <span>Quantidade</span>
          <h5>{total_amount}</h5>
        </TotalProducts>
        <TotalSold>
          <span>Total</span>
          <h5>R${total_price}</h5>
        </TotalSold>
      </Footer>
    </Bloco>
  );
}
