import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

import { MdDelete, MdEdit } from 'react-icons/md';
import { FaMoneyBillAlt } from 'react-icons/fa';

import {
  Container,
  CategoriesTable,
  Header,
  StyledLink,
  Actions,
  Delete,
} from './styles';

import RegisterButton from '~/components/Buttons/RegisterButton';
import api from '~/services/api';

function PaymentForm() {
  const [paymentMethods, setPaymentMethods] = useState([]);

  // const getPaymentMethods = useCallback(async () => {
  //   try {
  //     const response = await api.get('/restaurants/payment-methods');

  //     setPaymentMethods(response.data);
  //   } catch (err) {
  //     toast.error('Erro ao carregar informações');
  //   }
  // }, []);

  // useEffect(() => {
  //   try {
  //     getPaymentMethods();
  //   } catch (err) {
  //     // console.tron.log(err);
  //   }
  // }, [getPaymentMethods]);

  // async function handleDelete(id) {
  //   try {
  //     if (window.confirm('Tem certeza que deseja deletar esta Categoria?')) {
  //       await api.delete(`/restaurants/product-category/${id}`);

  //       getPaymentMethods();

  //       toast.success('Categoria deletada com sucesso!');
  //     }
  //   } catch (error) {
  //     toast.error('Erro ao deletar categoria');
  //   }
  // }

  return (
    <Container>
      <Header>
        <div>
          <h2>Formas de pagamento</h2>
          <p>Gerencie as formas de pagamento que seu estabelecimento aceita</p>
        </div>
        <StyledLink to="/box/payment/new">
          <RegisterButton>Adicionar</RegisterButton>
        </StyledLink>
      </Header>

      <CategoriesTable borderless>
        <thead>
          <tr>
            <th />
            <th>Forma</th>
            <th>Disponível</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {paymentMethods.map((paymentMethod) => (
            <tr key={paymentMethod.id}>
              <td>
                <FaMoneyBillAlt size={25} />
              </td>
              <td>{paymentMethod.name}</td>
              <td>{paymentMethod.available ? 'Sim' : 'Não'}</td>
              <td>
                {' '}
                <Actions>
                  <StyledLink
                    to={{
                      pathname: '/box/payment-methods/edit',
                      state: { paymentMethod },
                    }}
                  >
                    <MdEdit size={20} />
                  </StyledLink>
                  <Delete>
                    <MdDelete size={20} />
                  </Delete>
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </CategoriesTable>
    </Container>
  );
}

export default PaymentForm;
