import React, { useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import {v4} from 'uuid';

import { Container, Row, Col } from 'reactstrap';
import { Wrapper, Header, Card, Head } from './styles';

import history from '~/services/history';
import api from '~/services/api';

import Input from '~/components/Form/Input';
import CheckboxInput from '~/components/Form/Checkbox';
import Select from '~/components/Form/SelectInput';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function EditComplementCategory({category, toggle, getCategories, setCount}) {
  const [complements, setComplements] = useState([]);
 
  const initialData = {
    name: category.name,
    question: category.question,
    limit: category.limit,
    available: category.available,
    optional: category.optional,
    single_choice: category.single_choice,
    additional: category.additional,
  };

  const initialComplements = category.complements.map((complement) => ({
    value: complement.id,
    label: complement.name,
  }));

  const getComplements = useCallback(async () => {
    const response = await api.get('/restaurants/product/complements');

    const data = response.data.map((complement) => ({
      value: complement.id,
      label: complement.name,
    }));

    setComplements(data);
  }, []);

  useEffect(() => {
    try {
      getComplements();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getComplements, category.id]);

  async function handleSubmit(data) {
    try {
      await api.put(`restaurants/product/complement-category/${category.id}`, {
        name: data.name,
        question: data.question,
        limit: data.limit,
        single_choice: data.single_choice,
        optional: data.optional,
        available: data.available,
        additional: data.additional,
        complements: data.complements,
      });

      toast.success('Categoria atualizada com sucesso!');
      const aleatoryId = v4();

      setCount(aleatoryId)

      getCategories();
      toggle()
    } catch (error) {
      toast.error('Falha ao salvar categoria');
    }
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Editar Categoria de complementos</h2>
        </div>
      </Header>
      <Card>
        <Form initialData={initialData} onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Nome da categoria"
                />
              </Col>
              <Col sm="12" lg="6">
                <Input
                  label="Limite"
                  name="limit"
                  type="number"
                  placeholder="1"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Input
                  label="Pergunta"
                  name="question"
                  type="text"
                  placeholder="Escolha uma opção de carne, Qual o ponto da carne..."
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <CheckboxInput label="Disponível" name="available" />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <CheckboxInput
                  label="Opcional - O cliente pode escolher ou não esta categoria"
                  name="optional"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <CheckboxInput
                  label="Escolha única - O cliente poderá escolher apenas uma opção"
                  name="single_choice"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <CheckboxInput
                  label="Adicional - Marcado, os complementos serão adicionais, tendo custo adicional ao produto, não marcado serão todos gratuitos"
                  name="additional"
                />
              </Col>
            </Row>
            <Head>
              <h3>Associar complementos à categoria</h3>
            </Head>

            <Select
              name="complements"
              isMulti
              defaultValue={initialComplements}
              options={complements}
              label="Selecione os complementos"
            />
            <SaveButton type="submit">Salvar</SaveButton>
          </Container>
        </Form>
      </Card>
    </Wrapper>
  );
}

export default EditComplementCategory;
