import styled from 'styled-components';

export const Container = styled.div`
  @page {
    size: 75mm auto;
    margin: 0;
  }
  @media print {
    * {
      background: transparent;
      color: #000;
      text-shadow: none;
      filter: none;
      -ms-filter: none;
    }
    html,
    body {
      width: 75mm;
      height: auto;
    }
    h1 {
      margin-right: auto;
    }
    img {
      width: 70mm;
      height: auto;
    }
  }
`;

export const Table = styled.div`
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 50px;
  margin-right: auto;

  img {
    width: 120px;
  }

  strong {
    font-size: 32px;
  }
`;

export const Address = styled.div`
  padding-top: 2px;
  width: 110mm;
  text-align: center;
  font-size: 25px;
  margin-right: auto;
`;

export const Order = styled.div`
  padding-top: 5px;
  width: 110mm;
  text-align: center;
  font-size: 25px;
  margin-right: auto;
`;

export const Restaurant = styled.div`
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 30px;
  margin-right: auto;
  padding-bottom: 15px;
  border-bottom: solid black 1px;
`;

// export const Body = styled.div`
//   width: 110mm;
//   clear: both;
// `;

// export const Phone = styled.div`
//   text-align: left;
//   padding-left: 5px;
//   font-size: 25px;
//   padding-top: 10px;
//   float: left;
// `;

export const Body = styled.div`
  padding-top: 5px;
  padding: 10px;
  width: 110mm;
  clear: both;
  display: block;
`;

export const Phone = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  float: left;
`;

export const Price = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 30px;
  float: right;
`;

export const Time = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 25px;
  padding-top: 10px;
  float: right;
`;

export const Items = styled.div`
  width: 110mm;
  padding-top: 50px;
  style: block;
  border-top: 1px solid black;
`;

export const Item = styled.div`
  padding-top: 15px;
  padding-left: 10px;
`;

export const ItemName = styled.div`
  font-size: 30px;
  width: 110mm;
`;

export const ComplementsTitle = styled.div`
  font-size: 20px;
`;

export const ComplementsPrice = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 20px;
  float: right;
`;

export const Complements = styled.div`
  font-size: 20px;
  padding-left: 15px;
`;

export const Obs = styled.div`
  font-size: 20px;
`;

export const Footer = styled.div`
  margin-top: 20px;
  padding-top: 30px;
  border-top: solid black 1px;
  font-size: 25px;
  text-align: center;
  width: 110mm;
`;

export const TotalDiv = styled.div`
  padding-top: 5px;
  margin-top: 20px;
  border-top: solid black 1px;
  padding-bottom: 5px;
  font-size: 25px;
  text-align: center;
  width: 110mm;
  padding-bottom: 15px;
`;

export const TotalRow = styled.div`
  margin-top: 5px;
  width: 110mm;
  clear: both;
  display: block;
`;

export const TotalName = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: 25px;
  padding-top: 5px;
  float: left;
`;

export const TotalPrice = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 25px;
  padding-top: 5px;
  float: right;
`;

export const NamePrice = styled.div`
  width: 100%;
  display: flex;
`;

export const PriceItem = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 25px;
  width: 35%;
  float: right;
`;
