import React from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import {v4} from 'uuid'

import { Container, Row, Col } from 'reactstrap';
import { Wrapper, Header, Card } from './styles';

import history from '~/services/history';
import api from '~/services/api';

import CheckboxInput from '~/components/Form/Checkbox';
import Input from '~/components/Form/Input';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function EditComplement({complement, toggle, getCategories, getComplements, setCount}) {

  const initialData = {
    name: complement.name,
    price: complement.price,
    limit: complement.limit,
    available: complement.available,
  };

  async function handleSubmit(data) {
    try {
      await api.put(`restaurants/product/complements/${complement.id}`, {
        name: data.name,
        price: parseFloat(data.price).toFixed(2),
        limit: data.limit,
        available: data.available,
      });

      toast.success('Complemento atualizado com sucesso!');

      const aleatoryId = v4()

      setCount(aleatoryId)
     
      getComplements();
      getCategories();
      toggle();

    } catch (error) {
      toast.error('Falha ao atualizar Complemento');
      console.log(error.message);
    }
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Editar complemento</h2>
        </div>
     
      </Header>
      <Card>
        <Form initialData={initialData} onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Nome do complemento"
                />
              </Col>
              <Col sm="12" lg="6">
                <Input
                  label="Preço"
                  name="price"
                  type="text"
                  placeholder="R$xx,xx"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Limite"
                  name="limit"
                  type="number"
                  placeholder="Limite individual"
                />
              </Col>
              <Col sm="12" lg="6">
                <CheckboxInput label="Disponível" name="available" />
              </Col>
            </Row>
            <SaveButton type="submit">Cadastrar</SaveButton>
          </Container>
        </Form>
      </Card>
    </Wrapper>
  );
}

export default EditComplement;
