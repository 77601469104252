import styled from 'styled-components';

export const Container = styled.div`
  @page {
    size: 75mm 200mm;
    margin: 0;
  }
  @media print {
    html,
    body {
      width: 75mm;
      height: 200mm;
    }
    h1 {
      margin-right: auto;
    }
    img {
      width: 70mm;
      height: auto;
    }
  }
`;
