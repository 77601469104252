import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

import { Row, Col, Container } from 'reactstrap';
import { Wrapper, Header, Card, StyledForm } from './styles';

import history from '~/services/history';
import api from '~/services/api';

import Input from '~/components/Form/Input';
import CheckboxInput from '~/components/Form/Checkbox';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function StorePayment() {
  const formRef = useRef(null);
  const location = useLocation();

  const { paymentMethod } = location.state;

  const initialData = {
    name: paymentMethod.name,
    available: paymentMethod.available,
  };

  async function handleSubmit(data) {
    try {
      await api.put(`restaurants/payment-methods/${paymentMethod.id}`, {
        name: data.name,
        available: data.available,
      });

      toast.success('Forma de pagamento atualizada com sucesso!');
    } catch (error) {
      // console.tron.log(error);
      toast.error(`Erro ao atualizar forma de pagamento! ${error.message}`);
    }
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Editar forma de pagamento</h2>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Container>
        <Row>
          <Col md="6 ml-auto mr-auto">
            <Card>
              <StyledForm
                initialData={initialData}
                ref={formRef}
                onSubmit={handleSubmit}
              >
                <Input
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Forma de pagamento"
                />
                <CheckboxInput label="Disponível" name="available" />
                <SaveButton type="submit">Cadastrar</SaveButton>
              </StyledForm>
            </Card>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default StorePayment;
