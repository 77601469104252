import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import {useAuth} from '../../context/AuthContext'

import { FiTrash } from 'react-icons/fi';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import {
  Container,
  BillExplanation,
  InformationDiv,
  Total,
  Paid,
  PaymentDiv,
  PaymentTable,
  FinalDiv,
  AddPaymentForm,
  AddPaymentLink,
  InputButton,
  DeletePaymentForm,
} from './styles';

import api from '~/services/api';
import InputPaymentForm from '~/components/Form/InputPaymentForm';

export default function PaymentForm({ session }) {
  const { user } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDrop = () => setDropdownOpen((prevState) => !prevState);
  const [deliveryPaymentMethods, setDeliveryPaymentMethods] = useState();
  const [withdrawalPaymentMethods, setWithdrawalPaymentMethods] = useState();
  const [payments, setPayments] = useState([]);
  const [metodo, setMetodo] = useState('Método');

  const [paymentOption, setPaymentOption] = useState([]);

  const [paid, setPaid] = useState(0);
  const [left, setLeft] = useState(0);
  const [porcent, setPorcent] = useState(0);

  const getPaymentMethods = useCallback(async () => {
    try {
      const response = await api.get(`/restaurants/payment-method/${user.id}`);

      const deliveryPaymentsFiltered = response.data.payment_methods.filter(methods => methods.delivery_active)

      const withdrawalPaymentsFiltered = response.data.payment_methods.filter( methods => methods.withdrawal_active);


      const parsedDeliveryPaymentsMehod = deliveryPaymentsFiltered.map((data) => ({
        id: data.id,
        label: data.name,
        available: data.delivery_active,
      }));
      setDeliveryPaymentMethods(parsedDeliveryPaymentsMehod);

      const parsedWithdrawalPaymentsMehod = withdrawalPaymentsFiltered.map((data) => ({
        id: data.id,
        label: data.name,
        available: data.withdrawal_active,
      }));
      setWithdrawalPaymentMethods(parsedWithdrawalPaymentsMehod);
     
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  const getPayments = useCallback(async () => {
    try {
      const response = await api.get(`restaurants/payments/${session.id}`);

      const parsedData = response.data.map((data) => ({
        id: data.id,
        value: data.payment_value,
        payment_method: data.payment_method.name,
      }));
      setPayments(parsedData);
           
      const total = response.data.reduce((acum, curr) => acum + Number(curr.payment_value) , 0);
      setPaid(total);

      const localLeft = session.total_price_with_sms - total;
      setLeft(parseFloat(localLeft.toFixed(2)));

      const porcentPaid = (total / session.total_price_with_sms) * 100;
      setPorcent(parseFloat(porcentPaid.toFixed(2)));
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [session.id, session.total_price_with_sms]);

  async function deletePayments(id) {
    if (window.confirm('Tem certeza que deseja remover esse pagamento?')) {
      await api.delete(`restaurants/payments/${id}`);
      getPayments();
    }
  }

  async function newPayments(data) {
    try {
      await api.post('restaurants/payments', {
        payment_value: Number(data.value),
        order_basket_id: session.id,
        payment_method_id: paymentOption.id,
      });
      getPayments();
      toast.success('Inserido com sucesso');
    } catch (error) {
      toast.error('Selecione o método de pagamento.');
    }
  }

  useEffect(() => {
    try {
      getPaymentMethods();
      getPayments();
    } catch (error) {
      toast.error('Erro ao carregar informações');
    }
  }, [getPaymentMethods, getPayments]);

  async function setMetodoAndPaymentOption(payment) {
    setMetodo(payment.label);
    setPaymentOption({ id: payment.id, label: payment.label });
  }

  return (
    <Container>
      <BillExplanation>
        <h5>Adicionar Pagamento(s) </h5>
        <h7>
          Aqui você adiciona o(s) pagamento(s) já feitos do pedido e confere
          quanto falta a ser pago.
        </h7>
      </BillExplanation>
      {console.log(session)}
      <InformationDiv>
        <Total>
          <span>Total</span>
          <h4>R${session.total_price}</h4>
        </Total>
        <Paid>
          <span>Restante</span>
          <h4>R${left}</h4>
        </Paid>
      </InformationDiv>
      <PaymentDiv>
        <Form onSubmit={newPayments}>
          <AddPaymentForm>
            <Dropdown isOpen={dropdownOpen} size="sm" toggle={toggleDrop}>
              <DropdownToggle color="success" caret>
                {metodo}
              </DropdownToggle>
              {session.payment_method.toString() ==='withdrawal' && !!withdrawalPaymentMethods && (
                 <DropdownMenu>
                 {withdrawalPaymentMethods.map((payment) =>          
                     <DropdownItem
                     onClick={() => setMetodoAndPaymentOption(payment)}
                       key={payment.id}
                     >
                       {payment.label}
                     </DropdownItem>                 
                 )}
               </DropdownMenu>
              )}

              {session.payment_method.toString() !=='withdrawal' && !!deliveryPaymentMethods && (
                 <DropdownMenu>
                 {deliveryPaymentMethods.map((payment) =>          
                     <DropdownItem
                       onClick={() => setMetodoAndPaymentOption(payment)}
                       key={payment.id}
                     >
                       {payment.label}
                     </DropdownItem>                 
                 )}
               </DropdownMenu>
              )}

            </Dropdown>

            <InputButton>
              <InputPaymentForm
                placeholder="R$"
                type="number"
                step="0.01"
                min="0"
                name="value"
              />
              <AddPaymentLink type="submit">+</AddPaymentLink>
            </InputButton>
          </AddPaymentForm>
        </Form>

        <PaymentTable borderless>
          <tbody>
            {payments.map((payment) => (
              <tr>
              
                <td>{payment.payment_method}</td>
               
                <td>R${payment.value}</td>
                <td />
                <td>
                  <DeletePaymentForm onClick={() => deletePayments(payment.id)}>
                    <FiTrash size={15} />
                  </DeletePaymentForm>
                </td>
              </tr>
            ))}
          </tbody>
        </PaymentTable>
      </PaymentDiv>
      <FinalDiv>
        <Total>
          <span>Pago</span>
          <h4>R${paid}</h4>
        </Total>
        <Paid>
          <span>Pago</span>
          <h4>{porcent}%</h4>
        </Paid>
      </FinalDiv>
    </Container>
  );
}
