import React, { useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

import { Container, Row, Col } from 'reactstrap';
import {
  Wrapper,
  Header,
  Card,
  CheckBoxContainer,
  CheckboxInfo,
} from './styles';

import history from '~/services/history';
import api from '~/services/api';

import ImageInput from '~/components/Form/ImageInput';
import Input from '~/components/Form/Input';
import Textarea from '~/components/Form/Textarea';
import Select from '~/components/Form/SelectInput';
import Checkbox from '~/components/Form/Checkbox';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function EditProduct({category, toggle, getCategory, product}) {
   
  const [categories, setCategories] = useState([]);
  const [complementCategories, setComplementCategories] = useState([]);

  const initialData = {
    name: product.name,
    description: product.description,
    price: product.price,
    price_promotion: product.price_promotion,
    promotion: product.promotion,
    sold_off: product.sold_off,
    available: product.available,
    product_category: {
      value: category.id,
      label: category.name,
    },
    image_id: {
      id: product.image ? product.image.id : null,
      url: product.image ? product.image.url : null,
    },
  };

  const initialComplementCategories = product.complement_categories.map(
    (category) => ({
      value: category.id,
      label: category.name,
    })
  );

  const getCategories = useCallback(async () => {
    const response = await api.get('restaurants/product-category');

    const data = response.data.map((category) => ({
      value: category.id,
      label: category.name,
    }));

    setCategories(data);
  }, []);

  const getComplementCategories = useCallback(async () => {
    const response = await api.get('restaurants/product/complement-category');

    const data = response.data.map((category) => ({
      value: category.id,
      label: category.name,
    }));

    setComplementCategories(data);
  }, []);

  useEffect(() => {
    try {
      getCategories();
      getComplementCategories();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getCategories, getComplementCategories]);

  async function handleSubmit(data) {
    try {
      const formattedPrice = parseFloat(data.price.replace(',', '.')).toFixed(
        2
      );

      const formattedPromotionPrice =
        data.price_promotion.length > 0
          ? parseFloat(data.price_promotion.replace(',', '.')).toFixed(2)
          : null;

      const parsedData = {
        name: data.name,
        description: data.description,
        price: formattedPrice,
        product_category_id: data.product_category,
        promotion: data.promotion,
        available: data.available,
        sold_off: data.sold_off,
        complement_categories: data.complement_categories,
        price_promotion: formattedPromotionPrice,
      };

      if (data.product_category) {
        parsedData.product_category_id = data.product_category;
      }

      if (data.image_id) {
        parsedData.image_id = Number(data.image_id);
      }
      if (data.thumbnail_id) {
        parsedData.thumbnail_id = Number(data.thumbnail_id);
      }
      await api.put(`restaurants/products/${product.id}`, parsedData);

      toast.success('Produto atualizado com sucesso!');
      
      getCategories()
      getCategory()
      toggle();
    } catch (error) {
      toast.error('Falha ao atualizar produto');
      console.log(error.message);
    }
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Editar Produto</h2>
        </div>
        
      </Header>
      <Card>
        <Form initialData={initialData} onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col sm="12">
                <ImageInput name="image_id" />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Nome do Produto"
                />
              </Col>
              <Col sm="12" lg="6">
                <Input
                  label="Atributo"
                  name="promotion"
                  type="text"
                  placeholder="Dose dupla, sugestão, vegano..."
                  maxlength="20"
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Textarea
                  label="Descrição"
                  name="description"
                  type="textarea"
                  placeholder="Descrição do Produto"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Preço"
                  name="price"
                  type="text"
                  placeholder="R$12,00"
                />
              </Col>
              <Col sm="12" lg="6">
                <Input
                  label="Preço promocional"
                  name="price_promotion"
                  type="text"
                  placeholder="Preço promocional, caso deseje que tenha"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Select
                  label="Categoria"
                  name="product_category"
                  options={categories}
                />
              </Col>
            </Row>
            <CheckBoxContainer>
              <Col sm="6">
                <Col sm="12">
                  <Checkbox label="Produto Disponível" name="available" />
                </Col>
                <Col sm="12">
                  <Checkbox label="Produto Esgotado" name="sold_off" />
                </Col>
              </Col>
              <Col sm="6">
                <CheckboxInfo>
                  <h4> Legenda</h4>
                  <div>
                    <span>Disponível</span>
                    <p>
                      Se este produto está disponível e deve aparecer no
                      cardápio
                    </p>
                  </div>
                  <div>
                    <span>Esgotado</span>
                    <p>
                      Se este produto irá aparecer no cardápio, porém como
                      esgotado. O cliente não poderá pedir
                    </p>
                  </div>
                </CheckboxInfo>
              </Col>
            </CheckBoxContainer>
          </Container>
          <h4>Associar Categorias de complementos ao seu produto</h4>

          <Select
            name="complement_categories"
            isMulti
            defaultValue={initialComplementCategories}
            options={complementCategories}
            label="Selecione as categorias de complementos"
          />
          <SaveButton type="submit">Salvar</SaveButton>
        </Form>
      </Card>
    </Wrapper>
  );
}

export default EditProduct;
