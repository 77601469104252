import React from 'react';
import { useAuth } from '../../context/AuthContext';

import {
  Container,
  Table,
  Restaurant,
  Body,
  Phone,
  Time,
  Items,
  Item,
  ItemName,
  Complements,
  ComplementsTitle,
  Obs,
  Footer,
  Price,
  ComplementsPrice,
  TotalDiv,
  TotalRow,
  TotalName,
  TotalPrice,
  Address,
} from './styles';

import formatTime from '~/services/formatTime';
// import formatPhone from '~/services/formatPhoneString';
import logoTakeat from '~/assets/img/takeat_preto.png';
import formatCompleteDate from '~/services/formatCompleteDate';

class PrintNewOrderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: props.item,
      restaurant: props.restaurant,
      user: props.user,
      paymentStatus: props.paymentStatus,
    };
  }

  render() {
    const { item, restaurant, paymentStatus } = this.state;

    const paymentMethods = [
      {
        id: 14,
        name: 'Diners Club Crédito',
        keyword: 'diners_club_credito',
        is_active: true,
      },
      {
        id: 9,
        name: 'MasterCard Débido',
        keyword: 'mastercard_debito',
        is_active: true,
      },
      {
        id: 15,
        name: 'Diners Club Débito',
        keyword: 'diners_club_debito',
        is_active: true,
      },
      { id: 7, name: 'Visa Débito', keyword: 'visa_debito', is_active: true },
      { id: 10, name: 'Elo Crédito', keyword: 'elo_credito', is_active: true },
      { id: 6, name: 'Visa Crédito', keyword: 'visa_credito', is_active: true },
      { id: 11, name: 'Elo Débito', keyword: 'elo_debito', is_active: true },
      {
        id: 17,
        name: 'American Express Débito',
        keyword: 'american_express_debito',
        is_active: true,
      },
      { id: 4, name: 'Dinheiro', keyword: 'dinheiro', is_active: true },
      {
        id: 12,
        name: 'Hipercard Crédito',
        keyword: 'hipercard_credito',
        is_active: true,
      },
      {
        id: 13,
        name: 'Hipercard Débito',
        keyword: 'hipercard_debito',
        is_active: true,
      },
      {
        id: 8,
        name: 'MasterCard Crédito',
        keyword: 'mastercard_credito',
        is_active: true,
      },
      {
        id: 16,
        name: 'American Express Crédito',
        keyword: 'american_express_credito',
        is_active: true,
      },
      {
        name: 'Pix',
        keyword: 'pix',
        is_active: true,
      },
      {
        name: 'PicPay',
        keyword: 'picpay_offline',
        is_active: true,
      },
      {
        name: 'PicPay',
        keyword: 'picpay',
        is_active: true,
      },
    ];
    // console.log(paymentMethods.filter((m) => m.keyword === 'dinheiro'));
    const paymentMethod = paymentMethods.filter(
      (m) => m.keyword === item.payment_method
    );
    function upperFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const sms_price = item.sms_service_price;
    let client_info = '';

    if (!item.buyer.first_food_court_order) {
      client_info = 'CLIENTE FREQUENTE';
    } else {
      client_info = 'CLIENTE NOVO';
    }

    let troco = '0.00';
    if (item.user_change) {
      troco =
        parseFloat(item.user_change) - parseFloat(item.total_price_with_sms);
      troco = troco.toFixed(2);
    }

    if (item.discount === '') {
      item.discount = '0.00';
    }

    return (
      <Container>
        <Table>
          <img src={logoTakeat} alt="TAKEAT" />
        </Table>
        <Table>
          <strong>Pedido #{item.attendance_password.password_number}</strong>
        </Table>
        {item.delivery_type === 'delivery' && (
          <Table style={{ fontSize: '40px' }}>
            <strong>Delivery</strong>
          </Table>
        )}
        <Restaurant>{restaurant.fantasy_name}</Restaurant>
        <Body>
          <Phone>
            <strong>{upperFirst(item.buyer.name)}</strong>
          </Phone>
          <Time>
            <strong>{formatCompleteDate(item.start_time)}</strong>
          </Time>
        </Body>
        <Body>
          <Phone>
            <strong>
              <u>{paymentStatus.toUpperCase()}</u>
            </strong>
          </Phone>
        </Body>
        <Body>
          <Phone>
            <strong>{item.buyer.phone}</strong>
          </Phone>
        </Body>
        <Body>
          <Phone>
            <strong>{client_info}</strong>
          </Phone>
        </Body>
        <Body>
          <Phone>
            <strong>
              PAGAMENTO:{' '}
              {paymentMethod.length !== 0 ? paymentMethod[0].name : ''}
            </strong>
          </Phone>
        </Body>
        <Body>
          <Phone>
            <strong>{item.buyer.email}</strong>
          </Phone>
        </Body>
        {item.delivery_type === 'delivery' && (
          <Body style={{ borderTop: '1px solid black' }}>
            <Address style={{ textAlign: 'left' }}>
              <strong>Delivery</strong>
            </Address>
            <Address>
              <strong>{item.delivery_address.city}</strong>
            </Address>
            <Address>
              <strong>{item.delivery_address.neighborhood}</strong>
            </Address>
            <Address>
              <strong>
                Rua {item.delivery_address.street}, Nº{' '}
                {item.delivery_address.number}
              </strong>
            </Address>
            <Address>
              <strong>{item.delivery_address.complement}</strong>
            </Address>
            <Address>
              <strong>{item.delivery_address.reference}</strong>
            </Address>
          </Body>
        )}
        <Body>
          <Phone>Pedido</Phone>
        </Body>
        <Items>
          {item.orders.map((order) => (
            <Item key={order.id}>
              <ItemName>
                <strong>
                  {order.amount}x {order.product.name}
                </strong>
                <Price>
                  <strong>R${order.total_price}</strong>
                </Price>
              </ItemName>
              {order.complement_categories.map((category) => (
                <div key={category.id}>
                  <ComplementsTitle>
                    <strong>{category.complement_category.name}:</strong>
                  </ComplementsTitle>
                  {category.order_complements.map((complement) => (
                    <React.Fragment key={complement.id}>
                      <Complements>
                        <strong>
                          - {complement.amount}x {complement.complement.name}{' '}
                        </strong>
                        <ComplementsPrice>
                          <strong>R${complement.complement.price}</strong>
                        </ComplementsPrice>
                      </Complements>
                    </React.Fragment>
                  ))}
                </div>
              ))}
              {order.details !== '' && (
                <Obs>
                  <strong>Obs: {order.details}</strong>
                </Obs>
              )}
            </Item>
          ))}
          {/* <Item>
            <NamePrice>
              <ItemName>
                <strong></strong>
              </ItemName>
              <Price>
                <strong>R${sms_price}</strong>
              </Price>
            </NamePrice>
          </Item> */}
        </Items>
        <TotalDiv>
          {item.will_receive_sms && (
            <TotalRow>
              <TotalName>
                <strong>Serviço de SMS</strong>
              </TotalName>
              <TotalPrice>
                <strong>R${sms_price}</strong>
              </TotalPrice>
            </TotalRow>
          )}
          {item.delivery_type === 'delivery' && (
            <TotalRow>
              <TotalName>
                <strong>Taxa de Entrega</strong>
              </TotalName>
              <TotalPrice>
                <strong>R${item.delivery_tax_price || '0.00'}</strong>
              </TotalPrice>
            </TotalRow>
          )}
          <TotalRow>
            <TotalName>
              <strong>Desconto</strong>
            </TotalName>
            <TotalPrice>
              <strong>R${item.discount}</strong>
            </TotalPrice>
          </TotalRow>
          <TotalRow>
            <TotalName>
              <strong>TOTAL</strong>
            </TotalName>
            <TotalPrice>
              <strong>R${item.total_price_with_sms}</strong>
            </TotalPrice>
          </TotalRow>
        </TotalDiv>

        {item.payment_method === 'dinheiro' && (
          <TotalDiv>
            <TotalRow>
              <TotalName>
                <strong>Cliente Pediu Troco Para</strong>
              </TotalName>
              <TotalPrice>
                <strong>
                  R$
                  {item.user_change === '0.00'
                    ? item.total_price_with_sms
                    : item.user_change}
                </strong>
              </TotalPrice>
            </TotalRow>
            <TotalRow>
              <TotalName>
                <strong>Troco</strong>
              </TotalName>
              <TotalPrice>
                <strong>R${item.user_change > 0 ? troco : '0.00'}</strong>
              </TotalPrice>
            </TotalRow>
          </TotalDiv>
        )}

        <Footer>
          <strong>{`Obrigado por escolher ${restaurant.fantasy_name} !`}</strong>
        </Footer>
      </Container>
    );
  }
}

export default PrintNewOrderComponent;
