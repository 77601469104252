import React from 'react';

import { Container } from './styles';

import ProductCategory from './Categories';

import ProductComplementHeader from '~/components/Products/ProductComplementHeader';

function Products() {
  return (
    <Container>
      <ProductComplementHeader />
      <ProductCategory />
    </Container>
  );
}

export default Products;
