import React, { useState, useRef, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Container, Row, Col } from 'reactstrap';
import getValidationErrors from '../../../services/getValidationErrors';
import RadioInput from '~/components/Form/RadioInput';
import { format, startOfDay, subHours} from 'date-fns';
import { Wrapper, Header, Card, StyledForm } from './styles';

import history from '~/services/history';
import api from '~/services/api';

import Input from '~/components/Form/Input';
import Checkbox from '~/components/Form/Checkbox';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function EditTicket() {
  const location = useLocation();
  const formRef = useRef(null);
  const { ticket } = location.state;
  console.log(ticket);

  console.log(ticket.discount_type);
  const [discountType, setDiscountType] = useState(ticket.discount_type);
  const [type, setType] = useState(ticket.discount_type === "absolut" ? "Desconto (R$)" : "Desconto (%)");
  const today = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd");
  }, []);
  
  function changeType(){
    type === "Desconto (%)" ? setType("Desconto (R$)") : setType("Desconto (%)");
    discountType === "percentage" ? setDiscountType("absolut") : setDiscountType("percentage");
  }
  const [limitDate] = useState(ticket.limit_date === null ? null : format(startOfDay(new Date(ticket.limit_date)), "yyyy-MM-dd"));
  const [expirationDate, setExpirationDate] = useState(ticket.limit_date === null ? null : format(startOfDay(new Date(ticket.limit_date)), "yyyy-MM-dd"));

  const [active, setActive] = useState(ticket.active);
  const [isPublic, setIsPublic] = useState(ticket.is_public);
  const [limited, setLimited] = useState(ticket.buyer_limit_buy === null ? false : true);
  const [expires, setExpires] = useState(ticket.limit_date === null ? false : true);

  const [ticketLimit, setTicketLimit] = useState(ticket.buyer_limit_buy);

  function changeActive(){
    setActive(!active);
  }

  function changeIsPublic(){
    setIsPublic(!isPublic);
  }

  function changeLimited(){
    setLimited(!limited);
  }

  function changeExpires(){
    setExpires(!expires);
    !expires ? setExpirationDate(today+"T03:00:00") : setExpirationDate(null);
  }

  let discount_initial_data = ticket.discount;

  if (discountType !== "absolut"){
    discount_initial_data = parseFloat(discount_initial_data) * 100;
    discount_initial_data = discount_initial_data.toString();
  }
  const initialData = {
    name: ticket.name,
    title: ticket.title,
    description: ticket.description,
    code: ticket.code,
    // discount_type: ticket.discount_type,
    discount: (parseFloat(discount_initial_data).toFixed(2)).toString(),
    minimum_price: ticket.minimum_price || "0.00",
    maximum_discount: ticket.maximum_discount,
    buyer_limit_buy: ticketLimit,
    limit_date: expirationDate,
    limit_amount: ticket.limit_amount,
    active: ticket.active,
  };


  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }
      // const schema = Yup.object().shape({
      //   name: Yup.string().required('Nome obrigatório'),
      //   price: Yup.string().required('Defina o preço'),
      // });

      let discount_value = data.discount;

      if (discountType !== "absolut"){
        discount_value = parseFloat(data.discount.replace(',', '.'))/100;
        discount_value = discount_value.toString();
      } 

      let formattedPrice = 0;
      
      if (discountType !== "absolut"){
        formattedPrice = parseFloat(discount_value.replace(',', '.')).toFixed(4);
      } else {
        formattedPrice = parseFloat(discount_value.replace(',', '.')).toFixed(2);
      }

      console.log(formattedPrice);
      let dataToSend = {
        name: data.name,
        title: data.title,
        description: data.description,
        code: data.code,
        discount_type: discountType,
        discount: formattedPrice,
        limit_date: expirationDate,
        active: active,
        is_public: isPublic,
      };

      if (data.minimum_price !== ""){
        dataToSend.minimum_price = parseFloat(data.minimum_price.replace(',', '.')).toFixed(2);
      }

      if (data.maximum_discount !== ""){
        dataToSend.maximum_discount = parseFloat(data.maximum_discount.replace(',', '.')).toFixed(2);
      }

      if (data.limit_amount !== ""){
        dataToSend.limit_amount = data.limit_amount;
      }

      if (ticketLimit !== null && limited){
        dataToSend.buyer_limit_buy = ticketLimit;
      }

      await api.put(`/restaurants/discount-coupons/${ticket.id}`, dataToSend);
      toast.success('Cupom atualizado com sucesso!');
      history.push('/tickets');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        toast.error('Falha ao atualizar cupom');
      }
    }
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Editar cupom</h2>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Container>
        <Row>
          <Col md="8 ml-auto mr-auto">
            <Card>
              <StyledForm ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
                <Row>
                  <Col md="6">
                    <Input
                      label="Nome"
                      name="name"
                      type="text"
                      placeholder="60% OFF"
                    />
                    <Input
                      label="Título"
                      name="title"
                      type="text"
                      placeholder="60% de desconto!"
                    />
                    <Input
                      label="Descrição"
                      name="description"
                      type="text"
                      placeholder="Cupom top pra você!"
                    />
                    <Input
                      label="Código"
                      name="code"
                      type="text"
                      placeholder="60OFF"
                    />

                    {discountType === "absolut" ? 
                    <div>
                      <RadioInput
                        label="Cupom em Porcentagem"
                        name="discount_type"
                        value="percentage"
                        onChange={changeType}
                      />
                      <RadioInput
                        label="Cupom em Reais" 
                        name="discount_type"
                        value="absolut"
                        defaultChecked
                        onChange={changeType}
                      />
                    </div> :
                    <div>
                      <RadioInput
                        label="Cupom em Porcentagem"
                        name="discount_type"
                        value="percentage"
                        defaultChecked
                        onChange={changeType}
                      />
                      <RadioInput
                        label="Cupom em Reais" 
                        name="discount_type"
                        value="absolut"
                        onChange={changeType}
                      />
                    </div>
                    }
                    <Input
                      label={type}
                      name="discount"
                      type="text"
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      label="Valor Mínimo do Pedido"
                      name="minimum_price"
                      type="text"
                      placeholder=""
                    />
                    <Input
                      label="Desconto Máximo"
                      name="maximum_discount"
                      type="text"
                      placeholder=""
                    />
                    <Input
                      name="limit_amount"
                      type="text"
                      label="Limite de vezes que pode ser usado"
                    /> 
                     {limited ? 
                      <Checkbox
                      label="Limitar vezes que o mesmo cliente pode usar o cupom" 
                      name="active"
                      onChange={changeLimited}
                      defaultChecked
                      /> :
                      <Checkbox
                        label="Limitar vezes que o mesmo cliente pode usar o cupom" 
                        name="active"
                        onChange={changeLimited}
                        checked={false}
                      />}
                    
                    {limited && 
                      <Input
                        name="buyer_limit_buy"
                        type="text"
                        id="buyer_limit_buy"
                        placeholder="Limite de vezes que o mesmo cliente pode usar o cupom"
                        onChange={(e) => setTicketLimit(e.target.value)}
                      />
                    }

                    {expires ?
                      <Checkbox
                        label="Definir data de expiração" 
                        name="active"
                        onChange={changeExpires}
                      /> :
                      <Checkbox
                        label="Definir data de expiração" 
                        name="active"
                        checked={false}
                        onChange={changeExpires}
                      /> 
                    }

                    {expires &&
                    <Input
                      id="date"
                      label="Expira em"
                      type="date"
                      name="finalDate"
                      onChange={(e) => setExpirationDate(e.target.value+"T03:00:00")}
                      defaultValue={limitDate}
                      className="data"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />}

                    {active ? 
                    <Checkbox
                      label="Ativo" 
                      name="active"
                      defaultChecked
                      onChange={changeActive}
                    /> : 
                    <Checkbox
                      label="Ativo" 
                      name="active"
                      onChange={changeActive}
                    />}

                    {isPublic ? 
                    <Checkbox
                      label="Aparecer na lista de cupons disponíveis" 
                      name="is_public"
                      defaultChecked
                      onChange={changeIsPublic}
                    /> : 
                    <Checkbox
                      label="Aparecer na lista de cupons disponíveis" 
                      name="is_public"
                      onChange={changeIsPublic}
                    />}

                  </Col>
                </Row>
                <div style={{float: "right"}}>
                  <SaveButton type="submit">Cadastrar</SaveButton>                
                </div>
              </StyledForm>
            </Card>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default EditTicket;
