import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Pedido = styled.div`
  min-height: 110px;
  padding-top: 5px;
  background: white;
  width: 99%;
  border-top: none;
  border-bottom: solid #dee2e6 1px;
  padding: 2px;
  display: flex;
  filter: drop-shadow(0 0 0px rgba(0, 0, 0, 0.2));
`;

export const Header = styled.div`
  height: 0px;
  width: 100%;
  display: flex;
  position: absolute;
`;

export const OrderNumber = styled.span`
  color: red;
  padding: 0px;
  display: flex;
  height: 30px;
  top: 10px;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  left: 0;
  font-size: 1.3em;
  margin-top: 4px;
  margin-left: 40px;
  border: none;
  background: white;
`;

export const Time = styled.span`
  color: #17a2b8;
  align-self: center;
  float: bottom;
  position: absolute;
  clear: both;
  bottom: 0;
  font-size: 0.9rem;
  margin-bottom: 43px;
  border: none;
  color: #c82333;
  padding-bottom: 5px;
  margin-left: 40px;
`;

export const DataFormated = styled.span`
     color: #17a2b8;
  align-self: center;
  float: bottom;
  position: absolute;
  clear: both;
  bottom: 0;
  font-size: 0.7rem;
  margin-bottom: 23px;
  border: none;
  color: #c82333;
  padding-bottom: 5px;
  margin-left: 40px;
`

export const ContentBody = styled.div`
  display: flex;
  width: 100%;
  min-height: 80px;
  clear: both;

`;

export const PedidoItems = styled.div`
  height: 30px;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  left: 0;
  font-size: 1.3em;
  margin-top: 4px;
  margin-left: 10px;
  border: none;
  background: white;
`;

export const Eye = styled.button`
  height: 30px;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  right: 0;
  margin-right: 53px;
  border: none;
  background: white;
`;

export const Pencil = styled.button`
  height: 30px;
  align-self: center;
  float: left;
  position: absolute;
  clear: both;
  left: 0;
  margin-left: 10px;
  border: none;
  background: white;
`;

export const TableNumber = styled.span`
  align-self: center;
  border: none;
  color: #c82333;
  height: 30px;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  top: 10px;
  font-size: 1.2em;
  margin-top: 4px;
  margin-left: 100px;
  border: none;
  background: white;
`;

export const PhoneNumber = styled.span`
  align-self: center;
  float: bottom;
  position: absolute;
  clear: both;
  bottom: 0;
  font-size: 0.8rem;
  margin-bottom: 23px;
  margin-left: 100px;
  border: none;
  color: #218838;
  padding-bottom: 5px;
`;

export const BuyerName = styled.span`
  align-self: center;
  float: bottom;
  position: absolute;
  clear: both;
  bottom: 0;
  font-size: 0.9rem;
  margin-bottom: 43px;
  margin-left: 100px;
  border: none;
  color: #218838;
  padding-bottom: 5px;
`;

export const DeliveryType = styled.span`
  align-self: center;
  /* float: bottom; */
  position: absolute;
  clear: both;
  bottom: 0;
  top: 68px;
  font-size: 0.9rem;
  font-weight: bold;
  margin-top: 20px;
  margin-left: 100px;
  border: none;
  color: #218838;
  padding-bottom: 5px;

`;

export const Actions = styled.div`
  padding-top: 2px;
  display: flex;
  flex-direction: row;
  float: right;
  position: absolute;
  clear: both;
  right: 0;
  margin-right: 5px;
`;

export const DeleteAndEditActions = styled.div`
  display: flex;
  align-self: center; 
 
  flex-direction: column;
  height: 30px;
  margin: auto;
  margin-top: 12px; 
`;

export const AcceptButton = styled(Link)`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: #28a745;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 90%;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
    width: 80px;
  }
`;

export const SeeButton = styled(Link)`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 90%;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const InsertPaymentButton = styled(Link)`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: #f0b402;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 90%;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;