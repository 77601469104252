import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import {v4} from 'uuid';
import * as Yup from 'yup';

import { useAuth } from '~/context/AuthContext';
import getValidationErrors from '../../utils/getValidationErrors';

import { Row, Col, FormGroup, Label, Input as InputStrap } from 'reactstrap';
import { Container, Card, Header, Content } from './styles';

import api from '~/services/api';
import apiIbge from '~/services/apiIbge';
import Input from '~/components/Form/Input';
import SelectInput from '~/components/Form/SelectInput';
import SaveButton from '~/components/Buttons/SaveButton';

function ModalUserAddress({toggle, setCount}) {
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState({});
  const [cities, setCities] = useState([]);
  const [, setSelectedCity] = useState({});
  const formRef = useRef(null);
  const { user, getProfileData } = useAuth();

  
  const handleNavigate = () => {
    getProfileData();
    toggle();
  }

  const initialData = {
    // maximum_delivery_distance: user.maximum_delivery_distance,
    // delivery_tax_by_km: user.delivery_tax_by_km,
    neighborhood: user.address.neighborhood,
    number: user.address.number,
    zip_code: user.address.zip_code,
    street: user.address.street,
    state: user.address.state,
    city: user.address.city,
    
  };

  const getStatesData = useCallback(async () => {
    try {
      const response = await apiIbge.get('/localidades/estados');
      const parsedStates = response.data.map((item) => {
        return {
          label: item.nome,
          value: item.sigla,
        };
      });
      setStates(parsedStates);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  const getCitiesData = useCallback(async () => {
    try {
      console.log(selectedState);
      const response = await apiIbge.get(
        `/localidades/estados/${selectedState.value}/municipios`
      );

      console.log(response.data)
      const parsedCities = response.data.map((item) => {
        return {
          label: item.nome,
          value: item.nome,
        };
      });
      setCities(parsedCities);
      console.log(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [selectedState]);


  const handleSubmitDistanceDelivery = useCallback(async (data) => {
    try {

      if (formRef.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        street: Yup.string()
          .required('Campo obrigatório'),
        number: Yup.string()
          .matches(/^[0-9]+$/, 'Somente números, sem espaços em branco')
          .required('Número obrigatório'),
        zip_code: Yup.string()
          .matches(/^[0-9]+$/, 'Somente números, sem espaços em branco')
          .required('CEP obrigatório'),
        neighborhood: Yup.string()
          .required('Campo obrigatório'),
        city: Yup.string()
          .required('Cidade é obrigatória'),
        state: Yup.string()
          .required('Estado é obrigatório'),

      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const createData = {
        country: 'BR',
        state: data.state,
        city: data.city,
        neighborhood: data.neighborhood,
        street: data.street,
        number: data.number,
        zip_code: data.zip_code,      
       
      };
     
      await api.put('/restaurants/address', createData);
      // await api.put('/restaurants/delivery-info', {
      //   delivery_tax_by_km: data.delivery_tax_by_km,
      //   is_delivery_by_distance: true,
      //   maximum_delivery_distance: data.maximum_delivery_distance,
      // })

      toast.success('Endereço salvo com sucesso!');

      const aleatoryId = v4();
      setCount(aleatoryId)

      handleNavigate();
    } catch (err) {
      toast.error('Erro ao salvar endereço');
      const errors = getValidationErrors(err);
      if (formRef.current) {
        formRef.current.setErrors(errors);
      }
    }
  }, []);

  useEffect(() => {
    try {
      getStatesData();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getStatesData]);

  useEffect(() => {
    try {
      getCitiesData();
    } catch (err) {
      toast.error('Erro ao carregar cidades');
    }
  }, [selectedState, getCitiesData]);

  return (
    <Container>
      <Card>
          <Form onSubmit={handleSubmitDistanceDelivery} ref={formRef} initialData={initialData}>
            <Content>
            <Row>
              <h4
                style={{ paddingLeft: 10, paddingBottom: 15, paddingTop: 30 }}
              >
                Editar endereço
              </h4>
             </Row>
           <Row>
              <Col sm="12" lg="12">
                <SelectInput
                  name="state"
                  label="Selecione o estado"
                  options={states}
                  onChange={(e) => {
                    setSelectedState({ value: e.value, label: e.label });
                  }}
                 
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="12">
                <SelectInput
                  name="city"
                  label="Selecione a cidade"
                  options={cities}
                  onChange={(e) =>
                    setSelectedCity({ value: e.value, label: e.label })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="8">
                <Input
                  label="Bairro"
                  name="neighborhood"
                  type="text"
                  placeholder="Bairro..."
                  
                />
              </Col>

              <Col sm="12" lg="4">
                <Input
                  label="CEP (somente números)"
                  name="zip_code"
                  type="text"
                  placeholder="CEP..."
                />
              </Col>
            </Row>
            
            <Row>
              <Col sm="4" lg="8">
                <Input
                  label="Rua"
                  name="street"
                  type="text"
                  placeholder="Rua..."
                />
              </Col>
              <Col sm="2" lg="4">
                <Input
                  label="Número"
                  name="number"
                  type="text"
                  placeholder="Número..."
                />
              </Col>
            </Row>

            {/* <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Distância máxima de entrega (em km)"
                  name="maximum_delivery_distance"
                  type="text"
                  placeholder="Informe a distância"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Taxa de entrega por Km"
                  name="delivery_tax_by_km"
                  type="text"
                  placeholder="R$ x.xx"
                />
              </Col>
            </Row> */}

              <SaveButton type="submit">Salvar</SaveButton>
            </Content>
          </Form>
        
      </Card>
    </Container>
  );
}

export default ModalUserAddress;
