import React, { useState } from 'react';

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Badge,
} from 'reactstrap';

import {
  CategoriesTable,
  Container,
  NavDiv,
  NavDivTeste,
  ColorTag,
  LoadingDiv,
  EmptyDiv,
  NavLinkSpan,
} from './styles';

import Bill from '~/components/Bill';

export default function Bills({ sessions, getSessions, loading }) {
  const [activeTab, setActiveTab] = useState('1');

  const sessionsOpen = sessions.filter((session) => session.status === 'open');
  const sessionsClosing = sessions.filter(
    (session) => session.status === 'closing'
  );
  const sessionsFinished = sessions.filter(
    (session) => session.status === 'finished' || session.status === 'finished1'
  );

  const sessionsOpenLength = sessionsOpen.length;
  const sessionsClosingLength = sessionsClosing.length;
  const sessionsFinishedLength = sessionsFinished.length;

  return (
    <Container>
      <NavDiv>
        <NavDivTeste>
          <Nav tabs red borderless>
            <NavItem>
              <NavLink
                className={activeTab === '1' ? 'active' : ''}
                onClick={() => setActiveTab('1')}
              >
                <ColorTag color="#17a2b8" />
                <NavLinkSpan>Abertas</NavLinkSpan>{' '}
                <Badge>{sessionsOpenLength}</Badge>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={activeTab === '2' ? 'active' : ''}
                onClick={() => setActiveTab('2')}
              >
                <ColorTag color="#ffc107" />
                <NavLinkSpan>Em Fechamanto</NavLinkSpan>{' '}
                <Badge>{sessionsClosingLength}</Badge>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={activeTab === '3' ? 'active' : ''}
                onClick={() => setActiveTab('3')}
              >
                <ColorTag color="green" />
                <ColorTag color="#dc3545" />
                <NavLinkSpan>Fechadas</NavLinkSpan>{' '}
                <Badge>{sessionsFinishedLength}</Badge>
              </NavLink>
            </NavItem>
          </Nav>
        </NavDivTeste>
      </NavDiv>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <CategoriesTable borderless>
            <thead>
              <tr>
                <th>Nº</th>
                <th>Mesa</th>
                <th>Hora</th>
                <th>Clientes</th>
                <th>Individuais</th>
                <th>Pedidos</th>
                <th>Total</th>
                <th>Já Pago</th>
                <th>Status</th>
                <th> Ações </th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <LoadingDiv>
                      <Spinner color="info" />
                    </LoadingDiv>
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              </tbody>
            ) : (
              <tbody>
                {sessionsOpen.map((session) => (
                  <Bill session={session} getSessions={getSessions} />
                ))}
              </tbody>
            )}
          </CategoriesTable>
          {sessionsOpen === 0 ? (
            <EmptyDiv>
              <span>Não há comandas abertas no momento.</span>
            </EmptyDiv>
          ) : (
            <></>
          )}
        </TabPane>

        <TabPane tabId="2">
          <CategoriesTable borderless>
            <thead>
              <tr>
                <th>Nº</th>
                <th>Mesa</th>
                <th>Hora</th>
                <th>Clientes</th>
                <th>Individuais</th>
                <th>Pedidos</th>
                <th>Total</th>
                <th>Já Pago</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <LoadingDiv>
                      <Spinner color="warning" />
                    </LoadingDiv>
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              </tbody>
            ) : (
              <tbody>
                {sessionsClosing.map((session) => (
                  <Bill
                    key={session.key}
                    session={session}
                    getSessions={getSessions}
                  />
                ))}
              </tbody>
            )}
          </CategoriesTable>
          {sessionsClosing === 0 ? (
            <EmptyDiv>
              <span>Não há comandas em fechamento no momento.</span>
            </EmptyDiv>
          ) : (
            <></>
          )}
        </TabPane>

        <TabPane tabId="3">
          <CategoriesTable borderless>
            <thead>
              <tr>
                <th>Nº</th>
                <th>Mesa</th>
                <th>Hora</th>
                <th>Clientes</th>
                <th>Individuais</th>
                <th>Pedidos</th>
                <th>Total</th>
                <th>Já Pago</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <LoadingDiv>
                      <Spinner color="success" />
                    </LoadingDiv>
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              </tbody>
            ) : (
              <tbody>
                {sessionsFinished.map((session) => (
                  <Bill
                    key={session.key}
                    session={session}
                    getSessions={getSessions}
                  />
                ))}
              </tbody>
            )}
          </CategoriesTable>
          {sessionsFinished === 0 ? (
            <EmptyDiv>
              <span>Não há comandas fechadas no momento.</span>
            </EmptyDiv>
          ) : (
            <></>
          )}
        </TabPane>
      </TabContent>
    </Container>
  );
}
