import styled from 'styled-components';

export const ButtonContainer = styled.button`
  text-align: center;
  display: flex;
  align-items: center;
  background: blue;
  margin: 10px 0;
  color: #fff;
  height: 36px;
  padding: 0 15px;
  margin-top: 35px;
  font-weight: bold;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;
  right: 0;
  float: right;

  &:hover {
    background: purple;
  }

  svg {
    margin-right: 6px;
  }
`;
