import React from 'react';

import { MdAttachMoney } from 'react-icons/md';

import { BsPeople } from 'react-icons/bs';

import { FiStar } from 'react-icons/fi';

import { Rating } from '@material-ui/lab';
import {
  Header,
  Bloco,
  Content,
  Icon,
  IconTakeat,
  Value,
  Rate,
  Comment,
} from './styles';
import iconTakeat from '~/assets/img/garfinho_red.png';

export default function ReportCard({
  name,
  total,
  type,
  color,
  rate,
  comment,
}) {
  return (
    <Bloco color={color}>
      <Header>{name}</Header>
      <Content>
        <Icon>
          {type === 'cash' ? <MdAttachMoney /> : ''}
          {type === 'clients' ? <BsPeople /> : ''}
          {type === 'rate' ? <Rate>{rate === 0 ? <>—</> : rate}</Rate> : ''}
          {type === 'rateCount' ? <FiStar /> : ''}
          {type === 'takeat' ? (
            <IconTakeat>
              <img src={iconTakeat} alt="Logo Takeat" />
            </IconTakeat>
          ) : (
            ''
          )}
        </Icon>
        <Value>
          {type === 'cash' ? <>R${total}</> : ''}
          {type === 'clients' ? <>{total}</> : ''}
          {type === 'takeat' ? <>{total}</> : ''}
          {type === 'rateCount' ? <>{total}</> : ''}
          {type === 'rate' ? (
            <>
              <Rating
                name="half-rating-read"
                value={rate || 0}
                precision={0.1}
                readOnly
              />
            </>
          ) : (
            ''
          )}
        </Value>
        <Comment color={color}>
          <span>{comment}</span>
        </Comment>
      </Content>
    </Bloco>
  );
}
