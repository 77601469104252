import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';

export const Container = styled.div`
  margin: 10px;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const ComplementsArea = styled.div`
  width: 100%;
  margin: auto;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 20px;
  
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div`
  svg {
    cursor: pointer;
  }
`;

export const CategoryItemHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 30px;

`

export const CategoryItemBody = styled.div`
  width: 100%;
  display: flex;
  padding: 0 30px;
  justify-content: space-between;

 
`

export const ButtonCollapse = styled.div`
  margin-left: 50px;
  border: none;
  background: none;
  margin-bottom: 16px;
  font-weight: bold;

  cursor: pointer;
`
export const CategoryName = styled.p`
  font-weight: bold;
  font-size: 18px;
  margin-top: 32px;
  padding-top: 32px;
  color: red;

  border-top: 1px solid #33333320;
`
export const CategoryItemHeaderTitle = styled.p`
  font-weight: bold;
`