import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';

import { Row, Col } from 'reactstrap';
import { Container, Card, Header, Content } from './styles';

import api from '~/services/api';
import DefaultButton from '~/components/Buttons/DefaultButton';
import { useAuth } from '~/context/AuthContext';

function DeliveryConfig() {
  const { updateDeliveryStatus } = useAuth();
  const [isDeliveryActive, setIsDeliveryActive] = useState(false);

  const getDeliveryData = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/show');
      const { is_delivery_active } = response.data;
      setIsDeliveryActive(is_delivery_active);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  useEffect(() => {
    try {
      getDeliveryData();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getDeliveryData]);

  async function handleUpdateDelivery() {
    updateDeliveryStatus({ is_delivery_active: !isDeliveryActive });
    setIsDeliveryActive(!isDeliveryActive);
  }

  return (
    <Container>
      <Header>
        <div>
          <h2>Delivery</h2>
          <p>Configure a modalidade de delivery do seu estabelecimento!</p>
        </div>
      </Header>

      <Card>
        <Form onSubmit={() => {}}>
          <Content>
            <Row>
              <h4
                style={{ paddingLeft: 10, paddingBottom: 15, paddingTop: 30 }}
              >
                Ativação
              </h4>
              <Col md="12">
                <p style={{ textAlign: 'left' }}>
                  Caso você deseje, você pode pausar temporariamente a aceitação
                  do serviço de delivery aqui.
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <Row>
                  <Col sm="12" lg="12">
                    <DefaultButton
                      message={
                        isDeliveryActive
                          ? 'Desativar delivery'
                          : 'Ativar delivery'
                      }
                      onClick={handleUpdateDelivery}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Content>
        </Form>
      </Card>
    </Container>
  );
}

export default DeliveryConfig;
