import React from 'react';

import {
  Container,
  Table,
  Bill,
  Restaurant,
  Body,
  Phone,
  Items,
  Item,
  ItemName,
  Complements,
  Footer,
  PriceItem,
  NamePrice,
  PriceComplement,
  TotalDiv,
  TotalRow,
  TotalName,
  TotalPrice,
} from './styles';

import formatCompleteDate from '~/services/formatCompleteDate';
import formatPhone from '~/services/formatPhoneString';

class PrintBillsIndividual extends React.Component {
  constructor(props) {
    super(props);
    this.state = { item: props.item, restaurant: props.restaurant };
  }

  render() {
    const { item, restaurant } = this.state;
    const dateNow = new Date();
    const userName = item.bill.buyer
      ? formatPhone(item.bill.buyer.phone)
      : item.bill.waiter.name;

    const date = formatCompleteDate(dateNow);

    return (
      <Container>
        <Table>
          <strong>MESA {item.table?.table_number}</strong>
        </Table>
        <Bill>Comanda Individual</Bill>
        <Bill>Fechada {date}</Bill>
        <Restaurant>{restaurant.name}</Restaurant>
        <Body>
          <Phone>
            <strong>{userName}</strong>
          </Phone>
        </Body>
        <Items>
          {item.bill.order_baskets.map((basket) => (
            <>
              {basket.orders.map((order) => (
                <Item key={order.id}>
                  <NamePrice>
                    <ItemName>
                      {order.amount}x {order.product.name}
                    </ItemName>
                    <PriceItem>R${order.total_price}</PriceItem>
                  </NamePrice>
                  {order.complement_categories.map((category) => (
                    <>
                      {category.order_complements.map((complement) => (
                        <div key={complement.id}>
                          <NamePrice>
                            <Complements>
                              - {complement.amount}x{' '}
                              {complement.complement.name}{' '}
                            </Complements>
                            <PriceComplement>
                              R${complement.complement.price}
                            </PriceComplement>
                          </NamePrice>
                        </div>
                      ))}
                    </>
                  ))}
                </Item>
              ))}
            </>
          ))}
          <Item>
            <NamePrice>
              <ItemName>Serviços (10%)</ItemName>
              <PriceItem>
                R$
                {(
                  item.bill.total_service_price - item.bill.total_price
                ).toFixed(2)}
              </PriceItem>
            </NamePrice>
          </Item>
        </Items>
        <TotalDiv>
          <TotalRow>
            <TotalName>
              <strong>TOTAL</strong>
            </TotalName>
            <TotalPrice>
              <strong>R${item.bill.total_service_price}</strong>
            </TotalPrice>
          </TotalRow>
        </TotalDiv>
        <Footer>
          <strong>Obrigado por escolher a Takeat!</strong>
        </Footer>
      </Container>
    );
  }
}

export default PrintBillsIndividual;
