import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { MdEdit } from 'react-icons/md';
import { CustomInput } from 'reactstrap';

import {
  Actions,
  ActionButton,
  DeleteAndEditActions,
  ActionsInside,
} from './styles';

import api from '~/services/api';

export default function CepItem({ cep, address }) {
  const [active, setActive] = useState(cep.is_active);

  const handleActiveUpdate = useCallback(async () => {
    try {
      await api.put(
        `restaurants/delivery-address-zip-codes/change-status/${cep.id}`
      );
      setActive(document.getElementById(cep.id).checked);
      toast.success('Status do CEP atualizado.');
    } catch (err) {
      toast.error('Erro ao atualizar CEP.');
    }
  }, [cep.id]);

  const zip_code = cep.zip_code.substr(0, 5) + '-' + cep.zip_code.substr(5);
  return (
    <>
      {active ? (
        <tr key={cep.objectId}>
          <td>
            {cep.is_active ? (
              <CustomInput
                onClick={() => handleActiveUpdate()}
                type="switch"
                name="is_active"
                id={cep.id}
                defaultChecked
              />
            ) : (
              <CustomInput
                onClick={() => handleActiveUpdate()}
                type="switch"
                name="is_active"
                id={cep.id}
              />
            )}
          </td>
          <td>{zip_code}</td>
          <td>{cep.city}</td>
          <td>{cep.street}</td>
          <td>R${cep.price}</td>
          <td>
            <Actions>
              <ActionsInside>
                <DeleteAndEditActions>
                  <ActionButton
                    to={{
                      pathname: '/delivery/addresses/cep/edit',
                      state: {
                        cep,
                        zip_code,
                      },
                    }}
                    style={{ background: '#17a2b8' }}
                  >
                    <div>
                      <MdEdit size={17} style={{ marginRight: 3 }} />
                      Editar
                    </div>
                  </ActionButton>
                </DeleteAndEditActions>
              </ActionsInside>
            </Actions>
          </td>
        </tr>
      ) : (
        <tr key={cep.objectId} style={{ color: 'gray' }}>
          <td>
            {cep.is_active ? (
              <CustomInput
                onClick={() => handleActiveUpdate()}
                type="switch"
                name="is_active"
                id={cep.id}
                defaultChecked
              />
            ) : (
              <CustomInput
                onClick={() => handleActiveUpdate()}
                type="switch"
                name="is_active"
                id={cep.id}
              />
            )}
          </td>
          <td>{zip_code}</td>
          <td>{cep.city}</td>
          <td>{cep.street}</td>
          <td>R${cep.price}</td>
          <td>
            <Actions>
              <ActionsInside>
                <DeleteAndEditActions>
                  <ActionButton
                    to={{
                      pathname: '/delivery/addresses/cep/edit',
                      state: {
                        cep,
                        zip_code,
                      },
                    }}
                    style={{ background: '#17a2b8' }}
                  >
                    <div>
                      <MdEdit size={17} style={{ marginRight: 3 }} />
                      Editar
                    </div>
                  </ActionButton>
                </DeleteAndEditActions>
              </ActionsInside>
            </Actions>
          </td>
        </tr>
      )}
    </>
  );
}
