import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;

  &:nth-child(2) {
    margin-top: 82px;
    border-top: 1px solid #33333320;
    padding-top: 18px;
  }

  div {
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
    }
  }
`;
