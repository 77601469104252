import styled from 'styled-components';

export const Header = styled.div`
  justify-content: center;
  font-size: 18px;
  padding-top: 10px;
  height: 25px;
  text-align: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  font-size: 25px;
  height: 75px;
  margin-left: 5px;
  text-align: right;
  align-items: center;
  clear: both;
`;

export const Icon = styled.div`
  font-size: 25px;
  height: 30px;
  width: 30%;
  text-align: center;
  align-self: center;
`;

export const Bloco = styled.div.attrs((props) => ({
  color: props.color,
}))`

  width: 25%;
  min-width: 200px;
  height: 103px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  margin-left: 20px ;
  margin-right: 20px;
  background: white;
  border-radius: 8px;
  color: ${(props) => props.color};
  border: solid 2px ${(props) => props.color};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  text-align: center;
  transition-duration: 0.2s;

  &:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

export const Value = styled.div`
  font-size: 25px;
  height: 30px;
  width: 60%;
  right: 0;
`;

export const Comment = styled.div.attrs((props) => ({
  color: props.color,
}))`
  bottom: 0;
  right: 0;
  position: absolute;
  margin-bottom: 0px;
  width: 100%;
  text-align: center;
  justify-content: center;
  background: none;
  color: ${(props) => props.color};

  span {
    font-size: 13px;
  }
`;
