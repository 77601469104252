import React, { useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { AiOutlinePrinter, AiOutlineEye } from 'react-icons/ai';
import { MdDone } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';

import { Container, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import {
  Pedido,
  OrderNumber,
  Time,
  ContentBody,
  PhoneNumber,
  Actions,
  DeleteAndEditActions,
  AcceptButton,
  SeeButton,
  TableNumber,
  DeliveryType,
  BuyerName,
  DataFormated
} from '../styles';
import { CancelButton, Print, DisplayNoneImprimir } from './styles';
import formatDate from '~/services/formatDate';

import formatTime from '~/services/formatTime';
// import formatPhone from '~/services/formatPhoneString';

import EyeModalOrders from '~/components/EyeModalOrders';
import PrintNewOrderComponent from '~/components/PrintNewOrderComponent';

import { useAuth } from '~/context/AuthContext';

export default function OrderAndamento({
  item,
  handleItemDone,
  handlePrintItem,
  handleCancelItem,
}) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);

  const { user } = useAuth();
  const componentRef = useRef(null);

  // const userName = formatPhone(item.buyer.phone);
  const userName = item.buyer.phone;
  let paymentStatus = '';

  if (
    item.payment_status === 'paid' ||
    item.payment_status === 'completed' ||
    item.payment_status === 'succeeded'
  ) {
    paymentStatus = 'Pago';
  } else if (item.payment_status === 'canceled') {
    paymentStatus = 'Cancelado';
  } else if (item.payment_status === 'expired') {
    paymentStatus = 'Expirado';
  } else if (item.payment_status === 'waiting_payment' || 'pending') {
    paymentStatus = 'Pagamento Pendente';
  }

  return (
    <Pedido>
      <ContentBody>
        <OrderNumber>#{item.attendance_password.password_number}</OrderNumber>
        <CancelButton onClick={toggleDelete}>
          <TiDelete color="red" />
        </CancelButton>
        <TableNumber>
          <strong>{paymentStatus}</strong>
        </TableNumber>
        <Time>{formatTime(item.start_time)}</Time>
        <DataFormated>{formatDate(item.start_time)}</DataFormated>
        <BuyerName>{item.buyer.name}</BuyerName>
        <PhoneNumber>{userName}</PhoneNumber>
        <DeliveryType>{item.delivery_type === 'delivery' ? 'Delivery' : 'Retirar no balcão'}</DeliveryType>
        <Actions>
          <DeleteAndEditActions>
            <AcceptButton onClick={() => handleItemDone(item.id)}>
              <div>
                <MdDone size={17} style={{ marginRight: 3 }} />
                {item.delivery_type === 'delivery' ? 'Enviar' : 'Entregar'}
              </div>
            </AcceptButton>
            <SeeButton onClick={toggle}>
              <div>
                <AiOutlineEye size={17} style={{ marginRight: 3 }} />
                Ver
              </div>
            </SeeButton>
          </DeleteAndEditActions>
        </Actions>
      </ContentBody>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeModalOrders item={item} />
          </Container>
        </ModalBody>
        <ModalFooter>
          <ReactToPrint
            trigger={() => (
              <Print>
                <AiOutlinePrinter color="black" size={20} />
              </Print>
            )}
            content={() => componentRef.current}
          />
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalDelete} toggle={toggleDelete}>
        <ModalBody>
          <Container>
            <h3>Tem certeza que deseja cancelar esse pedido?</h3>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => handleCancelItem(item.id)}>
            Cancelar o Pedido
          </Button>
        </ModalFooter>
      </Modal>

      <DisplayNoneImprimir>
        <PrintNewOrderComponent
          restaurant={user}
          item={item}
          ref={componentRef}
          paymentStatus={paymentStatus}
        />
      </DisplayNoneImprimir>
    </Pedido>
  );
}
