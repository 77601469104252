import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ProductsTable, ButtonCollapse } from './styles'
import produce from 'immer';
import { toast } from 'react-toastify';
import { Collapse } from 'reactstrap';
import { MdExpandMore } from 'react-icons/md';

import api from '~/services/api';

import ItemProducts from '~/components/Products/ItemProducts';
import OrderProductCategoryContext from './context';

const ProductsTables = ({category, user, getFeaturedProduct, getCategories, handleDelete}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [products, setProducts] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const toggle = () => setIsOpen(!isOpen);

  const getProducts = useCallback(async () => {
    const response = await api.get(
      `/restaurants/products-custom-order/${category.id}`
    );

    setProducts(response.data.products);

    console.log('alan');
    console.log(response.data.products);

    const options = [];

    for (let i = 1; i <= response.data.count; i += 1) {
      options.push({
        label: i,
        value: i,
      });
    }
    setCategoriesOptions(options);
  }, [category.id]);

  async function move(from_product_id, to_product_id, from, to) {
    try {
      await api.put('/restaurants/products-custom-order', {
        from_product_id,
        to_product_id,
        to_position: products[to].custom_order,
        from_position: products[from].custom_order,
      });

      // console.log('swaping - from: %d, to: %d', from, to);
      // console.log('orders - from: %d, to: %d', products[from].custom_order, products[to].custom_order);

      const next = produce(products, (draft) => {
        const dragged = draft[from];

        dragged.custom_order = draft[to].custom_order;

        if (to > from) {
          for (let i = from + 1; i <= to; i++) {
            draft[i].custom_order -= 1;
          }
        } else {
          for (let i = to; i < from; i++) {
            draft[i].custom_order += 1;
          }
        }

        draft.splice(from, 1);
        draft.splice(to, 0, dragged);
      });
      // console.log('before', products);
      // console.log('after', next);
      setProducts(next);
      toast.success('Posição Atualizada com sucesso!');
    } catch (err) {
      toast.error('Erro ao atualizar posição da categoria');
      // getProducts();
    }
  }

  useEffect(() => {
    try {
      getProducts();
    } catch (err) {
      // console.tron.log(err);
      toast.error('Erro ao carregar dados');
    }
  }, [getProducts]);

  return (
    <OrderProductCategoryContext.Provider value={{ products, move }}>
      <ButtonCollapse onClick={toggle}>Exibir/ Esconder Produtos <MdExpandMore size={20} title="Mostar Produtos"/></ButtonCollapse>
      
      <Collapse isOpen={isOpen}>
        <ProductsTable borderless>
          <thead>
            <tr>
              <th>Item</th>
              <th>Preço</th>
              <th>Disponível</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <ItemProducts
                product={product}
                category={category}
                handleDelete={handleDelete}
                getFeaturedProduct={getFeaturedProduct}
                is_priority={user.is_priority}
                getCategories = {getCategories}
                key={product.id}
                index={index}
              />
            ))}
          </tbody>
        </ProductsTable>
      </Collapse>
      </OrderProductCategoryContext.Provider>
  )
}

export default ProductsTables