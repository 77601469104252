import React from 'react';

import { Container } from './styles';

class PrintComponent extends React.Component {
  render() {
    return <Container>{this.props.children}</Container>;
  }
}

export default PrintComponent;
