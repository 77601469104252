import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';

// import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import PaymentMethod from '../pages/PaymentMethods'

import Products from '../pages/Products';
import CreateProduct from '../pages/Products/Create';
import EditProduct from '../pages/Products/Edit';

import ProductCategories from '../pages/Products/Categories';
import StoreProductCategories from '../pages/Products/Categories/Store';
import EditProductCategories from '../pages/Products/Categories/Edit';

import ProductComplements from '../pages/Products/Complements';
import CreateProductComplements from '../pages/Products/Complements/Create';
import EditComplements from '../pages/Products/Complements/Edit';
import OrderProductCategory from '../pages/Products/OrderProductCategory';
import OrderProducts from '../pages/Products/OrderProducts';
import OrderProductsCategoryItem from '../pages/Products/OrderProducts/CategoryItem';

import ComplementsCategories from '../pages/Products/ComplementsCategories';
import CreateComplementsCategories from '../pages/Products/ComplementsCategories/Create';
import EditComplementsCategories from '../pages/Products/ComplementsCategories/Edit';

import Tables from '../pages/Tables';

import Orders from '../pages/Orders';
import CreateTables from '../pages/Tables/Create';
import EditTables from '../pages/Tables/Edit';
import ViewTables from '../pages/Tables/View';
import ViewBills from '../pages/Bills/View';
import CloseSolicitation from '../pages/Bills/CloseSolicitation';

import PaymentForm from '../pages/PaymentForm';
import StorePayment from '../pages/PaymentForm/StorePayment';
import EditPayment from '../pages/PaymentForm/Edit';
import Box from '../pages/Box';
import ReportsProducts from '../pages/Reports/ReportsProducts';
import PrintReportsProducts from '../pages/Reports/PrintReportsProducts';
import ReportsPrincipal from '../pages/Reports/ReportsPrincipal';
import ReportsRate from '../pages/Reports/ReportsRate';
import ReportsSMS from '../pages/Reports/ReportsSMS';
import Waiter from '../pages/Waiter';
import CreateWaiter from '../pages/Waiter/Create';
import EditWaiter from '../pages/Waiter/Edit';

import Tickets from '../pages/Tickets';
import EditTicket from '../pages/Tickets/Edit';
import NewTicket from '../pages/Tickets/Store';

import DeliveryConfig from '../pages/Delivery/Config';
import DeliveryAddresses from '../pages/Delivery/Addresses';
import CreateDeliveryAddresses from '../pages/Delivery/Addresses/Create';
import EditDeliveryAddresses from '../pages/Delivery/Addresses/Edit';
import CepDeliveryConfig from '../pages/Delivery/Addresses/Cep';
import CepDeliveryEdit from '../pages/Delivery/Addresses/Cep/Edit';
import CepDeliveryCreate from '../pages/Delivery/Addresses/Cep/Create';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      {/* <Route path="/dashboard" component={Dashboard} isPrivate /> */}
      <Route path="/profile" component={Profile} isPrivate />

      <Route
        path="/products/categories/new"
        component={StoreProductCategories}
        isPrivate
      />
      <Route
        path="/products/categories/edit"
        component={EditProductCategories}
        isPrivate
      />
      <Route
        path="/products/categories"
        component={ProductCategories}
        isPrivate
      />
      <Route path="/products/new/:category_id" component={CreateProduct} isPrivate />
      <Route path="/products/edit" component={EditProduct} isPrivate />

      <Route
        path="/products/complements/new"
        component={CreateProductComplements}
        isPrivate
      />
      <Route
        path="/products/complements/edit"
        component={EditComplements}
        isPrivate
      />
      <Route
        path="/products/complements"
        component={ProductComplements}
        isPrivate
      />
      <Route
        path="/products/complement-categories/new"
        component={CreateComplementsCategories}
        isPrivate
      />
      <Route
        path="/products/complement-categories/edit"
        component={EditComplementsCategories}
        isPrivate
      />
      <Route
        path="/products/complement-categories"
        component={ComplementsCategories}
        isPrivate
      />
      <Route
        path="/products/order-product-categories"
        component={OrderProductCategory}
        isPrivate
      />
      <Route
        path="/products/order-products"
        exact
        component={OrderProducts}
        isPrivate
      />
      <Route
        path="/products/order-products/category"
        component={OrderProductsCategoryItem}
        isPrivate
      />

      <Route path="/tables/new" component={CreateTables} isPrivate />
      <Route path="/tables/edit" component={EditTables} isPrivate />
      <Route path="/tables/view" component={ViewTables} isPrivate />
      <Route path="/tables" component={Tables} isPrivate />

      <Route path="/tickets/new" component={NewTicket} isPrivate />
      <Route path="/tickets/edit" component={EditTicket} isPrivate />
      <Route path="/tickets" component={Tickets} isPrivate />

      <Route
        path="/bills/close-solicitation"
        component={CloseSolicitation}
        isPrivate
      />
      <Route path="/bills" component={ViewBills} isPrivate />

      <Route path="/orders" component={Orders} isPrivate />

      <Route path="/box/payment/new" component={StorePayment} isPrivate />
      <Route
        path="/box/payment-methods/edit"
        component={EditPayment}
        isPrivate
      />
      <Route path="/box/payment" component={PaymentForm} isPrivate />

      <Route path="/reports/financial" component={Box} isPrivate />

      <Route path="/reports/products" component={ReportsProducts} isPrivate />
      <Route
        path="/reports/print"
        exact
        component={PrintReportsProducts}
        isPrivate
      />
      <Route path="/reports/rate" exact component={ReportsRate} isPrivate />
      <Route path="/reports/sms" exact component={ReportsSMS} isPrivate />

      <Route path="/reports" component={ReportsPrincipal} isPrivate />

      <Route path="/products" exact component={Products} isPrivate />

      <Route path="/waiters/new" exact component={CreateWaiter} isPrivate />
      <Route path="/waiters/edit" exact component={EditWaiter} isPrivate />
      <Route path="/waiters" exact component={Waiter} isPrivate />

      <Route
        path="/delivery/config"
        exact
        component={DeliveryConfig}
        isPrivate
      />
      <Route
        path="/delivery/addresses"
        exact
        component={DeliveryAddresses}
        isPrivate
      />
      <Route
        path="/delivery/payment-methods"
        exact
        component={PaymentMethod}
        isPrivate
      />
      <Route
        path="/delivery/addresses/new"
        exact
        component={CreateDeliveryAddresses}
        isPrivate
      />
      <Route
        path="/delivery/addresses/edit"
        exact
        component={EditDeliveryAddresses}
        isPrivate
      />
      <Route
        path="/delivery/addresses/cep"
        exact
        component={CepDeliveryConfig}
        isPrivate
      />
      <Route
        path="/delivery/addresses/cep/edit"
        exact
        component={CepDeliveryEdit}
        isPrivate
      />
      <Route
        path="/delivery/addresses/cep/new"
        exact
        component={CepDeliveryCreate}
        isPrivate
      />
    </Switch>
  );
}
