import React from 'react';
import { Form } from '@unform/web';
import { Row, Col } from 'reactstrap';
import Input from '../../Form/Input';
import { ProductImage, ModalFeaturedProductDiv, Title } from './styles';

import SaveButton from '~/components/Buttons/SaveButton';

export default function ModalFeaturedProduct({
  product,
  handleSubmitFeaturedProduct,
}) {

  
  return (
    <ModalFeaturedProductDiv>
      <Title>
        <h4>Adicionar Produto em Destaque</h4>
        <p>O produto em destaque é exclusivo para estabelecimentos que
        estão na vitrine principal e fazem parte de praça de aliementação.
        O produto selecionado ficará em destaque no banner da loja.</p>
      </Title>

      <Row>
        <Col md="4">
          {product.image ? (
            <ProductImage src={product.image.url} alt="Foto do produto" />
          ) : (
            <p>Sem foto</p>
          )}
        </Col>
        <Col md="8">
          <p>{product.name}</p>
          <p>{product.description}</p>
          <p>
            <b>R${product.price}</b>
          </p>
        </Col>
      </Row>
      <Form onSubmit={handleSubmitFeaturedProduct}>
        <Row>
          <Col md="12">
            <Input
              label="Legenda"
              name="title"
              type="text"
              placeholder="Ex: OFERTA DO DIA!"
              maxlength="20"
            />
          </Col>
        </Row>
        <Row>
          <Col md="4" className="ml-auto">
            <SaveButton type="submit">Salvar</SaveButton>
          </Col>
        </Row>
      </Form>
    </ModalFeaturedProductDiv>
  );
}
