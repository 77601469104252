import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

import { Form } from '@unform/web';
import { FiTrash } from 'react-icons/fi';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import {
  Container,
  StatusTag,
  BillExplanation,
  InformationDiv,
  Total,
  Paid,
  PaymentDiv,
  PaymentTable,
  FinalDiv,
  AddPaymentForm,
  InputButtonPayment,
  AddPaymentLinkOther,
  DeletePaymentForm,
} from './styles';
import api from '~/services/api';
import InputPaymentForm from '~/components/Form/InputPaymentForm';

export default function EyePaymentBills({ session }) {
  // const left = parseFloat(total) - parseFloat(paid);
  // const [isOpen, setIsOpen] = useState(true);
  // const toggle = () => setIsOpen(!isOpen);
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const toggleDrop = () => setDropdownOpen((prevState) => !prevState);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDrop = () => setDropdownOpen((prevState) => !prevState);
  const [payments, setPayments] = useState([]);
  const [paid, setPaid] = useState(0);
  const [porcent, setPorcent] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paidWithoutService, setPaidWithoutService] = useState(0);
  const [paidService, setPaidService] = useState(0);
  const [paymentOption, setPaymentOption] = useState([]);
  const [metodo, setMetodo] = useState('Método');

  const getPaymentMethods = useCallback(async () => {
    const response = await api.get('/restaurants/payment-methods');

    const parsedData = response.data.map((data) => ({
      id: data.id,
      label: data.name,
      available: data.available,
    }));
    setPaymentMethods(parsedData);
  }, []);

  const getPayments = useCallback(async () => {
    const response = await api.get('restaurants/payments', {
      params: { table_session_id: session.id },
    });

    const parsedData = response.data.payments.map((data) => ({
      id: data.id,
      value: data.payment_value,
      payment_method_id: data.payment_method_id,
    }));
    setPayments(parsedData);

    const total = response.data.total_value;
    setPaid(total);

    const porcentPaid = (total / session.total_service_price) * 100;
    setPorcent(parseFloat(porcentPaid.toFixed(2)));

    const paid_without_service =
      total > session.total_price ? session.total_price : total;
    setPaidWithoutService(paid_without_service);

    if (paid_without_service === session.total_price) {
      const paid_service = total - paid_without_service;
      setPaidService(paid_service);
    }
  }, [session.id, session.total_price, session.total_service_price]);

  async function deletePayments(id) {
    if (window.confirm('Tem certeza que deseja remover esse pagamento?')) {
      await api.delete(`restaurants/payments/${id}`);
      getPayments();
    }
  }

  async function newPayments(data) {
    try {
      await api.post('restaurants/payments', {
        payment_value: Number(data.value),
        table_session_id: session.id,
        payment_method_id: paymentOption.id,
      });
      getPayments();
      toast.success('Inserido com sucesso');
    } catch (error) {
      toast.error('Selecione o método de pagamento.');
    }
  }

  useEffect(() => {
    try {
      getPaymentMethods();
      getPayments();
    } catch (error) {
      toast.error('Erro ao carregar informações');
    }
  }, [getPaymentMethods, getPayments]);

  async function setMetodoAndPaymentOption(payment) {
    setMetodo(payment.label);
    setPaymentOption({ id: payment.id, label: payment.label });
  }

  return (
    <Container>
      <BillExplanation>
        <StatusTag color={porcent === 100 ? 'green' : '#dc3545'}>
          <span>Fechada {porcent}%</span>
        </StatusTag>
      </BillExplanation>
      <InformationDiv>
        <Total>
          <span>Total</span>
          <h4>R${session.total_service_price}</h4>
        </Total>
        <Paid>
          <span>Pago</span>
          <h4>R${paid}</h4>
        </Paid>
      </InformationDiv>
      <PaymentDiv>
        <Form onSubmit={newPayments}>
          <AddPaymentForm>
            {/* {console.tron.log(paymentMethods)} */}
            {/* <form onSubmit={() => {paymentMethods}}> */}
            {/* <Select
              name="payment_method"
              options={[{ label: 'Picpay', value: 1 }]}
              label="Selecione o método de pagamento"
            /> */}

            <Dropdown isOpen={dropdownOpen} size="sm" toggle={toggleDrop}>
              <DropdownToggle color="success" caret>
                {metodo}
              </DropdownToggle>
              <DropdownMenu>
                {paymentMethods.map((payment) =>
                  payment.available ? (
                    <DropdownItem
                      onClick={() => setMetodoAndPaymentOption(payment)}
                    >
                      {payment.label}
                    </DropdownItem>
                  ) : (
                    <></>
                  )
                )}
              </DropdownMenu>
            </Dropdown>

            <InputButtonPayment>
              <InputPaymentForm
                placeholder="R$"
                type="number"
                step="0.01"
                min="0"
                name="value"
              />
              <AddPaymentLinkOther type="submit">+</AddPaymentLinkOther>
            </InputButtonPayment>
          </AddPaymentForm>
        </Form>

        <PaymentTable borderless>
          <h6>Pagamentos</h6>
          <tbody>
            {payments.map((payment) => (
              <tr>
                {paymentMethods
                  .filter((method) => method.id === payment.payment_method_id)
                  .map((method) => (
                    <td>{method.label}</td>
                  ))}
                <td>R${payment.value}</td>
                <td>
                  <DeletePaymentForm onClick={() => deletePayments(payment.id)}>
                    <FiTrash size={15} />
                  </DeletePaymentForm>
                </td>
              </tr>
            ))}
          </tbody>
        </PaymentTable>
      </PaymentDiv>
      <FinalDiv>
        <Total>
          <span>Consumido</span>
          <h5>R${session.total_price}</h5>
        </Total>
        <Paid>
          <span>Pago</span>
          <h5>R${paidWithoutService}</h5>
        </Paid>
      </FinalDiv>
      <FinalDiv>
        <Total>
          <span>Serviços (10%)</span>
          <h5>R${(session.total_price * 0.1).toFixed(2)}</h5>
        </Total>
        <Paid>
          <span>Pago</span>
          <h5>R${paidService.toFixed(2)}</h5>
        </Paid>
      </FinalDiv>
    </Container>
  );
}
