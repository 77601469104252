import styled from 'styled-components';

export const CancelButton = styled.button`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  align-self: center;
  float: left;
  position: absolute;
  clear: both;
  left: 0;
  margin-left: 6px;
  margin-bottom: 5px;
  border: none;
  background: white;
  text-align: center;
  font-size: 26px;
  justify-content: center;

  // :hover {
  //   filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  // }
`;

export const DisplayNoneImprimir = styled.button`
  display: none;
`;
