import React, { useRef, useState, useEffect } from 'react';

import { TiDownload } from 'react-icons/ti';

import ReactToPrint from 'react-to-print';

import {
  Container,
  Delete,
  BillExplanation,
  OpenOrders,
  CloseOrders,
  PaymentTable,
  DisplayNoneImprimir,
} from './styles';

import PrintBillsIndividual from '~/components/Prints/PrintBillsIndividual';

import formatPhone from '~/services/formatPhoneString';
import { useAuth } from '~/context/AuthContext';

export default function OrdersBill({ session }) {
  const { user } = useAuth();
  const [pendingBills, setPendingBills] = useState([]);
  const [closedBills, setClosedBills] = useState([]);

  useEffect(() => {
    try {
      const pending = session.bills.filter(
        (bill) => bill.status !== 'finished'
      );
      setPendingBills(pending);

      const closed = session.bills.filter((bill) => bill.status === 'finished');
      setClosedBills(closed);
    } catch (error) {}
  }, [session.bills]);

  const componentRef = useRef(null);
  return (
    <Container>
      <BillExplanation>
        <h5>Clientes da Comanda </h5>
      </BillExplanation>
      <OpenOrders>
        <h6>
          <strong>Clientes na Mesa</strong>
        </h6>
        <PaymentTable borderless>
          <tbody>
            {pendingBills.length > 0 ? (
              pendingBills.map((bill) => {
                const userName = bill.buyer
                  ? formatPhone(bill.buyer.phone)
                  : bill.waiter.name;
                return (
                  <tr>
                    <td>{userName}</td>
                    <td>R${bill.total_service_price}</td>
                    <ReactToPrint
                      trigger={() => (
                        <Delete>
                          <TiDownload size={25} />
                        </Delete>
                      )}
                      content={() => componentRef.current}
                    />
                    {/* {console.tron.log(bill)} */}
                    <DisplayNoneImprimir>
                      <PrintBillsIndividual
                        item={{
                          bill,
                          table: session.table,
                        }}
                        restaurant={user}
                        ref={componentRef}
                      />
                    </DisplayNoneImprimir>
                  </tr>
                );
              })
            ) : (
              <p>Nenhum cliente restante!</p>
            )}
          </tbody>
        </PaymentTable>
      </OpenOrders>
      <CloseOrders>
        <h6>
          <strong>Contas Individuais Fechadas</strong>
        </h6>

        <PaymentTable borderless>
          <tbody>
            {closedBills.length > 0 ? (
              closedBills.map((bill) => {
                const userName = bill.buyer
                  ? formatPhone(bill.buyer.phone)
                  : bill.waiter.name;
                return (
                  <tr>
                    <td>{userName}</td>
                    <td>R${bill.total_service_price}</td>
                  </tr>
                );
              })
            ) : (
              <p>Nenhuma conta fechada</p>
            )}
          </tbody>
        </PaymentTable>
      </CloseOrders>
    </Container>
  );
}
