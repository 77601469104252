import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 10px;

  padding-bottom: 70px;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  margin: 20px auto;
  width: 90%;
  display: flex;
  padding: 30px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

export const Bloco = styled.div`
  margin: 20px auto;
  width: 32%;
  height: 500px;
  padding: 30px;
  display: flex;
  float: left;
  margin-right: 10px;
  background: red;
  border-radius: 8px;
`;

export const LugarPedidos = styled.div`
  display: flex;
  background-color: white;
  border: 1px solid #dee2e6;
  border-top: none;
  border-bottom-radius: 10px;
`;

export const BtnWhatsapp = styled.div`
  width: 180px;
  display: flex;
  align-items: center;
  position: fixed;
  right: 30px;
  bottom: 30px;
  display: flex;
  cursor: pointer;

  div {
    width: 150px;
    height: 50px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    font-weight: bold;
    box-shadow: 10px 5px 5px #33333320;
    border: 1px solid #33333310;
    color: #33333390;
  }

  img {
    width: 60px;
    margin-left: -16px;
  }
`;
