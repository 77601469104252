import React, { useRef, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Row, Col, Container } from 'reactstrap';
import { Wrapper, Header, Card, StyledForm } from './styles';

import history from '~/services/history';
import api from '~/services/api';
import { format, startOfDay, subHours } from 'date-fns';

import Input from '~/components/Form/Input';
import Checkbox from '~/components/Form/Checkbox';
import RadioInput from '~/components/Form/RadioInput';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function NewTicket() {
  const formRef = useRef(null);
  const [discountType, setDiscountType] = useState("percentage");
  const [type, setType] = useState("Desconto (%)");
  const today = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd");
  }, []);
  const [expirationDate, setExpirationDate] = useState(null);

  function changeType(){
    type === "Desconto (%)" ? setType("Desconto (R$)") : setType("Desconto (%)");
    discountType === "percentage" ? setDiscountType("absolut") : setDiscountType("percentage");
  }

  const [active, setActive] = useState(true);
  const [isPublic, setIsPublic] = useState(true);
  const [limited, setLimited] = useState(false);
  const [expires, setExpires] = useState(false);

  const [ticketLimit, setTicketLimit] = useState(null);

  function changeActive(){
    setActive(!active);
  }

  function changeIsPublic(){
    setIsPublic(!isPublic);
  }

  function changeLimited(){
    setLimited(!limited);
  }

  function changeExpires(){
    setExpires(!expires);
    !expires ? setExpirationDate(today+"T03:00:00") : setExpirationDate(null);
  }

  async function handleSubmit(data) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
      });

      await schema.validate(
        { name: data.name },
        {
          abortEarly: false,
        }
      );

      let discount_value = data.discount;

      if (discountType !== "absolut"){
        discount_value = parseFloat(data.discount.replace(',', '.'))/100;
        discount_value = discount_value.toString();
      } 

      let formattedPrice = 0;
      
      if (discountType !== "absolut"){
        formattedPrice = parseFloat(discount_value.replace(',', '.')).toFixed(4);
      } else {
        formattedPrice = parseFloat(discount_value.replace(',', '.')).toFixed(2);
      }
      
      let dataToSend = {
        name: data.name,
        title: data.title,
        description: data.description,
        code: data.code,
        discount_type: discountType,
        discount: formattedPrice,
        limit_date: expirationDate,
        active: active,
        is_public: isPublic,
      };

      if (data.minimum_price !== ""){
        dataToSend.minimum_price = parseFloat(data.minimum_price.replace(',', '.')).toFixed(2);
      }

      if (data.maximum_discount !== ""){
        dataToSend.maximum_discount = parseFloat(data.maximum_discount.replace(',', '.')).toFixed(2);
      }

      if (data.limit_amount !== ""){
        dataToSend.limit_amount = data.limit_amount;
      }

      if (ticketLimit !== null){
        dataToSend.buyer_limit_buy = ticketLimit;
      }

     await api.post('/restaurants/discount-coupons', dataToSend);

     toast.success('Cupom cadastrado com sucesso!');
     history.push('/tickets');
    } catch (error) {
      // console.tron.log(error);
      toast.error(`Erro ao cadastrar cupom! ${error.message}`);
    }
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Cadastrar cupons</h2>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Container>
        <Row>
          <Col md="8 ml-auto mr-auto">
            <Card>
              <StyledForm ref={formRef} onSubmit={handleSubmit}>
                <Row>
                  <Col md="6">
                    <Input
                      label="Nome"
                      name="name"
                      type="text"
                      placeholder="60% OFF"
                    />
                    <Input
                      label="Título"
                      name="title"
                      type="text"
                      placeholder="60% de desconto!"
                    />
                    <Input
                      label="Descrição"
                      name="description"
                      type="text"
                      placeholder="Cupom top pra você!"
                    />
                    <Input
                      label="Código"
                      name="code"
                      type="text"
                      placeholder="60OFF"
                    />
                    <RadioInput
                      label="Cupom em Porcentagem"
                      name="discount_type"
                      value="percentage"
                      defaultChecked
                      onChange={changeType}
                    />
                    <RadioInput
                      label="Cupom em Reais" 
                      name="discount_type"
                      value="absolut"
                      onChange={changeType}
                    />
                    <Input
                      label={type}
                      name="discount"
                      type="text"
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      label="Valor Mínimo do Pedido"
                      name="minimum_price"
                      type="text"
                      placeholder=""
                    />
                    <Input
                      label="Desconto Máximo"
                      name="maximum_discount"
                      type="text"
                      placeholder=""
                    />
                    
                    <Input
                      name="limit_amount"
                      type="text"
                      label="Limite de vezes que pode ser usado"
                    /> 
                    
                    <Checkbox
                      label="Limitar vezes que o mesmo cliente pode usar o cupom" 
                      name="active"
                      onChange={changeLimited}
                    />
                    {limited && 
                    <Input
                      name="buyer_limit_buy"
                      type="text"
                      placeholder="Limite de vezes que o mesmo cliente pode usar o cupom"
                      onChange={(e) => setTicketLimit(e.target.value)}
                    />}

                    <Checkbox
                      label="Definir data de expiração" 
                      name="active"
                      onChange={changeExpires}
                    />
                    {expires &&
                    <Input
                      id="date"
                      label="Expira em"
                      type="date"
                      name="finalDate"
                      onChange={(e) => setExpirationDate(e.target.value+"T03:00:00")}
                      defaultValue={today}
                      className="data"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />}
                    <Checkbox
                      label="Ativo" 
                      name="active"
                      defaultChecked
                      onChange={changeActive}
                    />
                    <Checkbox
                      label="Aparecer na lista de cupons disponíveis" 
                      name="active"
                      defaultChecked
                      onChange={changeIsPublic}
                    />
                  </Col>
                </Row>
                <div style={{float: "right"}}>
                  <SaveButton type="submit">Cadastrar</SaveButton>                
                </div>
              </StyledForm>
            </Card>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default NewTicket;
