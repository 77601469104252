import React, { useState } from 'react';

import { AiOutlineEye } from 'react-icons/ai';
import { GiTable } from 'react-icons/gi';
import { Container, Modal, ModalBody } from 'reactstrap';
import EyeBillsTable from '~/components/Tables/CloseOrder/Eye/EyeBillsTable';

import {
  Header,
  Bloco,
  TableNumber,
  Icon,
  Eye,
  Total,
  EyeButton,
} from './styles';

export default function Table({ session }) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <Bloco>
      <Header>
        <TableNumber>{}</TableNumber>
        <Total>
          <span>R${session.total_service_price}</span>
        </Total>
        <Icon>
          <span>{session.table?.table_number}</span>
          <span>
            <GiTable color="#00420f" size={30} />
          </span>
        </Icon>
      </Header>

      <Eye>
        <EyeButton onClick={toggle}>
          <AiOutlineEye color="#00420f" size={25} />
        </EyeButton>
      </Eye>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeBillsTable item={session} />
          </Container>
        </ModalBody>
      </Modal>
    </Bloco>
  );
}
