import React, { createContext, useState, useContext, useEffect } from 'react';
import Notification from 'react-web-notification';

import { Howl } from 'howler';
import Sound from '~/assets/sounds/phoneNotification.mp3';
// import SoundOgg from '~/assets/sounds/notification.ogg';

import icon from '~/assets/img/garfinho_red.png';

import history from '../../services/history';
import api from '../../services/api';

import { useAuth } from './../AuthContext';

import isMobile from '~/utils/isMobile';

import { firebaseToken, isSupported } from '../../firebaseInit';

const NotificationContext = createContext();

const is_mobile = isMobile();

const NotificationProvider = ({ children }) => {
  const [notificationTitle, setNotificationTitle] = useState('');
  const [options, setOptions] = useState({ icon });
  const [ignore, setIgnore] = useState(true);

  /* Indica se o token do firebase já foi registrado */
  const [deviceToken, setDeviceToken] = useState(null);

  const { user } = useAuth();

  async function register(token) {
    await api.post('public/device/register', {
      restaurant_id: user.id,
      token,
    });
    console.log('token registered');
  }

  function enableFirebase() {
    if (!user || !isSupported) return;

    console.log('enableFirebase');
    if (deviceToken === null) {
      setDeviceToken('');

      firebaseToken()
        .then(async (token) => {
          if (!deviceToken) {
            console.log('Token do firebase', token);
            setDeviceToken(token);
            await register(token);
          }
        })
        .catch((err) => {
          console.log('unable to register token', err);
          setDeviceToken(null);
        });
    }
  }

  /* Habilitando firebase quando o provider for contruído */

  useEffect(
    () => {
      enableFirebase();
    },
    [user]
  );

  function handlePermissionGranted() {
    let ignore = false;
    if (is_mobile) {
      ignore = true;
    }
    setIgnore(ignore);
  }

  function handlePermissionDenied() {
    // alert('Você não conseguirá ver notificações dos seus novos pedidos');
    console.log('Notification Permission Denied');
    setIgnore(true);
  }

  function handleNotSupported() {
    alert('Seu navegador não é compatível com o sistema de notificações');
    console.log('Web Notification not Supported');
    setIgnore(true);
  }

  function sendNotification({ title, body }) {
    if (is_mobile) {
      if (('serviceWorker' in navigator) && navigator.serviceWorker) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification(title, { body, icon: './favicon.png' });
        });
      }
      return;
    }
    setOptions({ icon, body });
    setNotificationTitle(title);
  }

  function playSoundHowler() {
    const sound = new Howl({
      src: Sound,
      volume: 1,
      preload: true,
      html5: true,
    });
    sound.play();
  }

  // function playSound(filename) {
  //   document.getElementById('sound').play();
  // }

  function handleNotificationOnShow(e, tag) {
    playSoundHowler();
    console.log(e, `Notification shown tag:${tag}`);
  }

  function handleOnClick(e) {
    window.focus();
    history.push('/orders');
  }

  return (
    <NotificationContext.Provider value={{ sendNotification }}>
      <>
        <Notification
          ignore={ignore && notificationTitle !== ''}
          title={notificationTitle}
          askAgain
          requireInteraction
          timeout={30000}
          onClose={() => setNotificationTitle('')}
          onShow={handleNotificationOnShow}
          onClick={handleOnClick}
          options={options}
          notSupported={handleNotSupported}
          onPermissionDenied={handlePermissionDenied}
          onPermissionGranted={handlePermissionGranted}
          // swRegistration={navigator.serviceWorker}
        />
        {/* <audio id="sound" preload="auto">
          <source src={Sound} type="audio/mpeg" />
          <embed hidden autostart="false" loop={false} src={Sound} />
        </audio> */}
      </>
      {children}
    </NotificationContext.Provider>
  );
};

function useNotification() {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error(
      'useNotification must be used within an NotificationProvider'
    );
  }

  return context;
}

export { NotificationProvider, useNotification };
