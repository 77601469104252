import styled from 'styled-components';

export const Icon = styled.span`
  float: left;
  margin-left: 15px;
`;

export const ItemsCount = styled.span`
  background: white;
  color: black;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  height: 25px;
  width: 25px;
  font-size: 15px;
  border-radius: 50%;
  float: right;
  display: flex;
`;

export const Content = styled.div`
  background-color: white;
  color: black;
  padding-top: 0px;
  overflow-y: auto;
`;

export const LoadingDiv = styled.div`
  padding-top: 20px;
  justify-content: center;
  height: 60px;
  width: 100%;
  text-align: center;
`;
