import React from 'react';
import { toast } from 'react-toastify';

import {
  Container,
  Table,
  Bill,
  Restaurant,
  Body,
  Phone,
  Price,
  Items,
  Item,
  ItemName,
  Complements,
  Footer,
  PriceItem,
  NamePrice,
  PriceComplement,
  AlreadyClosed,
  TotalDiv,
  TotalRow,
  TotalName,
  TotalPrice,
} from './styles';

import formatCompleteDate from '~/services/formatCompleteDate';
import formatPhone from '~/services/formatPhoneString';
import api from '~/services/api';

class PrintBillsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: props.item,
      restaurant: props.restaurant,
      payments: [],
    };
  }

  componentDidMount() {
    try {
      api
        .get('/restaurants/payments', {
          params: {
            table_session_id: this.state.item.id,
          },
        })
        .then((res) =>
          this.setState({
            payments: res.data.payments,
            total_paid: res.data.total_value,
          })
        );
    } catch (error) {
      toast.error('Erro ao carregar pagamentos');
    }
  }

  render() {
    const { item, restaurant, total_paid } = this.state;
    const date = formatCompleteDate(new Date());

    const pendingBills = item?.bills.filter(
      (bill) => bill.status !== 'finished'
    );
    const closedBills = item?.bills.filter(
      (bill) => bill.status === 'finished'
    );

    return (
      <Container>
        {item ? (
          <React.Fragment key={item.id}>
            <Table>
              <strong>MESA {item.table?.table_number}</strong>
            </Table>
            <Bill>Comanda #{item.id}</Bill>
            <Bill>Fechada {date}</Bill>
            <Restaurant>{restaurant.name}</Restaurant>
            {pendingBills.map((bill) => {
              const userName = bill.buyer
                ? formatPhone(bill.buyer.phone)
                : bill.waiter.name;
              return (
                <React.Fragment key={bill.id}>
                  <Body>
                    <Phone>
                      <strong>{userName}</strong>
                    </Phone>
                    <Price>
                      <strong>R${bill.total_service_price}</strong>
                    </Price>
                  </Body>
                  <Items>
                    {bill.order_baskets.map((basket) => (
                      <React.Fragment key={basket.id}>
                        {basket.orders.map((order) => (
                          <Item key={order.id}>
                            <NamePrice>
                              <ItemName>
                                {order.amount}x {order.product.name}
                              </ItemName>
                              <PriceItem>R${order.total_price}</PriceItem>
                            </NamePrice>
                            {order.complement_categories.map((category) => (
                              <React.Fragment key={category.id}>
                                {category.order_complements.map(
                                  (complement) => (
                                    <div key={complement.complement.id}>
                                      <NamePrice>
                                        <Complements>
                                          - {complement.amount}x{' '}
                                          {complement.complement.name}{' '}
                                        </Complements>
                                        <PriceComplement>
                                          R${complement.complement.price}
                                        </PriceComplement>
                                      </NamePrice>
                                    </div>
                                  )
                                )}
                              </React.Fragment>
                            ))}
                          </Item>
                        ))}
                      </React.Fragment>
                    ))}
                    <Item>
                      <NamePrice>
                        <ItemName>Serviços (10%)</ItemName>
                        <PriceItem>
                          R$
                          {(
                            bill.total_service_price - bill.total_price
                          ).toFixed(2)}
                        </PriceItem>
                      </NamePrice>
                    </Item>
                  </Items>
                </React.Fragment>
              );
            })}
            <AlreadyClosed>
              <Body>
                <h4>Comandas Individuais Já Fechadas</h4>
                {closedBills.map((bill) => {
                  const userName = bill.buyer
                    ? formatPhone(bill.buyer.phone)
                    : bill.waiter.name;

                  return (
                    <React.Fragment key={bill.id}>
                      <Phone>
                        <strong>{userName}</strong>
                      </Phone>
                      <Price>
                        <strong>R${bill.total_service_price}</strong>
                      </Price>
                    </React.Fragment>
                  );
                })}
              </Body>
            </AlreadyClosed>
            <TotalDiv>
              <TotalRow>
                <TotalName>
                  <strong>TOTAL</strong>
                </TotalName>
                <TotalPrice>
                  <strong>{item.total_service_price}</strong>
                </TotalPrice>
              </TotalRow>
              <TotalRow>
                <TotalName>
                  <strong>PAGO</strong>
                </TotalName>
                <TotalPrice>
                  <strong>R${total_paid}</strong>
                </TotalPrice>
              </TotalRow>
              <TotalRow>
                <TotalName>
                  <strong>RESTANTE</strong>
                </TotalName>
                <TotalPrice>
                  <strong>
                    R${(item.total_service_price - total_paid).toFixed(2)}
                  </strong>
                </TotalPrice>
              </TotalRow>
            </TotalDiv>
            <Footer>
              <strong>Obrigado por escolher a Takeat!</strong>
            </Footer>
          </React.Fragment>
        ) : (
          <div />
        )}
      </Container>
    );
  }
}

export default PrintBillsTable;
