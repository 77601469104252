import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
   * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html, body, #root {
    height: 100%;
  }
  body {
    -webkit-font-smoothing: antialiased;
  }
  body, input, button {
    font: 16px 'Roboto', sans-serif;
  }
  body::-webkit-scrollbar-track {
    background: #1e1e24;
  }
  body::-webkit-scrollbar-thumb {
    background: #6649b8;
  }
  a {
    text-decoration:none;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }
  body {
    background: ${(props) => props.theme.colors.background};
    font-size: 14px;
    color: ${(props) => props.theme.colors.text};
    font-family: 'Roboto', sans-serif;
  }

  /* @font-face {
  font-family: 'AmeliaBasic';
  src: local('AmeliaBasic'), url(./src/assets/fonts/amelia-basic-italic.ttf) format('truetype');
} */
`;
