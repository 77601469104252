import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
} from 'react';
import api from '../../services/api';

import { useNotification } from '../notificationContext';
import { useAuth } from '~/context/AuthContext';

const OrderContext = createContext();

const OrderProvider = ({ children }) => {
  const { user } = useAuth();
  const [pendingBaskets, setPendingBaskets] = useState(() => {
    const pendingBasketsStorage = localStorage.getItem(
      '@qrdashboard:pendingBaskets'
    );

    if (pendingBasketsStorage) {
      return JSON.parse(pendingBasketsStorage);
    }

    return [];
  });
  const [acceptedBaskets, setAcceptedBaskets] = useState([]);
  const [finishedBaskets, setFinishedBaskets] = useState([]);
  const [canceledBaskets, setCanceledBaskets] = useState([]);
  const [doneBaskets, setDoneBaskets] = useState([]);
  const [loading, setLoading] = useState(false);

  const { sendNotification } = useNotification();

  // function addNewOrder({ order_basket }) {
  //   setPendingBaskets([order_basket, ...pendingBaskets]);
  // }

  const addNewOrder = useCallback(
    ({ order_basket }) => {
      setPendingBaskets([order_basket, ...pendingBaskets]);
    },
    [pendingBaskets]
  );

  const getAllOrders = useCallback(async () => {
    const orders = await api.get('restaurants/orders');
    setPendingBaskets(orders.data.filter((a) => a.order_status === "pending"));
    setAcceptedBaskets(orders.data.filter((a) => a.order_status === "accepted"));
    setFinishedBaskets(orders.data.filter((a) => a.order_status === "finished"));
    setCanceledBaskets(orders.data.filter((a) => a.order_status === "canceled" ||  a.order_status === "chargeback"));
    setDoneBaskets(orders.data.filter((a) => a.order_status === "done"));
  }, []);

  useEffect(() => {
    if (user) {
      setLoading(true);
      getAllOrders();
      setLoading(false);
    }
  }, [getAllOrders, user]);

  useEffect(() => {
    localStorage.setItem(
      '@qrdashboard:pendingBaskets',
      JSON.stringify(pendingBaskets)
    );
  }, [pendingBaskets]);

  useEffect(() => {
    let interval;
    if (user) {
      interval = setInterval(async () => {
        const orders = await api.get('restaurants/orders');
        const pending = orders.data.filter((a) => a.order_status === "pending");
        setPendingBaskets(pending);
        
        if (pending.length > 0) {
          sendNotification({
            title: 'Seu restaurante possui pedidos pendentes! ',
            body: 'Por favor, cheque os pedidos em seu restaurante',
          });
        }
        
        setAcceptedBaskets(orders.data.filter((a) => a.order_status === "accepted"));
        setFinishedBaskets(orders.data.filter((a) => a.order_status === "finished"));
        setCanceledBaskets(orders.data.filter((a) => a.order_status === "canceled" ||  a.order_status === "chargeback"));
        setDoneBaskets(orders.data.filter((a) => a.order_status === "done"));
      }, 30000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [sendNotification, user]);

  const value = useMemo(
    () => ({
      pendingBaskets,
      setPendingBaskets,
      acceptedBaskets,
      finishedBaskets,
      canceledBaskets,
      doneBaskets,
      addNewOrder,
      getAllOrders,
      loading,
    }),
    [
      pendingBaskets,
      setPendingBaskets,
      acceptedBaskets,
      finishedBaskets,
      canceledBaskets,
      doneBaskets,
      addNewOrder,
      getAllOrders,
      loading,
    ]
  );
  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
};

function useOrder() {
  const context = useContext(OrderContext);

  if (!context) {
    throw new Error('useOrder must be used within an OrderProvider');
  }

  return context;
}

export { OrderProvider, useOrder };
