import React, { useState, useEffect, useCallback } from 'react';
import {useParams} from 'react-router-dom'
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Container, Row, Col } from 'reactstrap';
import {
  Wrapper,
  Header,
  Card,
  CheckboxInfo,
  CheckBoxContainer,
} from './styles';

import history from '~/services/history';
import api from '~/services/api';

import ImageInput from '~/components/Form/ImageInput';
import Input from '~/components/Form/Input';
import Textarea from '~/components/Form/Textarea';
import Select from '~/components/Form/SelectInput';
import Checkbox from '~/components/Form/Checkbox';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function CreateProduct({category, toggle, getCategory}) {
  const {category_id} = useParams()
  const [categories, setCategories] = useState([]);
  // const [productId, setProductId] = useState(-1);
  const [complementCategories, setComplementCategories] = useState([]);

  const getCategories = useCallback(async () => {
    const response = await api.get('/restaurants/product-category');

    const data = response.data.map((category) => ({
      value: category.id,
      label: category.name,
    }));

    setCategories(data);
  }, []);

  const getComplementCategories = useCallback(async () => {
    const response = await api.get('restaurants/product/complement-category');

    const data = response.data.map((category) => ({
      value: category.id,
      label: category.name,
    }));

    setComplementCategories(data);
  }, []);

  useEffect(() => {
    try {
      getCategories();
      getComplementCategories();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getCategories, getComplementCategories]);

  async function handleSubmit(data) {
    const formattedPrice = parseFloat(data.price.replace(',', '.')).toFixed(2);

    // const schema = Yup.object().shape({
    //   name: Yup.string(),
    //   description: Yup.string(),
    //   price: Yup.number().min(0.1),
    //   product_category_id: Yup.number().integer(),
    //   promotion: Yup.string(),
    //   sold_off: Yup.boolean(),
    //   available: Yup.boolean(),
    //   complement_categories: Yup.array(),
    //   price_promotion: Yup.number().min(
    //     0.1,
    //     'Preço deve ser maior do que R$0.10'
    //   ),
    //   image_id: Yup.number().integer(),
    //   thumbnail_id: Yup.number().integer(),
    // });

    const formattedPromotionPrice = parseFloat(
      data.price_promotion.replace(',', '.')
    ).toFixed(2);

    const parsedData = {
      name: data.name,
      description: data.description,
      price: formattedPrice,
      product_category_id: category.id,
      promotion: data.promotion,
      sold_off: data.sold_off,
      available: data.available,
      complement_categories: data.complement_categories,
    };

    if (data.price_promotion.length > 0) {
      parsedData.price_promotion = formattedPromotionPrice;
    }
    if (data.image_id) {
      parsedData.image_id = data.image_id;
    }
    if (data.thumbnail_id) {
      parsedData.thumbnail_id = data.thumbnail_id;
    }

    try {
      // await schema.validate(parsedData, {
      //   abortEarly: false,
      // });
      await api.post('restaurants/products', parsedData);

      // setProductId(response.data.id);

      toast.success('Produto cadastrado com sucesso!');
      getCategory()
      toggle()

    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        console.log(error);
        toast.error(error.message);
      } else {
        toast.error('Falha ao salvar produto');
      }
    }
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Cadastrar Produtos</h2>
        </div>
       
      </Header>
      <Card>
        <Form onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col sm="12">
                <ImageInput name="image_id" />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Nome do Produto"
                />
              </Col>
              <Col sm="12" lg="6">
                <Input
                  label="Atributo"
                  name="promotion"
                  type="text"
                  placeholder="Dose dupla, sugestão, vegano..."
                  maxlength="20"
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Textarea
                  label="Descrição"
                  name="description"
                  type="textarea"
                  placeholder="Descrição do Produto"
               
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Preço"
                  name="price"
                  type="text"
                  placeholder="R$12,00"
                />
              </Col>
              <Col sm="12" lg="6">
                <Input
                  label="Preço promocional"
                  name="price_promotion"
                  type="text"
                  placeholder="Preço promocional, caso deseje que tenha"
                />
              </Col>
            </Row>
            {/* <Row>
              <Col sm="12">
                <Select
                  label="Categoria"
                  name="product_category"
                  options={categories}
                />
              </Col>
            </Row> */}
            <CheckBoxContainer>
              <Col sm="6">
                <Col sm="12">
                  <Checkbox
                    defaultChecked
                    label="Produto Disponível"
                    name="available"
                  />
                </Col>
                <Col sm="12">
                  <Checkbox label="Produto Esgotado" name="sold_off" />
                </Col>
              </Col>
              <Col sm="6">
                <CheckboxInfo>
                  <h4> Legenda</h4>
                  <div>
                    <span>Disponível</span>
                    <p>
                      Se este produto está disponível e deve aparecer no
                      cardápio
                    </p>
                  </div>
                  <div>
                    <span>Esgotado</span>
                    <p>
                      Se este produto irá aparecer no cardápio, porém como
                      esgotado. O cliente não poderá pedir
                    </p>
                  </div>
                </CheckboxInfo>
              </Col>
            </CheckBoxContainer>
            {/* <SaveButton type="submit">Cadastrar</SaveButton> */}
            <h4>Associar Categorias de complementos ao seu produto</h4>
            <Select
              name="complement_categories"
              isMulti
              options={complementCategories}
              label="Selecione as categorias de complementos"
            />
            <SaveButton type="submit">Salvar</SaveButton>
          </Container>
        </Form>
      </Card>
    </Wrapper>
  );
}

export default CreateProduct;
