import React, { useState } from 'react';
import { MdInfo } from 'react-icons/md';
import { Modal, ModalBody } from 'reactstrap';
import { Container } from './styles';

import DefaultButton from '~/components/Buttons/DefaultButton';

import ComplementsCategories from '~/pages/Products/ComplementsCategories';

const ProductComplementHeader = () => {
  const [modalComplement, setModalComplement] = useState(false);

  function toggleModalComplement() {
    setModalComplement(!modalComplement);
  }
  return (
    <>
      <Modal isOpen={modalComplement} toggle={toggleModalComplement} size="xl">
        <ModalBody>
          <ComplementsCategories />
        </ModalBody>
      </Modal>
      <Container>
        <div>
          <h2>Complementos</h2>
          <MdInfo
            color="orange"
            size={24}
            title="Indicamos que antes de efetuar o cadastro do produto abaixo, cadastre primeiro os complementos, para que os mesmos já fiquem disponiveis a todos os produtos
            ao adicioná-los. Use também o gerenciamento para pausar o complemento ou a categoria na hora que desejar, pausando assim em todos os produtos
            que os mesmos tiverem associados."
          />
        </div>
        <DefaultButton
          message="Gerenciar complementos"
          onClick={toggleModalComplement}
        />
      </Container>
    </>
  );
};

export default ProductComplementHeader;
