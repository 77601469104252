import React, { useState, useEffect, useCallback } from 'react';

import { Container } from 'reactstrap';
import { toast } from 'react-toastify';
import { Wrapper, Header, TablesPlace } from './styles';

import history from '~/services/history';

import BackButton from '~/components/Buttons/BackButton';
import Table from '~/components/Table';

import TablesClosed from '~/components/TablesClosed';
import api from '~/services/api';

function ViewTables() {
  const [sessionsOpen, setSessionsOpen] = useState([]);
  const [tablesAvailable, setTablesAvailable] = useState([]);

  const getTablesAvailable = useCallback(async () => {
    const response = await api.get('/restaurants/tables', {
      params: {
        status: 'available',
      },
    });

    setTablesAvailable(response.data);
  }, []);

  const getSessionsOpen = async () => {
    try {
      const response = await api.get('/restaurants/table-sessions', {
        params: {
          status: 'open',
        },
      });

      setSessionsOpen(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  };

  useEffect(() => {
    getSessionsOpen();
    getTablesAvailable();
  }, [getTablesAvailable]);

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Acompanhar Mesas</h2>
          <p>
            Aqui você acompanha as mesas que estão ocupadas e livres nesse
            momento no seu estabelecimento.
          </p>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Container>
        <TablesPlace>
          {sessionsOpen.map((session) => (
            <Table session={session} />
          ))}
          {tablesAvailable.map((table) => (
            <TablesClosed table={table?.table_number} />
          ))}
        </TablesPlace>
      </Container>
    </Wrapper>
  );
}

export default ViewTables;
