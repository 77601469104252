import React, { useState } from 'react';
import { MdInfo, MdArrowDropUp } from 'react-icons/md';
import { Modal, ModalBody } from 'reactstrap';
import PersonalButton from '~/components/Buttons/PersonalButton';
import RegisterButton from '~/components/Buttons/RegisterButton';

import OrderProductCategory from '~/pages/Products/OrderProductCategory';
import Store from '~/pages/Products/Categories/Store';

import { Container } from './styles';

const CategoryHeader = ({ getCategories }) => {
  const [modalCreateCategory, setModalCreateCatgory] = useState(false);
  const [modalOrderProductCategory, setModalOrderProductCategory] = useState(
    false
  );

  function toggleModalCreateCategory() {
    setModalCreateCatgory(!modalCreateCategory);
  }

  function toggleModalOrderProductCategory() {
    setModalOrderProductCategory(!modalOrderProductCategory);
  }

  return (
    <Container>
      <Modal
        isOpen={modalOrderProductCategory}
        toggle={toggleModalOrderProductCategory}
        size="xl"
      >
        <ModalBody>
          <OrderProductCategory getCategory={getCategories} />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalCreateCategory}
        toggle={toggleModalCreateCategory}
        size="lg"
      >
        <ModalBody>
          <Store
            toggle={toggleModalCreateCategory}
            getCategories={getCategories}
          />
        </ModalBody>
      </Modal>

      <div>
        <h2>Categorias de Produto</h2>
        <MdInfo
          color="orange"
          size={24}
          title="Caso seja a sua primeira vez aqui, comece cadastrando a Categoria de produto, para depois adicionar o produto dentro dela."
        />
      </div>
      <div>
        <RegisterButton
          text="Cadastrar categorias"
          onClick={toggleModalCreateCategory}
        />
        {/* <PersonalButton
          color="#ff403d"
          message="Ordenar categorias"
          onClick={toggleModalOrderProductCategory}
          Icon={MdArrowDropUp}
        /> */}

        {/* <StyledLink to="/products/categories">
          <ManagerButton title="Gerenciar categorias"/>
        </StyledLink> */}
      </div>
    </Container>
  );
};

export default CategoryHeader;
