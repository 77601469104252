import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

import { MdDelete, MdEdit } from 'react-icons/md';
import {
  Container,
  CategoriesTable,
  Header,
  StyledLink,
  Actions,
  Delete,
} from './styles';

import RegisterButton from '~/components/Buttons/RegisterButton';
import api from '~/services/api';
import formatDate from '~/services/formatDate';

function Tickets() {
  const [tickets, setTickets] = useState([]);

  const getTickets = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/discount-coupons');
      setTickets(response.data);
      console.log(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  useEffect(() => {
    try {
      getTickets();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getTickets]);

  async function handleDelete(id) {
    try {
      if (window.confirm('Tem certeza que deseja deletar este cupom?')) {
        await api.delete(`/restaurants/discount-coupons/${id}`);
        getTickets();
        toast.success('Cupom deletado com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar cupom');
    }
  }

  return (
    <Container>
      <Header>
        <div>
          <h2>Cupons</h2>
          <p>
            Gerencie os cupons disponíveis em seu estabelecimento!
          </p>
        </div>
        <StyledLink to="/tickets/new">
          <RegisterButton>Adicionar</RegisterButton>
        </StyledLink>
      </Header>

      <CategoriesTable borderless>
        <thead>
          <tr>
            <th>Código</th>
            <th>Nome</th>
            <th>Ativo</th>
            <th>Público</th>
            <th>Título</th>
            <th>Desconto</th>
            <th>Valor Mínimo</th>
            <th>Desconto Máximo</th>
            <th>Limite</th>
            <th>Usado</th>
            <th>Expiração</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {tickets ? (
            tickets.map((ticket) => (
              <tr key={ticket.id}>
                <td>{ticket.code}</td>
                <td>{ticket.name}</td>
                <td>{ticket.active ? 'Sim' : 'Não'}</td>
                <td>{ticket.is_public ? 'Sim' : 'Não'}</td>
                <td>{ticket.title}</td>
                <td>{ticket.discount_type === "absolut" && `R$${ticket.discount}`}{ticket.discount_type === "percentage" && `${(parseFloat(ticket.discount) * 100).toFixed(2)}%`}</td>
                <td>R${ticket.minimum_price}</td>
                <td>R${ticket.maximum_discount}</td>
                <td>{ticket.limit_amount}</td>
                <td>{ticket.used_amount} {ticket.used_amount === 1 ? "vez" : "vezes"}</td>
                <td>{ticket.limit_date === null ? "Sem expiração" : formatDate(ticket.limit_date)}</td>
                <td>
                  {' '}
                  <Actions>
                    <StyledLink
                      to={{
                        pathname: 'tickets/edit',
                        state: {
                          ticket,
                        },
                      }}
                    >
                      <MdEdit size={20} />
                    </StyledLink>
                    <Delete onClick={() => handleDelete(ticket.id)}>
                      <MdDelete size={20} />
                    </Delete>
                  </Actions>
                </td>
              </tr>
            ))
          ) : (
            <h1>Vazio</h1>
          )}
        </tbody>
      </CategoriesTable>
    </Container>
  );
}

export default Tickets;
