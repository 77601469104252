import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const Content = styled.div`
  width: 100vw;
  min-height: 100vh;
  margin-left: 13rem;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;
