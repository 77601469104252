import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import {v4} from 'uuid';
import {Modal, ModalBody, Collapse} from 'reactstrap'

import { MdDelete, MdEdit, MdExpandMore } from 'react-icons/md';
import {
  Container,
  ComplementsTable,
  ComplementsItemHeader,
  Actions,
  Delete,
  ComplementsItemBody,
  ButtonCollapse,
  ComplementItemHeaderTitle
} from './styles';
import api from '~/services/api';

import EditComplement from './Edit'

function Complements({category, getCategories, count, setCount}) {
  const [complementsData, setComplementsData] = useState(false);
  const [modalEditComplement,setModalEditComplement] = useState(false);
  const [modalEditComplementData, setModalEditComplementData] = useState();
  const [isOpen, setIsOpen] = useState(true);

  const toggleComplements = () => setIsOpen(!isOpen);
  
  function toggleModalEditComplement(){
    setModalEditComplement(!modalEditComplement);
  }

 function handleChangeEditComplement(complement){
    toggleModalEditComplement();
    setModalEditComplementData(complement)
  }

  const getComplements = useCallback(async () => {
    try {
      const response = await api.get(`/restaurants/product/complement-category/${category.id}`);

      setComplementsData(response.data.complements);

    
    } catch (err) {
      toast.error('Falha ao carregar dados');
    }
  }, []);

  useEffect(() => {
    try {
      getComplements();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getComplements, count, setCount]);

  async function handleDelete(id) {
    try {
      if (window.confirm('Tem certeza que deseja deletar este Complemento? Lembre-se que ele será excluído de todas as categorias. Para deletar desta categoria, clique em "Adicionar/ Remover complementos já existentes"')) {
        await api.delete(`/restaurants/product/complements/${id}`);

        // window.location.reload();
         getComplements();

        const aleatoryId = v4()

        setCount(aleatoryId)

        toast.success('Complemento deletado com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar complemento');
    }
  }

  async function handleChangeComplement(value, event, idx) {

    try{
      const id = Number(idx);
     
      await api.put(`/restaurants/product/complements/${id}`, {
        available: value
      });

      const aleatoryId = v4()

      setCount(aleatoryId)
     
      // window.location.reload();

      getComplements()

      // const complementFiltered = complementsData.complements.findIndex(item => item.id === id)

      // complementsData.complements[complementFiltered].available = value;

      // setComplementsData({...complementsData});

          
    }catch(error){
      toast.error('Erro ao ativar/ desativar complemento')
      console.log(error.message)
    }
  }

  return (
    <Container>  
      <ButtonCollapse onClick={toggleComplements} title="Mostar/ esconder lista de complementos">Exibir/ Esconder Complementos <MdExpandMore size={20}/></ButtonCollapse>

  <Collapse isOpen={isOpen}>  
      <ComplementsTable>
        <ComplementsItemHeader>      
          <div>
            <ComplementItemHeaderTitle>Complemento</ComplementItemHeaderTitle>
            {complementsData && complementsData.length && (
            complementsData.map((complement) => (
              <p>{complement.name}</p> )))}
          </div>    

          <div>
          <ComplementItemHeaderTitle>Preço</ComplementItemHeaderTitle>
          {complementsData && complementsData.length && (
            complementsData.map((complement) => (
              <p>{complement.price}</p> )))}
          </div>

          <div>
          <ComplementItemHeaderTitle>Limite</ComplementItemHeaderTitle>
          {complementsData && complementsData.length && (
            complementsData.map((complement) => (
              <p>{complement.limit}</p> )))}
          </div>

          <div>
          <ComplementItemHeaderTitle>Disponilidade</ComplementItemHeaderTitle>
          {complementsData && complementsData.length && (
            complementsData.map((complement) => (
              <p>
              <Switch
                id={`${complement.id}`}
                onChange={handleChangeComplement}
                draggable={true}
                checked={complement.available}
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={40}
                handleDiameter={20}
                activeBoxShadow="0 0 2px 3px #111"
                boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
              />
              </p>
              )))}
          </div>

          <div>
          <ComplementItemHeaderTitle>Ações</ComplementItemHeaderTitle>  
          {complementsData && complementsData.length && (
            complementsData.map((complement) => (
              <p>
                <Actions>
                  
                    <MdEdit size={20} title="Editar o complemento" color="blue" onClick={()=>{handleChangeEditComplement(complement)}}/>
 
                  <Delete onClick={() => handleDelete(complement.id)} title="Deletar complemento">
                    <MdDelete size={20} />
                  </Delete>
                </Actions>
              </p>)))}
          </div>                    
        </ComplementsItemHeader>

        <Modal isOpen={modalEditComplement} toggle={toggleModalEditComplement} size="lg">
          <ModalBody>
            <EditComplement complement={modalEditComplementData} getCategories={getCategories} toggle={toggleModalEditComplement} getComplements={getComplements} setCount={setCount}/>         
          </ModalBody>
        </Modal>
      
        {/* {complementsData && complementsData.length ? (
          complementsData.map((complement) => (
            <ComplementsItemBody>
              
              
            
             
              
              
            </ComplementsItemBody>
          ))
        ) : (
            <p>Não existem complementos cadastrados</p>
          )} */}
       
      </ComplementsTable>
      </Collapse>
    </Container>
  );
}

export default Complements;
