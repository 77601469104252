import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import Switch from 'react-switch';

import { Container, Header, MethodsTable } from './styles';

import api from '~/services/api';
import { useAuth } from '~/context/AuthContext';

function PaymentMethod() {
  const { user } = useAuth();
  const [paymentData, setPaymentData] = useState(false);

  const getPaymentData = useCallback(async () => {
    try {
      const restaurant_id = user.id;
      const response = await api.get(
        `/restaurants/payment-method/${restaurant_id}`
      );
      setPaymentData(response.data);
      
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [user.id]);

  useEffect(() => {
    getPaymentData();
  }, [getPaymentData]);

  async function handleChangeWithdrawal(value, event, idx) {
    try {
      idx = Number(idx);
      const { keyword } = paymentData.payment_methods[idx];
      const restaurant_id = user.id;

      await api.put(
        `/restaurants/payment-method-activate/${restaurant_id}/${keyword}`,
        { withdrawal_active: value }
      );

      paymentData.payment_methods[idx].withdrawal_active = value;
      setPaymentData({ ...paymentData });
    } catch (error) {
      toast.error(`Erro ao atualizar método! ${error.message}`);
    }
  }

  async function handleChangeDelivery(value, event, idx) {
    try {
      idx = Number(idx);
      const { keyword } = paymentData.payment_methods[idx];
      const restaurant_id = user.id;

      await api.put(
        `/restaurants/payment-method-activate/${restaurant_id}/${keyword}`,
        { delivery_active: value }
      );

      paymentData.payment_methods[idx].delivery_active = value;
      setPaymentData({ ...paymentData });
    } catch (error) {
      toast.error(`Erro ao atualizar método! ${error.message}`);
    }
  }

  return (
    <Container>
       <Header>
        <div>
          <h2>Métodos de Pagamento</h2>
          <p>Habilite métodos de pagamentos aceitos no seu restaurante.</p>
        </div>
      </Header>

      <MethodsTable borderless>
        <thead>
          <tr>
            <th>Nome</th>
            {user.is_delivery_allowed && ( <th>Ativo no Delivery</th>)}
           
            <th>Ativo no Balcão</th>
          </tr>
        </thead>
        <tbody>
          {paymentData && paymentData.payment_methods.length ? (
            paymentData.payment_methods.map((method, id) => (
              <tr key={method.keyword}>
                <td>{method.name}</td>
                {user.is_delivery_allowed && (
                  <td>
                  <Switch
                    id={`${id}`}
                    onChange={handleChangeDelivery}
                    draggable={true}
                    checked={method.delivery_active}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={20}
                    width={40}
                    handleDiameter={20}
                    activeBoxShadow="0 0 2px 3px #111"
                    boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                  />
                </td>
                )}
                
                <td>
                  <Switch
                    id={`${id}`}
                    onChange={handleChangeWithdrawal}
                    draggable={false}
                    checked={method.withdrawal_active}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={20}
                    width={40}
                    handleDiameter={20}
                    activeBoxShadow="0 0 2px 3px #111"
                    boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                  />
                </td>
              </tr>
            ))
          ) : (
            <h1>Vazio</h1>
          )}
        </tbody>
      </MethodsTable>
    </Container>
  );
}

export default PaymentMethod;
