import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { InputPaymentContainer } from './styles';

function InputPaymentForm({ name, label, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, inputRef, registerField]);

  return (
    <InputPaymentContainer>
      <label htmlFor={fieldName}>{label}</label>
      <input ref={inputRef} defaultValue={defaultValue} {...rest} />
      {error && <span>{error}</span>}
    </InputPaymentContainer>
  );
}

export default InputPaymentForm;
