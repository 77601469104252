import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  margin: 10px;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;

  svg {
    margin-left: 8px;
  }
`;

export const Card = styled.div`
  width: 90%;
  min-width: 400px;
  margin: 0 auto;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const Content = styled.div`
  margin: 0px auto;
  width: 95%;
  padding: 10px;
`;

export const Table = styled.table`
  margin-top: 8px;
  margin-left: 10px;

  
  td {
    padding: 10px;
    text-align: left;

    input{
      width: 80px;
    }
  }

  svg {
    cursor: pointer;
  }
`