import React, { useState, useEffect, useCallback } from 'react';
import { toast} from 'react-toastify';

import { MdDelete, MdEdit, MdAddCircle } from 'react-icons/md';
import { FaStreetView } from 'react-icons/fa';

import PersonalSwitcher from '../../../components/PersonalSwitcher';
import DeliveryStore from './Create/index';
import EditAddresses from './Edit/index';
import ModalCreateAddress from './ModalCreateAddress'

import {
  Container,
  Header,
  AddressesTable,
  Actions,
  StyledLink,
  ActionButton,
  DeleteAndEditActions,
  ActionsInside,
  UserAddress,
  DeliveryDistanceTax,
  PersonalTable,
  AreaButton
} from './styles';
import { Form } from '@unform/web';
import Input from '~/components/Form/Input';
import SaveButton from '~/components/Buttons/SaveButton';
import RegisterButton from '~/components/Buttons/RegisterButton';
import ModalUserAddress from '~/components/ModalUserAddress'

import { Card, Row, Col, Modal, ModalBody, Input as InputStrap } from 'reactstrap';

import api from '~/services/api';
import DefaultButton from '~/components/Buttons/DefaultButton';
import { useAuth } from '~/context/AuthContext';

function DeliveryConfig() {
  const { user, updateUser, getProfileData } = useAuth();
  const [addresses, setAddresses] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalDeliveryConfig, setModalDeliveryConfig] = useState(false);
  const [modalEditAddress, setModalEditAddress] = useState(false)
  const [modalAddAddress, setModalAddAddress] = useState(false)
  const [modalEditAddressData, setModalEditAddressData] = useState('')
 
  const [deliveryDistance, setDeliveryDistance] = useState()
  const [count, setCount] = useState(0)

  const [distance, setDistance] = useState('')
  const [taxByDistance, setTaxByDistance] = useState('')
  const [timeByDistance, setTimeByDistance] = useState('')

  const initialData = {
    time_to_delivery: user.time_to_delivery,
    time_to_withdrawal: user.time_to_withdrawal,
    minimum_delivery_price: user.minimum_delivery_price,      
  };

  const handleSetDeliveryDistance = () => {
    if (distance && taxByDistance && timeByDistance){
     
     setDeliveryDistance([...deliveryDistance, {distance: distance, price: taxByDistance, time: timeByDistance}])
     
      setDistance('')
      setTaxByDistance('')
      setTimeByDistance('')
    }
  }

  const handleDeleteDeliveryDistance = (id) => {
    const distanceFiltered = deliveryDistance.filter(distance => distance.id !== id)
    setDeliveryDistance(distanceFiltered)
  }
 
  async function handleChangeTime(data) {
    await updateUser(data);
    
    window.location.reload()
  }

  const toggleModal = () => {
    setModal(!modal)
  }

  const toggleModalDeliveryConfig = () => {
    setModalDeliveryConfig(!modalDeliveryConfig)
  }

  const tooggleModalEditAddress = () => {
    setModalEditAddress(!modalEditAddress)
  }

  const tooggleModalAddAddress = () => {
    setModalAddAddress(!modalAddAddress)
  }

  const handleEditAddress = (address) => {
    setModalEditAddressData(address)
    tooggleModalEditAddress();
  }


  const getAddresses = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/delivery-addresses');

      setAddresses(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  const getDeliveryDistances = useCallback(async()=>{
    const res = await api.get('/restaurants/delivery-info')

    const {delivery_distances} = res.data

    setDeliveryDistance(delivery_distances)

    console.log(user)

  }, [])

  const handleDelete = useCallback(
    async (addressId) => {
      try {
        if (window.confirm('Tem certeza que deseja deletar este endereço?')) {
          await api.delete(`/restaurants/delivery-addresses/${addressId}`);
          toast.success('Endereço deletado com sucesso!');
          const parsedAddresses = addresses.filter(
            (item) => item.id !== addressId
          );
          setAddresses(parsedAddresses);
        }
      } catch (err) {
        toast.error('Erro ao deletar endereço');
      }
    },
    [addresses]
  );

  const handleDeleteUserAddress = useCallback(
    async () => {
      try {
        if (window.confirm('Tem certeza que deseja deletar este endereço?')) {
          await api.delete(`/restaurants/address/delete`);
          toast.success('Endereço deletado com sucesso!');

          getProfileData()
        }
      } catch (err) {
        toast.error('Erro ao deletar endereço');
      }
    },
    [addresses]
  );

  const handleSubmitDistances = async ()=>{
    try{
      await api.put('/restaurants/delivery-info', {
        delivery_distances: deliveryDistance
      })

      toast.success("Alcances alteradas com sucesso")
      getDeliveryDistances()
    } catch(e){
      console.log(e.message)
      toast.error("Erro ao alterar alcance")
    }
   
  }

  useEffect(() => {
    try {
      getAddresses();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getAddresses]);

  useEffect(() => {
    try {
      getDeliveryDistances();
    } catch (err) {
      console.log(err);
    }
  }, [getDeliveryDistances]);

  useEffect(()=>{
    getProfileData();
  }, [getProfileData]);

  return (
    <Container>
      <Header>
        <div>
          <h2>Delivery</h2>
          <p>Configure a modalidade de delivery do seu estabelecimento!</p>
        </div>
        
          <DefaultButton message="Configurar Delivery" onClick={toggleModalDeliveryConfig}/>
        
      </Header>

      <Card
        style={{
          width: '85%',
          padding: 20,
          margin: 'auto',
          textAlign: 'center',
          marginBottom: 30,
        }}
      >
        
        <Form initialData={initialData} onSubmit={handleChangeTime}>
          <Row>
            <Col lg="4">
              <Input
                label="Tempo para retirada aprox. (min.)"
                name="time_to_withdrawal"
                type="text"
              />
            </Col>
            <Col lg="4">
              <Input
                label="Tempo para entrega aprox. (min.)"
                name="time_to_delivery"
                type="text"
              />
            </Col>
            <Col lg="4">
              <Input
                label="Preço mínimo pro pedido."
                name="minimum_delivery_price"
                type="text"
              />
            </Col>
          </Row>
          {/* {user.is_delivery_by_distance && (
            <Row>
            <Col lg="4">
                <Input
                  label="Distância máxima de pedidos. (km) "
                  name="maximum_delivery_distance"
                  type="text"
                />
              </Col>
              
            </Row>
          )} */}
          
          <Row>
            <Col lg="12" align="right">
              <SaveButton type="submit"/>
            </Col>
          </Row>
        </Form>
      </Card>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
          <ModalBody>
            <ModalUserAddress toggle={toggleModal} setCount={setCount}/>
          </ModalBody>
        </Modal>

        <Modal isOpen={modalDeliveryConfig} toggle={toggleModalDeliveryConfig} size="lg">
          <ModalBody>
            <DeliveryStore toggle={toggleModalDeliveryConfig} setCount={setCount} getDeliveryDistances={getDeliveryDistances}/>
          </ModalBody>
        </Modal>

        <Modal isOpen={modalEditAddress} toggle={tooggleModalEditAddress} size="lg">
          <ModalBody>
            <EditAddresses address={modalEditAddressData} toggle={tooggleModalEditAddress} getAddresses={getAddresses}/>
          </ModalBody>
        </Modal>

        <Modal isOpen={modalAddAddress} toggle={tooggleModalAddAddress} size="lg">
          <ModalBody>
            <ModalCreateAddress toggle={tooggleModalAddAddress} getAddresses={getAddresses}/>
          </ModalBody>
        </Modal>

      {user.is_delivery_by_distance && user.address && (
        <>
        <UserAddress>
          <Header>
            <div>
              <h2>
                Endereço do Restaurante
              </h2>
              <p>Endereço do restaurante cadastrado no sistema.</p>
            </div>
          </Header>

          <AddressesTable borderless>
         
         <thead>
           <tr>
             <th>País</th>
             <th>Estado</th>
             <th>Cidade</th>
             <th>Bairro</th>
             <th>Rua</th>
             <th>Número</th>
             <th>CEP</th>
             <th>Ações</th>
           </tr>
         </thead>
         <tbody>
            <tr key={user.address.id}>
                 <td>{user.address.country}</td>
 
                 <td>{user.address.state}</td>
                 <td>{user.address.city}</td>
                 <td>
                   {user.address.neighborhood}
                 </td>
                 <td>{user.address.street}</td>
 
                 <td>{user.address.number}</td>
                 <td>{user.address.zip_code}</td>
 
                 <td>
                   {' '}
                   <Actions>
                     <ActionsInside>
                       <DeleteAndEditActions>
                         <ActionButton onClick={handleDeleteUserAddress}>
                           <div>
                             <MdDelete size={17} style={{ marginRight: 3 }} />
                             Excluir
                           </div>
                         </ActionButton>
                         <ActionButton style={{ background: "#17a2b8"}} onClick={toggleModal}>
                           <div>
                             <MdEdit size={17} style={{ marginRight: 3 }} />
                             Editar
                           </div>
                         </ActionButton>                         
                       </DeleteAndEditActions>
                     </ActionsInside>
                   </Actions>
                 </td>
               </tr>           
         </tbody>
       </AddressesTable>        

      </UserAddress>

      <DeliveryDistanceTax>
        <Header>
          <div>
            <h2>Taxa por alcance</h2>
            <p>Configure as taxas de acordo com os alcances (km) desejados</p>
          </div>
                  
        </Header>
      <Card
       style={{
        width: '530px',
        padding: 20,
        marginLeft: 120,
        textAlign: 'left',
        marginBottom: 30,
      }}>
        <PersonalTable>
          <thead>
            <tr>
              <th>
                Alcance (km)
              </th>
              <th>
                Taxa (R$)
              </th>
              <th>
                Tempo (mins.)
              </th>
              <th>
                Ações
              </th>
            </tr>
          </thead>
          <tbody>                        
            {deliveryDistance && deliveryDistance.length > 0 && (
              deliveryDistance.map(item => (
                <tr>
                  <td>{`Até ${item.distance} km`}</td>
                  <td>{`R$ ${item.price} `}</td>
                  <td>{`${item.time} mins.`}</td>
                  <td><MdDelete size={20} color="#ff0000" title="Deletar alcance" onClick={()=>{handleDeleteDeliveryDistance(item.id)}}/></td>
                </tr>               
                
              ))
            )}                     
            <tr>                          
              <td>              
                <InputStrap
                  name="distance"
                  type="text"
                  placeholder="km..."
                  value={distance}
                  onChange={(e)=>{setDistance(e.target.value)}}
                  />                     
              </td>
              <td>
                <InputStrap
                name="tax_by_distance"
                type="text"
                placeholder="R$..."
                value={taxByDistance}
                onChange={(e)=>{setTaxByDistance(e.target.value)}}
                />
              </td>
              <td>
                <InputStrap
                name="time_by_distance"
                type="text"
                placeholder="Mins..."
                value={timeByDistance}
                onChange={(e)=>{setTimeByDistance(e.target.value)}}
                />
              </td> 
              <td><MdAddCircle onClick={handleSetDeliveryDistance} size={28} title="Adicionar alcance" color="green"/></td>                   
            </tr>
          </tbody>                  
        </PersonalTable>
          <AreaButton>
            <SaveButton onClick={handleSubmitDistances} />
          </AreaButton>
        </Card>
      </DeliveryDistanceTax>

      </>
        
      )}

      {!user.is_delivery_by_distance && (
        <>
        <Header>
          <div>
            <h2>
              Endereços cadastrados
            </h2>
            <p>Lista de endereços cadastrados na modalidade de entrega por endereço.</p> 
            <RegisterButton onClick={tooggleModalAddAddress} text="Adicionar Endereço" title="Adicionar novos endereços para delivery"/> 
          </div>       
        </Header>
     
       <AddressesTable borderless>
         <thead>
           <tr>
             <th>País</th>
             <th>Estado</th>
             <th>Cidade</th>
             <th>Bairro</th>
             <th>Ativo?</th>
             <th>Taxa de entrega</th>
             {/* <th>Limitado por CEP</th> */}
             <th>Ações</th>
           </tr>
         </thead>
         <tbody>
           {addresses ? (
             addresses.map((address) => (
               <tr key={address.id}>
                 <td>{address.country}</td>
 
                 <td>{address.state}</td>
                 <td>{address.city}</td>
                 <td>
                   {address.neighborhood ? address.neighborhood : 'Não definido'}
                 </td>
                 <td>{address.is_active ? 'Sim' : 'Não'}</td>
 
                 <td>{address.delivery_tax_price || 'Não definida'}</td>
                 {/* <td style={{ textAlign: 'center' }}>
                   <PersonalSwitcher
                     address={address}
                     getAddresses={getAddresses}
                     activeBoxShadow="0 0 2px 3px #111"
                     boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                   />
                 </td> */}
 
                 <td>
                   {' '}
                   <Actions>
                     <ActionsInside>
                       <DeleteAndEditActions>
                         <ActionButton onClick={() => handleDelete(address.id)}>
                           <div>
                             <MdDelete size={17} style={{ marginRight: 3 }} />
                             Excluir
                           </div>
                         </ActionButton>
                         <ActionButton style={{ background: '#17a2b8' }} onClick={()=>{handleEditAddress(address)}} >
                           <div>
                             <MdEdit size={17} style={{ marginRight: 3 }} />
                             Editar
                           </div>
                         </ActionButton>
 
                         {/* {address.is_zip_code_limited && (
                           <ActionButton
                             to={{
                               pathname: '/delivery/addresses/cep',
                               state: {
                                 address,
                               },
                             }}
                             style={{ background: '#ffc107', color: 'black' }}
                           >
                             <div>
                               <FaStreetView
                                 size={17}
                                 style={{ marginRight: 3 }}
                               />
                               CEPs
                             </div>
                           </ActionButton>
                         )} */}
                       </DeleteAndEditActions>
                     </ActionsInside>
                   </Actions>
                 </td>
               </tr>
             ))
           ) : (
             <h1>Vazio</h1>
           )}
         </tbody>
       </AddressesTable>
       </>
      )}

     
    </Container>
  );
}

export default DeliveryConfig;
