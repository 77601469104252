import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { toast } from 'react-toastify';

import { AiOutlineEye } from 'react-icons/ai';
import { FaHandHoldingUsd } from 'react-icons/fa';
import { TiDownload } from 'react-icons/ti';

import { Modal, ModalBody } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import {
  StyledLink,
  Actions,
  Delete,
  StatusTag,
  ModalPaymentBody,
  DisplayNoneImprimir,
} from './styles';

import PaymentForm from '~/components/PaymentForm';

import formatTime from '~/services/formatTime';

import OrdersBill from '~/components/Tables/Bills/OrdersBill';
import PrintBillsTable from '~/components/Prints/PrintBillsTable';
import EyeBillsTable from '~/components/Tables/CloseOrder/Eye/EyeBillsTable';
import EyePaymentBills from '~/components/Eye/EyePaymentBills';

import api from '~/services/api';
import { useAuth } from '~/context/AuthContext';

export default function Bill({ session, getSessions }) {
  const { user } = useAuth();

  const status = useMemo(() => {
    if (session.status === 'open') {
      return {
        statusText: 'Aberta',
        color: '#17a2b8',
      };
    }
    if (session.status === 'closing') {
      return {
        statusText: 'Fechando',
        color: '#17a2b8',
      };
    }
    if (session.status === 'finished') {
      return {
        statusText: 'Fechada',
        color: 'green',
      };
    }
    if (session.status === 'finished2') {
      return {
        statusText: 'Fechada',

        color: '#dc3545',
      };
    }
    return {
      statusText: '',
      color: '#ddd',
    };
  }, [session.status]);

  const [modal, setModal] = useState(false);
  function toggle() {
    setModal(!modal);
    getPayments();
  }

  const [modalEye, setModalEye] = useState(false);
  function toggleEye() {
    setModalEye(!modalEye);
    getPayments();
  }
  const [paid, setPaid] = useState(0);
  const [porcent, setPorcent] = useState(0);

  async function handlePrintItem({ session_key }) {
    try {
      await api.put('restaurants/close-table-bill', {
        key: session_key,
      });
      getSessions();
      toast.success('Comanda fechada!');
    } catch (error) {
      toast.error('Não foi possível finalizar a comanda! tente novamente');
    }
  }

  const getPayments = useCallback(async () => {
    const response = await api.get('restaurants/payments', {
      params: { table_session_id: session.id },
    });

    const total = response.data.total_value;
    setPaid(total);

    const porcentPaid = (total / session.total_service_price) * 100;
    setPorcent(parseFloat(porcentPaid.toFixed(2)));
  }, [session.id, session.total_service_price]);

  useEffect(() => {
    try {
      getPayments();
    } catch (error) {
      toast.error('Erro ao carregar informações');
    }
  }, [getPayments]);

  const componentRef = useRef(null);
  return (
    <>
      <tr>
        <td>#{session.id}</td>
        <td>{session.table?.table_number}</td>
        <td>{formatTime(session.start_time)}</td>
        <td>{session.bills ? session.bills.length : 1}</td>
        <td>{session.bills ? session.bills.length : 1}</td>
        <td>x</td>
        <td>R${session.total_service_price}</td>
        <td>R${paid}</td>
        <td>
          <StatusTag color={porcent >= 100 ? 'green' : '#dc3545'}>
            <span>
              {status.statusText} {porcent}%
            </span>
          </StatusTag>
        </td>
        <td>
          {' '}
          <Actions>
            {session.status === 'finished' ? (
              <StyledLink onClick={toggleEye}>
                <AiOutlineEye size={25} />
              </StyledLink>
            ) : (
              <div>
                <StyledLink onClick={toggle}>
                  <FaHandHoldingUsd size={25} />
                </StyledLink>
                <ReactToPrint
                  trigger={() => (
                    <Delete>
                      <TiDownload size={25} />
                    </Delete>
                  )}
                  content={() => componentRef.current}
                  onAfterPrint={() =>
                    handlePrintItem({
                      session_key: session.key,
                    })
                  }
                />
              </div>
            )}
          </Actions>
        </td>
        <td>
          <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalBody>
              <ModalPaymentBody>
                <PaymentForm session={session} />
                <OrdersBill session={session} />
              </ModalPaymentBody>
            </ModalBody>
          </Modal>

          <DisplayNoneImprimir>
            <PrintBillsTable
              item={session}
              restaurant={user}
              ref={componentRef}
            />
          </DisplayNoneImprimir>

          <Modal isOpen={modalEye} toggle={toggleEye} size="lg">
            <ModalBody>
              <ModalPaymentBody>
                <EyePaymentBills session={session} />
                <EyeBillsTable item={session} />
              </ModalPaymentBody>
            </ModalBody>
          </Modal>
        </td>
      </tr>
    </>
  );
}
