import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';

import { FaHamburger, FaShoppingCart, FaMotorcycle } from 'react-icons/fa';
import {MdFormatListBulleted} from 'react-icons/md'
import { GiTicket } from 'react-icons/gi';

import { GoGraph } from 'react-icons/go';

import {
  Container,
  NavbarItem,
  RestaurantInfo,
  Profile,
  StyledCollapse,
  LinkMenu,
  RestaurantInfoTitle
} from './styles';

import { useAuth } from '~/context/AuthContext';

function SideBar({ collapsed }) {
  const { user, changeRestaurantStatus, updateDeliveryStatus } = useAuth();
  const [opened, setOpened] = useState(user.opened);
  const [deliveryOpened, setDeliveryOpened] = useState(user.is_delivery_active);

  async function handleRestaurantStatusChange() {
    const response = await changeRestaurantStatus({
      opened: !opened,
      is_delivery_active: !!deliveryOpened,
    });

    setOpened(response.opened);
    setDeliveryOpened(response.is_delivery_active);
  }

  async function handleDeliveryStatusChange() {
    const response = await updateDeliveryStatus({
      opened: !!opened,
      is_delivery_active: !deliveryOpened,
    });

    setDeliveryOpened(response.is_delivery_active);
  }

  useEffect(
    () => {
      if (!!opened !== user.opened) {
        setOpened(user.opened);
      }

      if (!!deliveryOpened !== user.is_delivery_active) {
        setDeliveryOpened(user.is_delivery_active);
      }
    },
    [user.opened, user.is_delivery_active]
  );

  return (
    <Container>
      <NavbarItem collapsed={collapsed}>
        <Profile>
          {user?.avatar ? (
            <img src={user.avatar.url} alt={user.fantasy_name} />
          ) : (
            <img
              src="https://api.adorable.io/avatars/128/abott@adorable.png"
              alt="Usuário sem foto"
            />
          )}

          <div>
            <span>{user.name}</span>
            <Link to="/profile">Editar restaurante</Link>
          </div>
        </Profile>
        <RestaurantInfoTitle>Restaurante</RestaurantInfoTitle>
        <RestaurantInfo>
          {opened ? (
            <>
              <Switch
                onChange={handleRestaurantStatusChange}
                checked={opened}
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={40}
                handleDiameter={20}
                activeBoxShadow="0 0 2px 3px #111"
                boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
              />
              <span>{opened ? 'Aberto' : 'Fechado'}</span>
            </>
          ) : (
            <>
              <Switch
                onChange={handleRestaurantStatusChange}
                checked={false}
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={40}
                handleDiameter={20}
                activeBoxShadow="0 0 2px 3px #111"
                boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
              />
              <span>{opened ? 'Aberto' : 'Fechado'}</span>
            </>
          )}
          
        </RestaurantInfo>
        {user.is_delivery_allowed && (
          <>
            <p>Delivery</p>
            <RestaurantInfo>
              {' '}
              <Switch
                onChange={handleDeliveryStatusChange}
                checked={deliveryOpened}
                checkedIcon={false}
                uncheckedIcon={false}
                disabled={!opened}
                height={20}
                width={40}
                handleDiameter={20}
                activeBoxShadow="0 0 2px 3px #111"
                boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
              />
              <span>{deliveryOpened ? 'Ativo' : 'Pausado'}</span>
            </RestaurantInfo>
          </>
        )}
        
        <ul>
          {/* <li>
            <button type="button" id="collapse1">
              <FaUtensils />
              <div>
                <span>Mesas</span>
                <div />
              </div>
            </button>
            <StyledCollapse toggler="#collapse1">
              <Link to="/bills">Acompanhar Comandas</Link>
              <Link to="/bills/close-solicitation">
                Solicitações de Fechamento de Comanda
              </Link>
              <Link to="/tables/view">Acompanhar Mesas</Link>
              <Link to="/tables">Gerenciar Mesas</Link>
            </StyledCollapse>
          </li> */}
          <li>
            <LinkMenu to="/orders">
              <FaShoppingCart />
              <div>
                <span>Pedidos</span>
              </div>
            </LinkMenu>
            {/* <StyledCollapse toggler="#collapse2">
              <Link to="/orders">Acompanhar Pedidos</Link>
            </StyledCollapse> */}
          </li>
          <li>
            <LinkMenu to="/tickets">
              <GiTicket />
              <div>
                <span>Cupons</span>
              </div>
            </LinkMenu>
          </li>

          <li>
            <LinkMenu to="/products">
              <FaHamburger />
                <div>
                  <span>Cardápio</span>
                  
                </div>
              </LinkMenu>
            </li>
            
            {/* <StyledCollapse toggler="#collapse3"> */}
              {/* <Link to="/products/categories">Categorias</Link> */}
              {/* <Link to="/products">Seus Produtos</Link> */}
              {/* <Link to="/products/complement-categories">
                Categorias de complementos
              </Link>
              <Link to="/products/complements">Complementos</Link> */}
              {/* <Link to="/products/order-product-categories">
                Ordernar Cardápio
              </Link> */}
              {/* <Link to="/products/order-products">Ordernar Produtos</Link> */}
            {/* </StyledCollapse> */}
          
          {/* <li>
            <button type="button" id="collapse4">
              <FaUserMinus />

              <div>
                <span>Garçons</span>
                <div />
              </div>
            </button>
            <StyledCollapse toggler="#collapse4">
              <Link to="/waiters">Gerenciar Garçons</Link>
            </StyledCollapse>
          </li> */}
          {/* <li>
            <button type="button" id="collapse5">
              <FaCashRegister />
              <div>
                <span>Caixa</span>
                <div />
              </div>
            </button>
            <StyledCollapse toggler="#collapse5">
              <Link to="/box">Controle de Caixa</Link>
              <Link to="/box/payment">Formas de Pagamento</Link>
            </StyledCollapse>
          </li> */}
          <li>
            <button type="button" id="collapse6">
              <GoGraph />
              <div>
                <span>Relatórios</span>
                <div />
              </div>
            </button>
            <StyledCollapse toggler="#collapse6">
              <Link to="/reports">Página Principal</Link>
              <Link to="/reports/financial">Relatório de Finanças</Link>
              <Link to="/reports/products">Relatório de Produtos</Link>
              <Link to="/reports/rate">Relatório de Avaliações</Link>
              <Link to="/reports/sms">Relatório de SMS</Link>
            </StyledCollapse>
          </li>
          {user.is_delivery_allowed && (
            <li>
              <button type="button" id="collapse7">
                <FaMotorcycle />
                <div>
                  <span>Delivery</span>
                  <div />
                </div>
              </button>
              <StyledCollapse toggler="#collapse7">
              
                <Link to="/delivery/addresses">Endereços e Configurações</Link>
                 </StyledCollapse>
            </li>
          )}

          {/* {user.is_delivery_allowed && (
            <li>
              <LinkMenu to="/delivery/addresses">
                {/* <GoPackage /> 
                <FaMotorcycle />
                <div>
                  <span>Delivery</span>
                </div>
              </LinkMenu>
            </li>
          )} */}
        </ul>
      </NavbarItem>
    </Container>
  );
}

export default SideBar;
