import React, { useRef } from 'react';

import ReactToPrint from 'react-to-print';
import { AiOutlinePrinter } from 'react-icons/ai';
import PrintReportProducts from '~/components/Prints/PrintReportProducts';

import { Print, DisplayImprimir, PrintDiv } from './styles';

export default function PrintReportButton({
  categories,
  inicialFormat,
  finalFormat,
  total_restaurant_amount,
  total_restaurant_price,
  payments,
  totalBox,
  buyers,
  restaurantName,
  categoriesCanceled,
  total_restaurant_price_canceled,
  total_restaurant_amount_canceled,
}) {
  const componentRef = useRef(null);
  return (
    <>
      <PrintDiv>
        <ReactToPrint
          trigger={() => (
            <Print>
              <AiOutlinePrinter color="white" size={20} />
            </Print>
          )}
          content={() => componentRef.current}
        />
      </PrintDiv>
      <DisplayImprimir>
        <PrintReportProducts
          categories={categories}
          total_restaurant_amount={total_restaurant_amount}
          total_restaurant_price={total_restaurant_price}
          payments={payments}
          totalBox={totalBox}
          buyers={buyers}
          inicialFormat={inicialFormat}
          finalFormat={finalFormat}
          ref={componentRef}
          restaurantName={restaurantName}
          categoriesCanceled={categoriesCanceled}
          total_restaurant_price_canceled={total_restaurant_price_canceled}
          total_restaurant_amount_canceled={total_restaurant_amount_canceled}
        />
      </DisplayImprimir>
    </>
  );
}
