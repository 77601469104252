import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 10px;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  margin: 20px auto;
  width: 80%;
  padding: 30px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

export const Content = styled.div`
  width: 100%;
  heigth: auto;
`;
export const TablesPlace = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 300px;
  background-color: white;
  border-radius: 8px;
  overflow-x: auto;
`;

export const BillExplanation = styled.div`
  width: 50%;
  margin-left: 60px;
  margin-top: 20px;
`;
