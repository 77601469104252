import React from 'react';

import { GiTable } from 'react-icons/gi';

import { Header, Bloco, TableNumber, Icon, Total, NoClients } from './styles';

export default function TablesClosed({ table }) {
  return (
    <Bloco>
      <Header>
        <TableNumber></TableNumber>
        <Total>
          <span></span>
        </Total>
        <Icon>
          <span>{table}</span>
          <span>
            <GiTable color="#8c2500" size={30} />
          </span>
        </Icon>
      </Header>

      <NoClients>
        <span>Sem clientes</span>
      </NoClients>
    </Bloco>
  );
}
