import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import AuthLayout from '~/pages/_layouts/auth';
import AdminLayout from '~/pages/_layouts/admin';

import { useAuth } from '~/context/AuthContext';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  // const { signed } = store.getState().auth;
  const { user } = useAuth();

  const Layout = user ? AdminLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={(props) => {
        return isPrivate === !!user ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: isPrivate ? '/' : '/orders' }} />
        );
      }}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
