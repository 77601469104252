import React, { useState, useRef, useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FiMenu } from 'react-icons/fi';

import Switch from 'react-switch';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { MdDelete, MdEdit, MdViewHeadline } from 'react-icons/md';
import { Modal, ModalBody } from 'reactstrap';
import ImageInput from '~/components/Form/ImageInput';
import SaveButton from '~/components/Buttons/SaveButton';
import OrderProductContext from '../../../pages/Products/ProductsTables/context';

import {
  Actions,
  ProductTableRow,
  ProductImage,
  MenuItemPromotion,
  EditButton,
  DeleteButton,
  FeaturedProductButton,
  DeleteAndEditActions,
  NoImage,
  Description,
  ProductName,
  ProductPrice,
  ProductDescription,
  ProductAvailabe,
  ButtonsTd,
  ItemTd,
  ProductAvatar,
} from './styles';
import ModalFeaturedProduct from '../ModalFeaturedProduct';
import EditProduct from '~/pages/Products/Edit';

import api from '~/services/api';
import formatValue from '../../../utils/formatValue';

export default function ItemProduct({
  product,
  category,
  handleDelete,
  getFeaturedProduct,
  is_priority,
  getCategories,
  index,
}) {
  const [modal, setModal] = useState(false);
  const [modalImage, setModalImage] = useState(false);
  const [modalEditProduct, setModalEditProduct] = useState(false);

  // drag and drop
  const ref = useRef();
  const { move } = useContext(OrderProductContext);

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'PRODUCTCARD', index, product_id: product.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: 'PRODUCTCARD',
    hover(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      if (draggedIndex === targetIndex) {
        return;
      }

      const targetSize = ref.current.getBoundingClientRect();
      const targetCenter = targetSize.height / 2;
      const draggedOffset = monitor.getClientOffset();
      const draggedTop = draggedOffset.y - targetSize.top;
      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return;
      }
      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return;
      }
      move(item.product_id, product.id, draggedIndex, targetIndex);

      item.index = targetIndex;
    },
  });

  dragRef(dropRef(ref));

  function toggle() {
    setModal(!modal);
  }

  function toggleImage() {
    setModalImage(!modalImage);
  }

  function toggleModalEditProduct() {
    setModalEditProduct(!modalEditProduct);
  }

  const initialData = {
    image_id: {
      id: product.image ? product.image.id : null,
      url: product.image ? product.image.url : null,
    },
  };

  async function handleSubmit(data) {
    try {
      const parsedData = {};

      if (data.image_id) {
        parsedData.image_id = Number(data.image_id);
      }

      await api.put(`restaurants/products/${product.id}`, parsedData);

      toast.success('Produto atualizado com sucesso!');
      getCategories();
    } catch (error) {
      toast.error('Falha ao atualizar produto');
      console.log(error);
    }
  }

  async function handleSubmitFeaturedProduct(data) {
    try {
      const update = {
        title: data.title,
        product_id: product.id,
      };

      await api.put('restaurants/product/promotion', update);
      toast.success('Produto adicionado aos detaques com sucesso!');

      getCategories();
    } catch (err) {
      toast.error('Erro ao adicionar produto ao destaque!');
    }
  }

  async function handleChangeProduct(value, event, idx) {
    const id = Number(idx);
    try {
      await api.put(`restaurants/product-available/${id}`, {
        available: value,
      });
      toast.success('Produto alterado com sucesso!');
      getCategories();
    } catch (err) {
      toast.error('Erro ao ativar/ desativar produto!');
    }
  }

  return (
    <>
      <ProductTableRow
        available={product.available}
        sold_off={product.sold_off}
        isDragging={isDragging}
        ref={ref}
      >
        <ItemTd>
          <FiMenu title="Clique e arraste para ordenar" size={18} />
          <ProductAvatar>
            {product.image ? (
              <ProductImage
                src={product.image.url}
                alt="Foto do produto"
                onClick={toggleImage}
                title="Clique para atualizar a foto"
              />
            ) : (
              <NoImage
                onClick={toggleImage}
                title="Clique para atualizar a foto"
              />
            )}
          </ProductAvatar>
          <ProductDescription>
            <ProductName>{product.name}</ProductName>
            <Description>{product.description}</Description>
          </ProductDescription>
        </ItemTd>

        <ProductPrice>
          {product.price_promotion ? (
            <>
              <MenuItemPromotion>
                {formatValue(product.price)}
              </MenuItemPromotion>
              <p>{formatValue(product.price_promotion)}</p>
            </>
          ) : (
            <p>{formatValue(product.price)}</p>
          )}
        </ProductPrice>
        <ProductAvailabe>
          <Switch
            id={`${product.id}`}
            onChange={handleChangeProduct}
            draggable
            checked={product.available}
            checkedIcon={false}
            uncheckedIcon={false}
            height={20}
            width={40}
            handleDiameter={20}
            activeBoxShadow="0 0 2px 3px #111"
            boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
          />
        </ProductAvailabe>
        <ButtonsTd>
          <Actions>
            <DeleteAndEditActions>
              {is_priority && (
                <FeaturedProductButton onClick={toggle}>
                  <div>
                    <MdViewHeadline size={17} />
                    Destacar
                  </div>
                </FeaturedProductButton>
              )}

              <EditButton onClick={toggleModalEditProduct}>
                <div>
                  <MdEdit size={17} />
                  Editar
                </div>
              </EditButton>
              <DeleteButton onClick={() => handleDelete(product.id)}>
                <div>
                  <MdDelete size={17} />
                  Excluir
                </div>
              </DeleteButton>
            </DeleteAndEditActions>
          </Actions>
        </ButtonsTd>
      </ProductTableRow>

      <Modal isOpen={modal} toggle={toggle} size="md">
        <ModalBody>
          <ModalFeaturedProduct
            product={product}
            getFeaturedProduct={getFeaturedProduct}
            handleSubmitFeaturedProduct={handleSubmitFeaturedProduct}
          />
        </ModalBody>
      </Modal>

      <Modal isOpen={modalImage} toggle={toggleImage} size="md">
        <ModalBody>
          <h3 className="text-center" style={{ marginBottom: 32 }}>
            Atualizar foto do produto
          </h3>
          <p className="text-center">
            Clique sobre a imagem e faça upload da nova foto do produto.
          </p>
          <Form initialData={initialData} onSubmit={handleSubmit}>
            <ImageInput name="image_id" alt="Foto do produto" />
            <SaveButton type="submit">Salvar</SaveButton>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalEditProduct}
        toggle={toggleModalEditProduct}
        size="lg"
      >
        <ModalBody>
          <EditProduct
            product={product}
            category={category}
            getCategory={getCategories}
            toggle={toggleModalEditProduct}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
