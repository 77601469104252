import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { v4 } from 'uuid';

import { Container, Card, Header, AddressesTable, StyledLink } from './styles';
import { Spinner, Row, Col, Button } from 'reactstrap';
import api from '~/services/api';
import apiCep from '~/services/apiCep';
import RegisterButton from '~/components/Buttons/RegisterButton';
import CepItem from '~/components/Delivery/CepItem';
import { Form } from '@unform/web';
import Input from '~/components/Form/Input';

function CepDeliveryConfig() {
  const [ceps, setCeps] = useState([]);
  const location = useLocation();
  const { address } = location.state;

  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);

  const getAddressCeps = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `restaurants/delivery-address-zip-codes/${address.id}`
      );

      setCeps(response.data);
      setLoading(false);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [address.id]);

  const getAddressCepsAfter = useCallback(async () => {
    try {
      setSearching(true);
      let response = await api.get(
        `restaurants/delivery-address-zip-codes/${address.id}`
      );
      if (response.data.length === 0) {
        getAddressCepsAfter();
        setLoading(true);
      } else {
        setLoading(false);
        setSearching(false);
      }
      setCeps(response.data);
      setLoading(false);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [address.id]);

  const [message, setMessage] = useState('');

  const getNewCeps = useCallback(
    async (data) => {
      try {
        const where = encodeURIComponent(
          JSON.stringify({
            bairro: {
              $regex: data.neighborhood,
            },
          })
        );
        const response = await apiCep.get(
          `Cepcorreios_CEP?count=1&limit=10000&where=${where}`,
          {
            headers: {
              'X-Parse-Application-Id':
                'g1lrTDTwZdQHxgs3cWTlbOqQA4rw0bsZAznBdIZu',
              'X-Parse-REST-API-Key':
                'FvhTyI4q4OzjUFN0SIrtJUMwTrzhRs3scf8wJCST',
            },
          }
        );

        let updateCeps = [];
        console.log(response);

        if (response.data.count === 0) {
          setMessage(
            'Não foi encontrado nenhum bairro com esse nome. Veja as instruções abaixo e confira se digitou como esta no site do Correios.'
          );
        } else {
          response.data.results.forEach((c) => {
            updateCeps.push({
              restaurant_delivery_address_id: address.id,
              zip_code: c.CEP,
              city: c.cidade,
              street: c.logradouro,
              is_active: true,
              price: parseFloat(address.delivery_tax_price),
            });
          });

          let toSend = [];

          if (updateCeps.length > 500) {
            let loops = parseInt(updateCeps.length / 500);
            const resto = updateCeps.length % 500;
            if (resto > 0) {
              loops++;
            }

            for (let i = 0; i < loops; i++) {
              let arrayLocal = [];
              for (let j = i * 500; j < (i + 1) * 500; j++) {
                if (j < updateCeps.length) {
                  arrayLocal.push(updateCeps[j]);
                }
              }
              toSend.push({ zip_codes: arrayLocal });
            }
          } else {
            toSend = [{ zip_codes: updateCeps }];
          }

          toSend.forEach(async (code) => {
            await api.post('restaurants/delivery-address-zip-codes', code);
          });
          getAddressCepsAfter();
          setMessage('');
        }
      } catch (err) {
        setLoading(false);
        toast.error('Erro ao carregar informações');
        setMessage(
          'Não foi encontrado nenhum bairro com esse nome. Veja as instruções abaixo e confira se digitou como esta no site do Correios.'
        );
      }
    },
    [address.delivery_tax_price, address.id, getAddressCepsAfter]
  );

  const handleSubmit = useCallback(
    async (data) => {
      setLoading(true);
      await getNewCeps(data);
      setLoading(false);
    },
    [getNewCeps]
  );

  useEffect(() => {
    try {
      getAddressCeps();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getAddressCeps]);

  return (
    <Container>
      <Header>
        <div>
          <h2>CEPs</h2>
          <h4>Bairro de {address.neighborhood}</h4>
          <p>Selecione os CEPs que seu estabelecimento atende nesse bairro.</p>
        </div>
        <StyledLink
          to={{
            pathname: '/delivery/addresses/cep/new',
            state: {
              address,
            },
          }}
        >
          <RegisterButton>Adicionar</RegisterButton>
        </StyledLink>
      </Header>

      {ceps.length === 0 && !loading && !searching ? (
        <div>
          <Form initialData={address} onSubmit={handleSubmit}>
            <Card>
              <h6>
                <strong>
                  Ainda não há nenhum CEP cadastrado para o bairro{' '}
                  {address.neighborhood}.
                </strong>
              </h6>
              <Row>
                <Col md="12">
                  <Input
                    label="Nome do Bairro"
                    name="neighborhood"
                    type="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div style={{ color: 'red' }}>
                    <strong>{message}</strong>
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingTop: 15 }}>
                <Col md="11">
                  Só serão encontrados os CEPs do bairro, se a forma escrita da
                  busca for igual está nos sites do correis. Para descobrir como
                  o bairro está salvo no site do correios, é possível buscar no
                  site do próprio correios,{' '}
                  <a href="https://buscacepinter.correios.com.br/app/endereco/index.php">
                    aqui.
                  </a>
                  <div style={{ paddingTop: 10 }}>
                    Exemplos: "Jardim da Penha" e não "jardim da penha" ou
                    "JARDIM DA PENHA".
                  </div>
                </Col>
                <Col md="1">
                  <Button style={{ background: 'green' }} type="submit">
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Card>
          </Form>
        </div>
      ) : (
        <AddressesTable borderless>
          <thead>
            <tr>
              <th>Ativo</th>
              <th>CEP</th>
              <th>Cidade</th>
              <th>Lougradouro</th>
              <th>Taxa de entrega</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {ceps !== [] ? (
              ceps.map((cep) => (
                <CepItem cep={cep} address={address} key={v4()} />
              ))
            ) : (
              <h1>Vazio</h1>
            )}
          </tbody>
        </AddressesTable>
      )}

      {loading || searching ? (
        <div style={{ textAlign: 'center', paddingTop: 15 }}>
          <Spinner
            style={{ width: '3rem', height: '3rem' }}
            type="grow"
            color="danger"
          />
        </div>
      ) : (
        <></>
      )}
    </Container>
  );
}

export default CepDeliveryConfig;
