import React from 'react';

import { RiKnifeLine } from 'react-icons/ri';
import { Spinner } from 'reactstrap';
import { Content, Icon, ItemsCount, LoadingDiv } from '../styles';
import { Header, Bloco } from './styles';
import OrderAndamento from '~/components/Orders/Items/OrderAndamento';

export default function CardOrdersAndamento({
  baskets,
  handleItemDone,
  handleCancelItem,
  loading,
}) {
  const count_orders = baskets.length;
  return (
    <Bloco>
      <Header>
        <Icon>
          <RiKnifeLine color="#fff" size={25} />
        </Icon>
        EM ANDAMENTO
        <ItemsCount>{count_orders}</ItemsCount>
      </Header>
      <Content>
        {loading ? (
          <LoadingDiv>
            <Spinner color="warning" />
          </LoadingDiv>
        ) : (
          baskets.map((item) => (
            <OrderAndamento
              key={item.id}
              handleCancelItem={handleCancelItem}
              handleItemDone={handleItemDone}
              item={item}
            />
          ))
        )}
      </Content>
    </Bloco>
  );
}
