import styled from 'styled-components';

export const Header = styled.div.attrs((props) => ({
  color: props.color,
}))`
  justify-content: left;
  border-radius-top: 8px;
  font-size: 18px;
  padding-top: 10px;
  height: 40px;
  text-align: left;
  background-color: ${(props) => props.color};
  color: white;
  padding-left: 10px;
  align-items: left;
  display: flex;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #343a40;
  border-bottom: none;

  &:hover {
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  display: flex;
  font-size: 25px;
  height: 50px;
  width: 100%;
  text-align: right;
  align-items: center;
  clear: both;
  border: 1px solid #343a40;
  border-radius: 0px 0px 8px 8px;
  border-top: none;
`;

export const Content = styled.div`
  display: flex;
  font-size: 25px;
  width: 100%;
  text-align: right;
  align-items: center;
  clear: both;
  border: 1px solid #343a40;
  border-top: none;
  border-bottom: none;
`;

export const Icon = styled.div`
  font-size: 25px;
  height: 30px;
  width: 30%;
  margin-left: -10px;
  text-align: center;
  align-self: center;
`;

export const Value = styled.div`
  font-size: 25px;
  height: 30px;
  width: 60%;
  right: 0;
`;

export const Bloco = styled.div.attrs((props) => ({
  color: props.color,
}))`
  margin: 20px auto;
  width: 95%;
  min-width: 200px;
  min-height: 90px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  margin-left: 20px auto;
  background: white;
  border-radius: 8px;
  color: black;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  text-align: center;
`;

export const IconTakeat = styled.span`
  float: left;
  margin-left: 25px;
  margin-top: -3px;

  img {
    width: 25px;
    heigth: 50px;
  }
`;

export const TagWhite = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  margin-right: 10px;
`;

export const TotalProducts = styled.div`
  padding-top: 8px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
  span {
    color: grey;
    font-size: 12px;
  }
`;

export const TotalSold = styled.div`
  padding-top: 8px;
  padding-left: 10px;
  display: flex;
  right: 0;
  margin-right: 10px;
  flex-direction: column;
  text-align: right;
  position: absolute;
  span {
    color: grey;
    font-size: 12px;
  }
`;

export const Percent = styled.div`
  display: flex;
  right: 0;
  margin-right: 10px;
  flex-direction: row;
  text-align: right;
  position: absolute;
  color: white;
  padding-right: 5px;

  span {
    padding-top: 2px;
    padding-left: 10px;
  }
`;

export const ProductsTable = styled.table`
  width: 98%;
  margin: 10px auto 10px auto;
  border-collapse: initial;
  border-spacing: 0 20px;
  border-radius: 8px;
  padding: 0 20px;
  font-size: 15px;
  thead {
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #eee;
      padding-bottom: 5px;
      text-align: left;
      justify-content: center;
    }

    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;
