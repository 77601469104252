import React from 'react';

import {
  FaCashRegister,
  FaCreditCard,
  FaMoneyBillAlt,
  FaRegCreditCard,
} from 'react-icons/fa';

import { RiMoneyDollarCircleLine } from 'react-icons/ri';

import { Header, Bloco, Content, Icon, Value, Comment } from './styles';

import picpayLogo from '~/assets/img/picpay-icon.png';

export default function BoxCard({ name, total, type, color, comment }) {

  return (
    <Bloco color={color}>
      <Header>{name}</Header>
      <Content>
        <Icon>
          {type === 'total' ? <FaCashRegister /> : ''}
          {type === 'cash' ? <FaMoneyBillAlt /> : ''}
          {type === 'credit' ? <FaCreditCard /> : ''}
          {type === 'debit' ? <FaRegCreditCard /> : ''}
          {type === 'other' ? <RiMoneyDollarCircleLine /> : ''}
          {type === 'picpay' && (
            <img
              src={picpayLogo}
              style={{ width: 30, height: 30 }}
              alt="Logo Picpay"
            />
          )}
        </Icon>
        <Value>R${total}</Value>
        <Comment color={color}>
          <span>{comment}</span>
        </Comment>
      </Content>
    </Bloco>
  );
}
