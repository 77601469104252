import React from 'react';

import {
  Container,
  Table,
  Body,
  Phone,
  Time,
  Items,
  Item,
  ItemName,
  Complements,
  ComplementsTitle,
  Obs,
  Price,
  ComplementsPrice,
  TotalDiv,
  TotalRow,
  TotalName,
  TotalPrice,
} from './styles';

import formatTime from '~/services/formatTime';
// import formatPhone from '~/services/formatPhoneString';

export default function EyeModalOrders({ item }) {
  // const userName = formatPhone(item.buyer.phone);
  const userName = item.buyer.phone;

  return (
    <Container>
      <Table>
        <strong>Pedido #{item.attendance_password.password_number}</strong>
      </Table>
      <Body>
        <Phone>{userName}</Phone>
        <Time>{formatTime(item.start_time)}</Time>
      </Body>
      <Items>
        {item.orders.map((order) => (
          <Item key={order.id}>
            <ItemName>
              <strong>
                {order.amount}x {order.product.name}
              </strong>
              <Price>
                <strong>R${order.total_price}</strong>
              </Price>
            </ItemName>
            {order.complement_categories.map((category) => (
              <div key={category.id}>
                <ComplementsTitle>
                  {category.complement_category.name}:
                </ComplementsTitle>
                {category.order_complements.map((complement) => (
                  <Complements key={complement.id}>
                    - {complement.amount}x {complement.complement.name}{' '}
                    <ComplementsPrice>
                      R${complement.complement.price}
                    </ComplementsPrice>
                  </Complements>
                ))}
              </div>
            ))}
            {order.details !== '' && <Obs>Obs: {order.details}</Obs>}
          </Item>
        ))}
      </Items>
      <TotalDiv>
        <TotalRow>
          <TotalName>
            <strong>TOTAL</strong>
          </TotalName>
          <TotalPrice>
            <strong>R${item.total_price}</strong>
          </TotalPrice>
        </TotalRow>
      </TotalDiv>
    </Container>
  );
}
