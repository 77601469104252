import React from 'react';

import { TiCancel } from 'react-icons/ti';
import { Spinner } from 'reactstrap';
import { Header, Bloco } from './styles';
import { Content, Icon, ItemsCount, LoadingDiv } from '../styles';
import OrderCanceled from '~/components/Orders/Items/OrderCanceled';

export default function CardOrdersCanceled({ baskets, loading }) {
  const count_orders = baskets.length;
  return (
    <Bloco>
      <Header>
        <Icon>
          <TiCancel color="#fff" size={25} />
        </Icon>
        CANCELADOS
        <ItemsCount>{count_orders}</ItemsCount>
      </Header>
      <Content>
        {loading ? (
          <LoadingDiv>
            <Spinner color="danger" />
          </LoadingDiv>
        ) : (
          baskets.map((item) => <OrderCanceled key={item.id} item={item} />)
        )}
      </Content>
    </Bloco>
  );
}
