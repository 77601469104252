import React from 'react';

import { MdAdd } from 'react-icons/md';

import { ButtonContainer } from './styles';

export default function RegisterButton({ text, title, ...rest  }) {
  return (
    <ButtonContainer type="button" title={title} {...rest}>
      <MdAdd color="#fff" size={16} />
      <span>{text || 'CADASTRAR' }</span>
    </ButtonContainer>
  );
}
