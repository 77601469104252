import React, { useRef } from 'react';
import * as Yup from 'yup';
import {FiMail, FiLock} from 'react-icons/fi'


import logo from '~/assets/img/logoPedidos.png';
import { Container, StyledForm, Card, LeftSide, RightSide, StyledInput } from './styles';

import LoginInput from '~/components/Form/LoginInput';

import { useAuth } from '~/context/AuthContext';

function SignIn() {
  const formRef = useRef(null);

  const { signIn, loading } = useAuth();

  async function handleSubmit({ email, password }) {
    try {
      signIn({ email, password });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        formRef.current.setFieldError('email', 'Insira um e-mail válido');
      }
    }
  }
  return (
    <Container>
      <LeftSide>
        <img src={logo} alt="Logo takeat Dashboard" />
      </LeftSide>
      <RightSide>
     
      <Card>
        <h1>Faça seu login</h1>
        <StyledForm ref={formRef} onSubmit={handleSubmit}>
          <LoginInput icon={FiMail} name="email" type="email" placeholder="E-mail" />

          <LoginInput icon={FiLock} name="password" type="password" placeholder="Senha" />

          <button type="submit">{loading ? 'Carregando...' : 'Acessar'}</button>
        </StyledForm>
      </Card>
      </RightSide>
     
    </Container>
  );
}

export default SignIn;
