import React from 'react';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

import { Container, Logo } from './styles';
import logo from '~/assets/img/logosolo.png';

import { useAuth } from '~/context/AuthContext';

function Navbar({ toggleCollapse, collapsed }) {
  const { signOut } = useAuth();
  function handleLogout() {
    signOut();
  }

  return (
    <Container collapsed={collapsed}>
      <div>
        <button type="button" onClick={toggleCollapse}>
          {collapsed ? (
            <FaArrowRight color="#fff" />
          ) : (
            <FaArrowLeft color="#fff" />
          )}
        </button>
      </div>
      <Logo collapsed={collapsed}>
        <img src={logo} alt="Logo Takeat" />
        <button type="button" onClick={handleLogout}>
          Logout
        </button>
      </Logo>
    </Container>
  );
}

export default Navbar;
