import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

import { Input } from '@material-ui/core';
import {
  Wrapper,
  Header,
  DateSelectorDiv,
  DateDiv,
  SubmitDate,
  Card,
  Content,
} from './styles';

import history from '~/services/history';

import BackButton from '~/components/Buttons/BackButton';

import Bills from '~/components/Bills';

import api from '~/services/api';
import UpdateButton from '~/components/Buttons/UpdateButton';

function ViewBills() {
  const data = new Date();

  let hour = '';
  let day = '';
  let month = '';

  if (data.getUTCHours() - 3 < 10 && data.getUTCHours() - 3 >= 0) {
    hour = `0${data.getUTCHours() - 3}`;
  } else {
    hour = `${data.getUTCHours() - 3}`;
    if (data.getUTCHours() === 0) {
      hour = '21';
    } else if (data.getUTCHours() === 1) {
      hour = '22';
    } else if (data.getUTCHours() === 2) {
      hour = '23';
    }
  }

  if (data.getUTCDate() < 10) {
    day = `0${data.getUTCDate()}`;
    if (hour === 21 || hour === 22 || hour === 23) {
      day = `0${data.getUTCDate() - 1}`;
    }
  } else {
    day = `${data.getUTCDate()}`;
    if (hour === 21 || hour === 22 || hour === 23) {
      day = `${data.getUTCDate() - 1}`;
    }
  }

  if (data.getUTCMonth() + 1 < 10) {
    month = `0${data.getUTCMonth() + 1}`;
  } else {
    month = `${data.getUTCDay() + 1}`;
  }

  const inicial = `${data.getFullYear()}-${month}-${day}T00:00:00`;
  const defaultInicial = `${data.getFullYear()}-${month}-${day}T00:00`;
  const final = `${data.getFullYear()}-${month}-${day}T23:59:59`;
  const defaultFinal = `${data.getFullYear()}-${month}-${day}T23:59`;

  const [inicialDate, setInicialDate] = useState(inicial);
  const [finalDate, setFinalDate] = useState(final);

  const [sessions, setSessions] = useState([]);
  // const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);

  // const getPayments = useCallback(async () => {
  //   try {
  //     const response = await api.get('/restaurants/payments', {
  //       params: {
  //         start_date: `${inicialDate}`,
  //         end_date: `${finalDate}`,
  //       },
  //     });
  //     setPayments(response.data);
  //   } catch (err) {
  //     toast.error('Erro ao carregar informações');
  //   }
  // }, [inicialDate, finalDate]);

  const getSessions = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/table-sessions', {
        params: {
          start_date: `${inicialDate}`,
          end_date: `${finalDate}`,
        },
      });
      setSessions(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  useEffect(() => {
    // getPayments();
    getSessions();
    setLoading(false);
  }, [getSessions]);

  async function handleUpdate() {
    setLoading(true);
    getSessions();
    setLoading(false);
  }

  function handleOnChangeInicial(event) {
    setInicialDate(`${event.target.value}`);
  }

  function handleOnChangeFinal(event) {
    setFinalDate(`${event.target.value}`);
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Acompanhar Comandas</h2>
          <p>
            Aqui você acompanha as comandas das mesas e consegue controlar as
            contas individuais fechadas e a(s) forma(s) de pagamento usada(s).
          </p>
          <UpdateButton onClick={handleUpdate} />
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Card>
        <Content>
          <DateSelectorDiv>
            <DateDiv>
              <Input
                id="datetime-local"
                label="Data Inicial"
                type="datetime-local"
                name="initialDate"
                defaultValue={defaultInicial}
                className="data"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleOnChangeInicial}
              />
            </DateDiv>
            <DateDiv>
              <Input
                id="datetime-local"
                label="Data Final"
                type="datetime-local"
                name="finalDate"
                defaultValue={defaultFinal}
                className="data"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleOnChangeFinal}
              />
            </DateDiv>
            <div>
              <SubmitDate onClick={getSessions}>Filtrar</SubmitDate>
            </div>
          </DateSelectorDiv>
          <Bills
            sessions={sessions}
            getSessions={getSessions}
            loading={loading}
          />
        </Content>
      </Card>
    </Wrapper>
  );
}

export default ViewBills;
