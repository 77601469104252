import styled from 'styled-components';

export const PrintDiv = styled.div`
  right: 0;
  display: flex;
  width: 100%;
  align-itens: right;
  text-align: right;
  justify-content: right;
  clear: both;
  position: relative;
`;

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  margin-right: 10px;
  border: none;
  background: #d60300;
  right: 0;
  margin-left: auto;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const DisplayImprimir = styled.div`
  width: 90%;
`;
