import styled from 'styled-components';

export const CancelButton = styled.button`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  align-self: center;
  float: left;
  position: absolute;
  clear: both;
  left: 0;
  margin-left: 6px;
  margin-bottom: 5px;
  border: none;
  background: white;
  text-align: center;
  font-size: 26px;
  justify-content: center;

  // :hover {
  //   filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  // }
`;
export const DisplayNoneImprimir = styled.button`
  display: none;
`;
export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  float: right;
  position: relative;
  display: block;
  right: 0;
  margin-right: 10px;
  border: none;
  background: #f0b402;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;
