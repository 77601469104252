import styled from 'styled-components';
import { Row } from 'reactstrap';
import { Form } from '@unform/web';

export const Wrapper = styled.div`
  margin: 10px;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  padding: 30px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CheckboxInfo = styled.div`
  h3 {
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 10px;
  }
  span {
    font-weight: bold;
    margin-bottom: 5px;
  }
`;

export const CheckBoxContainer = styled(Row)`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

export const StyledForm = styled(Form)``;
