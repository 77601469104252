import React from 'react';

import { MdDone } from 'react-icons/md';
import { Spinner } from 'reactstrap';
import { Header, Bloco } from './styles';
import { Content, Icon, ItemsCount, LoadingDiv } from '../styles';
import OrderDone from '~/components/Orders/Items/OrderDone';

export default function CardOrdersDone({
  baskets,
  handleItemFinished,
  handleCancelItem,
  loading,
}) {
  const count_orders = baskets.length;
  return (
    <Bloco>
      <Header>
        <Icon>
          <MdDone color="#fff" size={25} />
        </Icon>
        PRONTOS
        <ItemsCount>{count_orders}</ItemsCount>
      </Header>
      <Content>
        {loading ? (
          <LoadingDiv>
            <Spinner color="warning" />
          </LoadingDiv>
        ) : (
          baskets.map((item) => (
            <OrderDone
              key={item.id}
              handleCancelItem={handleCancelItem}
              handleItemFinished={handleItemFinished}
              item={item}
            />
          ))
        )}
      </Content>
    </Bloco>
  );
}
