import React from 'react';

import { ButtonContainer } from './styles';

export default function SaveButton({ message, title, ...rest }) {
  return (
    <ButtonContainer type="button" title={title} {...rest}>
      <span>{message}</span>
    </ButtonContainer>
  );
}
