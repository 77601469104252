import React from 'react';

import { FaHands } from 'react-icons/fa';
import { Spinner } from 'reactstrap';
import { Header, Bloco } from './styles';
import { Content, Icon, ItemsCount, LoadingDiv } from '../styles';
import OrderFinished from '~/components/Orders/Items/OrderFinished';

export default function CardOrdersFinished({ baskets, loading, handleUpdate }) {
  const count_orders = baskets.length;
  return (
    <Bloco>
      <Header>
        <Icon>
          <FaHands color="#fff" size={25} />
        </Icon>
        ENTREGUES
        <ItemsCount>{count_orders}</ItemsCount>
      </Header>
      <Content>
        {loading ? (
          <LoadingDiv>
            <Spinner color="success" />
          </LoadingDiv>
        ) : (
          baskets.map((item) => <OrderFinished key={item.id} item={item} handleUpdate={handleUpdate}/>)
        )}
      </Content>
    </Bloco>
  );
}
