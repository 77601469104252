import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { useLocation } from 'react-router-dom';

import { Row, Col } from 'reactstrap';

import { Container, Card, Header, Content } from './styles';

import api from '~/services/api';
import history from '~/services/history';
import Input from '~/components/Form/Input';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function CepDeliveryCreate() {
  const location = useLocation();
  const { address } = location.state;

  const handleUpdate = useCallback(
    async (data) => {
      try {
        const handleData = {
          zip_codes: [
            {
              restaurant_delivery_address_id: address.id,
              zip_code: data.zip_code,
              city: data.city,
              street: data.street,
              is_active: true,
              price: data.delivery_tax_price,
            },
          ],
        };
        console.log(handleData);
        await api.post(`restaurants/delivery-address-zip-codes`, handleData);
        history.goBack();
        toast.success('CEP cadastrado.');
      } catch (err) {
        toast.error('Erro ao cadastrar CEP.');
      }
    },
    [address.id]
  );

  console.log(address);

  return (
    <Container>
      <Header>
        <div>
          <h2>Adicionar CEP em {address.neighborhood}</h2>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>

      <Card>
        <Form initialData={address} onSubmit={handleUpdate}>
          <Content>
            <Row>
              <Col sm="3" lg="3">
                <Input
                  label="CEP (Somente números)"
                  name="zip_code"
                  type="text"
                />
              </Col>
              <Col sm="9" lg="9">
                <Input label="Logradouro" name="street" type="text" />
              </Col>
            </Row>
            <Row>
              <Col sm="6" lg="6">
                <Input label="Cidade" name="city" type="text" disabled />
              </Col>
              <Col sm="6" lg="6">
                <Input
                  label="Taxa de Entrega (R$)"
                  name="delivery_tax_price"
                  type="number"
                  step="0.01"
                />
              </Col>
            </Row>

            <SaveButton type="submit">Salvar</SaveButton>
          </Content>
        </Form>
      </Card>
    </Container>
  );
}

export default CepDeliveryCreate;
