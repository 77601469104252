import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import './config/ReactotronConfig';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import './assets/fonts/amelia-basic-italic.ttf';

import { Router } from 'react-router-dom';
import light from './styles/theme/light';
import GlobalStyle from './styles/global';
import Routes from './routes';
import Startup from '~/components/Startup';

import history from './services/history';
import { OrderProvider } from './context/orderContext';
import { FCMProvider } from './context/FCMContext';
import { NotificationProvider } from './context/notificationContext';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <ThemeProvider theme={light}>
      <AuthProvider>
        <NotificationProvider>
          <OrderProvider>
            <FCMProvider>
              <DndProvider backend={HTML5Backend}>
                <Startup>
                  <Router history={history}>
                    <GlobalStyle />
                    <Routes />
                    <ToastContainer autoclose={3000} />
                  </Router>
                </Startup>
              </DndProvider>
            </FCMProvider>
          </OrderProvider>
        </NotificationProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
