import React from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';

import { Container, Row, Col } from 'reactstrap';
import { Wrapper, Header, Card } from './styles';

import history from '~/services/history';
import api from '~/services/api';

import Input from '~/components/Form/Input';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function CreateWaiter() {
  async function handleSubmit(data) {
    try {
      await api.post('restaurants/waiters', {
        name: data.name,
        email: data.email,
        password: data.password,
      });

      toast.success('Usuário do garçom criado com sucesso!');
      history.push('/waiters');
    } catch (error) {
      toast.error('Falha ao criar usuário de garçom');
    }
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Cadastrar Garçons</h2>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Card>
        <Form onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col sm="12">
                <Input label="Nome do Garçom:" name="name" type="text" />
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Input label="E-mail do Garçom:" name="email" type="email" />
              </Col>
              <Col sm="6">
                <Input
                  label="Senha do Garçom:"
                  name="password"
                  type="password"
                />
              </Col>
            </Row>
            <SaveButton type="submit">Cadastrar</SaveButton>
          </Container>
        </Form>
      </Card>
    </Wrapper>
  );
}

export default CreateWaiter;
