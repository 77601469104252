import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import {v4} from 'uuid';
import * as Yup from 'yup';

import { MdDelete, MdCreate, MdInfo, MdAddCircle } from 'react-icons/md';

import { useAuth } from '~/context/AuthContext';
import getValidationErrors from '..//../../../utils/getValidationErrors';

import { Row, Col, FormGroup, Label, Input as InputStrap } from 'reactstrap';
import { Container, Card, Header, Content, Table } from './styles';

import api from '~/services/api';
import history from '~/services/history';
import apiIbge from '~/services/apiIbge';
import Input from '~/components/Form/Input';
import SelectInput from '~/components/Form/SelectInput';
import CheckboxInput from '~/components/Form/Checkbox';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function DeliveryConfig({toggle, getAddresses}) {
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState({});
  const [cities, setCities] = useState([]);
  const [, setSelectedCity] = useState({});
  const [neighborhoodLimited, setNeighborhoodLimited] = useState(true);
  const [restaurantDeliveryType, setRestaurantDeliveryType] = useState()
  const formRef = useRef(null);
  
  const handleNavigate = () => {
    getAddresses();
    toggle();
  }

  const getStatesData = useCallback(async () => {
    try {
      const response = await apiIbge.get('/localidades/estados');
      const parsedStates = response.data.map((item) => {
        return {
          label: item.nome,
          value: item.sigla,
        };
      });
      setStates(parsedStates);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  const getCitiesData = useCallback(async () => {
    try {
      console.log(selectedState);
      const response = await apiIbge.get(
        `/localidades/estados/${selectedState.value}/municipios`
      );

      console.log(response.data)
      const parsedCities = response.data.map((item) => {
        return {
          label: item.nome,
          value: item.nome,
        };
      });
      setCities(parsedCities);
      console.log(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [selectedState]);

 
  const handleSubmit = useCallback(async (data) => {
    try {
      const createData = {
        country: 'BR',
        state: data.state,
        city: data.city,
        delivery_tax_price: data.delivery_tax,
        is_neighborhood_limited: data.is_neighborhood_limited,
      };
      if (data.is_neighborhood_limited) {
        createData.neighborhood = data.neighborhood;
      }
      await api.post('/restaurants/delivery-addresses', createData);

      await api.put('/restaurants/delivery-info', {
        is_delivery_by_distance: false
      })
      toast.success('Endereço salvo com sucesso!');
      handleNavigate()
    } catch (err) {
      toast.error('Erro ao salvar endereço');
    }
  }, []);

  useEffect(() => {
    try {
      getStatesData();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getStatesData]);

  useEffect(() => {
    try {
      getCitiesData();
    } catch (err) {
      toast.error('Erro ao carregar cidades');
    }
  }, [selectedState, getCitiesData]);

  return (
    <Container>
  
      <Card>
  
          <Form onSubmit={handleSubmit}>
          <Content>
            <Row>
              <h4
                style={{ paddingLeft: 10, paddingBottom: 15, paddingTop: 30 }}
              >
                Endereços
              </h4>
              <Col md="12">
                <p style={{ textAlign: 'left' }}>
                  Cadastre os endereços e as taxas de entrega do seu
                  estabelecimento aqui!
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <Row>
                  <Col sm="12" lg="12">
                    <SelectInput
                      name="state"
                      label="Selecione o estado"
                      options={states}
                      onChange={(e) => {
                        setSelectedState({ value: e.value, label: e.label });
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" lg="12">
                    <SelectInput
                      name="city"
                      label="Selecione a cidade"
                      options={cities}
                      onChange={(e) =>
                        setSelectedCity({ value: e.value, label: e.label })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" lg="12">
                    <CheckboxInput
                      label="Limitar por bairro"
                      name="is_neighborhood_limited"
                      value={neighborhoodLimited}
                      defaultChecked
                      onChange={() => {
                        setNeighborhoodLimited(!neighborhoodLimited);
                      }}
                    />
                  </Col>
                </Row>
                {neighborhoodLimited ? (
                  <>
                    <Row>
                      <Col sm="12" lg="12">
                        <Input
                          label="Nome do Bairro"
                          name="neighborhood"
                          type="text"
                          placeholder="Nome do bairro"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" lg="12">
                        <h4>Observações</h4>
                        <p>
                          O usuário, no aplicativo, poderá selecionar somente na lista de
                          bairros que estiver cadastrada!
                        </p>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
                <Row>
                  <Col sm="12" lg="12">
                    <Input
                      label="Taxa de entrega"
                      name="delivery_tax"
                      type="number"
                      placeholder="R$x.xx"
                      step="0.01"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <SaveButton type="submit">Salvar</SaveButton>
          </Content>
        </Form>
              
      </Card>
    </Container>
  );
}

export default DeliveryConfig;
