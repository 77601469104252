import React, { useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { AiOutlinePrinter, AiOutlineEye } from 'react-icons/ai';

import { Container, Modal, ModalBody } from 'reactstrap';
import { OrderNumber, ContentBody, Eye, TableNumber } from '../styles';
import { Pedido, Print, DisplayNoneImprimir } from './styles';

import formatTime from '~/services/formatTime';
import EyeBillsTable from '~/components/Tables/CloseOrder/Eye/EyeBillsTable';
import PrintBillsTable from '~/components/Prints/PrintBillsTable';

import { useAuth } from '~/context/AuthContext';

export default function CloseTable({ item, handlePrintItem }) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const { user } = useAuth();
  const componentRef = useRef(null);

  return (
    <Pedido>
      <ContentBody>
        <OrderNumber>{formatTime(item.updatedAt)}</OrderNumber>
        <TableNumber>Mesa {item.table?.table_number}</TableNumber>
        <Eye onClick={toggle}>
          <AiOutlineEye color="blue" size={20} />
        </Eye>
        <ReactToPrint
          trigger={() => (
            <Print>
              <AiOutlinePrinter color="black" size={20} />
            </Print>
          )}
          content={() => componentRef.current}
          onAfterPrint={() =>
            handlePrintItem({
              session_key: item.key,
            })
          }
        />
      </ContentBody>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeBillsTable item={item} />
          </Container>
        </ModalBody>
      </Modal>

      <DisplayNoneImprimir>
        <PrintBillsTable item={item} restaurant={user} ref={componentRef} />
      </DisplayNoneImprimir>
    </Pedido>
  );
}
