import React from 'react';
import { useLocation } from 'react-router-dom';

import { Card, Container, Header } from './styles';
import history from '~/services/history';
import BackButton from '~/components/Buttons/BackButton';
import PrintReportButton from '~/components/Buttons/PrintReportButton';

import { useAuth } from '~/context/AuthContext';

function PrintReportsProducts() {
  const location = useLocation();

  const { user } = useAuth();
  const { name } = user;
  const restaurantName = name;

  const {
    categories,
    inicialFormat,
    finalFormat,
    total_restaurant_amount,
    total_restaurant_price,
    payments,
    totalBox,
    buyers,
    categoriesCanceled,
    total_restaurant_price_canceled,
    total_restaurant_amount_canceled,
  } = location.state;

  // console.log('location state');
  // console.log(location.state);

  return (
    <Container>
      <Header>
        <div>
          <h2>Relatório de Produtos</h2>
          <p>Confira todas suas vendas detalhadamente!</p>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Card>
        <PrintReportButton
          categories={categories}
          total_restaurant_amount={total_restaurant_amount}
          total_restaurant_price={total_restaurant_price}
          payments={payments}
          totalBox={totalBox}
          inicialFormat={inicialFormat}
          finalFormat={finalFormat}
          buyers={buyers}
          restaurantName={restaurantName}
          categoriesCanceled={categoriesCanceled}
          total_restaurant_price_canceled={total_restaurant_price_canceled}
          total_restaurant_amount_canceled={total_restaurant_amount_canceled}
        />
      </Card>
    </Container>
  );
}

export default PrintReportsProducts;
