import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Wrapper, Header, Card } from './styles';

import history from '~/services/history';
import api from '~/services/api';

import Input from '~/components/Form/Input';

import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';
import ChangePasswordButton from '~/components/Buttons/ChangePasswordButton';

function EditWaiter() {
  const location = useLocation();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const { waiter } = location.state;

  const initialData = {
    name: waiter?.name,
    email: waiter?.email,
    password: waiter?.password,
  };

  async function handleSubmit(data) {
    try {
      await api.put(`restaurants/waiters/${waiter.id}`, {
        name: data.name,
        email: data.email,
        password: waiter?.password,
      });

      toast.success('Usuário de garçom atualizado com sucesso!');
    } catch (error) {
      toast.error(`Falha ao atualizar usuário do garçom ${error.message}`);
    }
  }

  // submit de mudar senha
  async function handleSubmitChangePassword(data) {
    try {
      await api.put(`restaurants/waiters/${waiter.id}`, {
        password: data.password,
        email: waiter?.email,
      });
      toast.success('Senha do garçom atualizada com sucesso!');
    } catch (err) {
      toast.error('Falha ao alterar senha.');
    }
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Editar Garçons</h2>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Card>
        <Form id="save-form" initialData={initialData} onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col sm="12">
                <Input label="Nome do Garçom:" name="name" type="text" />
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Input label="E-mail do Garçom:" name="email" type="email" />
              </Col>
              <Col sm="6">
                <label />
                <ChangePasswordButton onClick={toggle} />
              </Col>
            </Row>
            <SaveButton type="submit" form="save-form">
              Cadastrar
            </SaveButton>
          </Container>
        </Form>
      </Card>

      <Modal isOpen={modal} toggle={toggle}>
        <Form initialData={initialData} onSubmit={handleSubmitChangePassword}>
          <ModalHeader toggle={toggle}>Alterar Senha</ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col lg="12">
                  <Input label="Nova Senha" name="password" type="password" />
                  <span>A senha deve conter pelo menos 6 caracteres.</span>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" onClick={toggle}>
              Salvar
            </Button>{' '}
            <Button color="danger" onClick={toggle}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Wrapper>
  );
}

export default EditWaiter;
