import styled from 'styled-components';

export const Pedido = styled.div`
  height: 70px;
  padding-top: 5px;
  background: white;
  width: 99%;
  border-top: none;
  border-bottom: solid #dee2e6 1px;
  padding: 2px;
  display: flex;
  filter: drop-shadow(0 0 0px rgba(0, 0, 0, 0.2));
`;

export const Header = styled.div`
  height: 0px;
  width: 100%;
  display: flex;
  position: absolute;
`;

export const OrderNumber = styled.span`
  color: #6c757d;
  padding: 0px;
  display: flex;
  height: 30px;
  top: 10px;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  left: 0;
  font-size: 1.3em;
  margin-top: 4px;
  margin-left: 10px;
  border: none;
  background: white;
`;

export const Time = styled.span`
  color: #17a2b8;
  align-self: center;
  float: bottom;
  position: absolute;
  clear: both;
  bottom: 0;
  font-size: 0.9rem;
  margin-bottom: 3px;
  border: none;
  color: #c82333;
  padding-bottom: 5px;
  margin-left: 10px;
`;

export const ContentBody = styled.div`
  display: flex;
  width: 100%;
  clear: both;
`;

export const PedidoItems = styled.div`
  height: 30px;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  left: 0;
  font-size: 1.3em;
  margin-top: 4px;
  margin-left: 10px;
  border: none;
  background: white;
`;

export const Eye = styled.button`
  height: 30px;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  right: 0;
  margin-right: 50px;
  border: none;
  background: white;
`;

export const Pencil = styled.button`
  height: 30px;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  right: 0;
  margin-right: 50px;
  border: none;
  background: white;
`;

export const TableNumber = styled.span`
  align-self: center;
  border: none;
  color: #c82333;
  height: 30px;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  top: 10px;
  font-size: 1.3em;
  margin-top: 4px;
  margin-left: 110px;
  border: none;
  background: white;
`;

export const PhoneNumber = styled.span`
  align-self: center;
  float: bottom;
  position: absolute;
  clear: both;
  bottom: 0;
  font-size: 0.9rem;
  margin-bottom: 3px;
  margin-left: 110px;
  border: none;
  color: #218838;
  padding-bottom: 5px;
`;
