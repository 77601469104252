import styled from 'styled-components';

export const Header = styled.div`
  justify-content: center;
  font-size: 18px;
  padding-top: 10px;
  height: 25px;
  text-align: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  font-size: 25px;
  height: 75px;
  margin-left: 5px;
  text-align: right;
  align-items: center;
  clear: both;
`;

export const Icon = styled.div`
  font-size: 25px;
  height: 30px;
  width: 30%;
  margin-left: -10px;
  text-align: center;
  align-self: center;
`;

export const Value = styled.div`
  font-size: 25px;
  height: 30px;
  width: 60%;
  right: 0;
`;

export const Bloco = styled.div.attrs((props) => ({
  color: props.color,
}))`
  margin: 20px auto;
  width: 30%;
  min-width: 200px;
  height: 100px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  margin-left: 20px auto;
  background: white;
  border-radius: 8px;
  color: ${(props) => props.color};
  border: solid 2px ${(props) => props.color};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  text-align: center;
  transition-duration: 0.2s;
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);

  &:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
`;

export const IconTakeat = styled.span`
  float: left;
  margin-left: 25px;
  margin-top: -3px;

  img {
    width: 25px;
    heigth: 50px;
  }
`;

export const Rate = styled.span`
  padding-left: 2px;
  padding-bottom: 6px;
  font-size: 23px;
`;
