import React, { useState, useRef } from 'react';
import { AiOutlinePrinter, AiOutlineEye } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import { Container, Modal, ModalBody, Button, ModalFooter } from 'reactstrap';
import { TiDelete } from 'react-icons/ti';
import PrintNewOrderComponent from '~/components/PrintNewOrderComponent';
import EyeModalOrders from '~/components/EyeModalOrders';

import {
  Pedido,
  OrderNumber,
  Time,
  ContentBody,
  PhoneNumber,
  Actions,
  DeleteAndEditActions,
  AcceptButton,
  SeeButton,
  TableNumber,
  DeliveryType,
  BuyerName,
  DataFormated,
} from '../styles';
import { CancelButton, DisplayNoneImprimir } from './styles';

import formatTime from '~/services/formatTime';
import formatDate from '~/services/formatDate';
// import formatPhone from '~/services/formatPhoneString';

import { useAuth } from '~/context/AuthContext';

export default function OrderNew({ item, handlePrintItem, handleCancelItem }) {
  const { user } = useAuth();
  const componentRef = useRef(null);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);

  // const userName = formatPhone(item.buyer.phone);
  const userName = item.buyer.phone;
  let paymentStatus = '';

  if (
    item.payment_status === 'paid' ||
    item.payment_status === 'completed' ||
    item.payment_status === 'succeeded'
  ) {
    paymentStatus = 'Pago';
  } else if (item.payment_status === 'canceled') {
    paymentStatus = 'Cancelado';
  } else if (item.payment_status === 'expired') {
    paymentStatus = 'Expirado';
  } else if (item.payment_status === 'waiting_payment' || 'pending') {
    paymentStatus = 'Pagamento Pendente';
  }

  // const getBuyer = useCallback(async () => {
  //   try {
  //     const response = await api.get(
  //       `food-courts/reports/restaurants/2/buyers`
  //     );
  //     console.log(response.data);
  //   } catch (err) {
  //     console.log('error');
  //   }
  // }, [item.id]);
  //
  // useEffect(() => {
  //   getBuyer();
  // }, [getBuyer]);

  return (
    <Pedido>
      <ContentBody>
        <OrderNumber>#{item.attendance_password.password_number}</OrderNumber>
        <TableNumber>
          <strong>{paymentStatus}</strong>
        </TableNumber>
        <CancelButton onClick={toggleDelete}>
          <TiDelete color="red" />
        </CancelButton>
        <Time>{formatTime(item.start_time)}</Time>
        <DataFormated>{formatDate(item.start_time)}</DataFormated>
        <BuyerName>{item.buyer.name}</BuyerName>
        <PhoneNumber>{userName}</PhoneNumber>
        <DeliveryType>
          {item.delivery_type === 'delivery' ? 'Delivery' : 'Retirar no balcão'}
        </DeliveryType>
        <Actions>
          <DeleteAndEditActions>
            <ReactToPrint
              trigger={() => (
                <AcceptButton>
                  <div>
                    <AiOutlinePrinter size={17} style={{ marginRight: 3 }} />
                    Aceitar
                  </div>
                </AcceptButton>
              )}
              content={() => componentRef.current}
              onAfterPrint={() => handlePrintItem(item.id, userName, item)}
            />
            <SeeButton onClick={toggle}>
              <div>
                <AiOutlineEye size={17} style={{ marginRight: 3 }} />
                Ver
              </div>
            </SeeButton>
          </DeleteAndEditActions>
        </Actions>
      </ContentBody>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeModalOrders item={item} />
          </Container>
        </ModalBody>
      </Modal>

      <Modal isOpen={modalDelete} toggle={toggleDelete}>
        <ModalBody>
          <Container>
            <h3>Tem certeza que deseja recusar esse pedido?</h3>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => handleCancelItem(item.id)}>
            Recusar o Pedido
          </Button>
        </ModalFooter>
      </Modal>

      <DisplayNoneImprimir>
        <PrintNewOrderComponent
          restaurant={user}
          item={item}
          ref={componentRef}
          handlePrintItem={handlePrintItem}
          paymentStatus={paymentStatus}
        />
      </DisplayNoneImprimir>
    </Pedido>
  );
}
