import React from 'react';

import { FaRegSmileBeam } from 'react-icons/fa';

import {
  Header,
  Bloco,
  SmileIcon,
  Info,
  Total,
  CardDescription,
  ReviewsTable,
} from './styles';

export default function GoodCard({ ratings }) {
  let reviewsCount = 0;
  let goodReviewsCount = 0;
  let percentGoodReview = 0;

  if (ratings.good_reviews) {
    ratings.good_reviews.forEach((rating) => {
      reviewsCount += rating.count;
      goodReviewsCount += rating.count;
    });
  }

  if (ratings.bad_reviews) {
    ratings.bad_reviews.forEach((rating) => {
      reviewsCount += rating.count;
    });
  }

  if (reviewsCount > 0) {
    percentGoodReview = ((goodReviewsCount * 100) / reviewsCount).toFixed(0);
  }

  return (
    <Bloco>
      <Header>
        <SmileIcon>
          <FaRegSmileBeam />
        </SmileIcon>
        <Total>
          <span>Avaliações Positivas</span>
        </Total>
        <Info>
          <span>{goodReviewsCount}</span>
          <span>{percentGoodReview}%</span>
        </Info>
      </Header>

      <CardDescription>
        São aquelas que receberam 4 ou 5 estrelas de seus clientes! Ao terem que
        selecionar os aspectos em que seu estabelecimento se destaca, esses
        foram os resultados:{' '}
      </CardDescription>

      <ReviewsTable borderless>
        <tbody>
          {ratings.good_reviews ? (
            ratings.good_reviews.map((rating) => (
              <tr>
                <td>{rating.adjective}</td>
                <td>{rating.count}</td>
              </tr>
            ))
          ) : (
            <></>
          )}
        </tbody>
      </ReviewsTable>
    </Bloco>
  );
}
