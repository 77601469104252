import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import {v4} from 'uuid';
import * as Yup from 'yup';

import { MdDelete, MdCreate, MdInfo, MdAddCircle } from 'react-icons/md';

import { useAuth } from '~/context/AuthContext';
import getValidationErrors from '..//../../../utils/getValidationErrors';

import { Row, Col, FormGroup, Label, Input as InputStrap } from 'reactstrap';
import { Container, Card, Header, Content, Table } from './styles';

import api from '~/services/api';
import history from '~/services/history';
import apiIbge from '~/services/apiIbge';
import Input from '~/components/Form/Input';
import SelectInput from '~/components/Form/SelectInput';
import CheckboxInput from '~/components/Form/Checkbox';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function DeliveryConfig({toggle, setCount, getDeliveryDistances}) {
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState({});
  const [cities, setCities] = useState([]);
  const [, setSelectedCity] = useState({});
  const [neighborhoodLimited, setNeighborhoodLimited] = useState(true);
  const [restaurantDeliveryType, setRestaurantDeliveryType] = useState()
  const [deliveryDistance, setDeliveryDistance] = useState([])
  const formRef = useRef(null);
  const { user, getProfileData } = useAuth();

  const [distance, setDistance] = useState('')
  const [taxByDistance, setTaxByDistance] = useState('')
  const [timeByDistance, setTimeByDistance] = useState('')

  
  const handleSetDeliveryDistance = () => {
    if (distance && taxByDistance && timeByDistance){
     
     setDeliveryDistance([...deliveryDistance, {distance: distance, price: taxByDistance, time: timeByDistance}])
     
      setDistance('')
      setTaxByDistance('')
      setTimeByDistance('')
    }
  }

  const handleDeleteDeliveryDistance = (idx) => {
    const elementFiltered = deliveryDistance[idx]
    const distanceFiltered = deliveryDistance.filter(distance => distance !== elementFiltered)

    setDeliveryDistance(distanceFiltered)
    
  }

  const handleDeliveryTypeChange = (e) => {
    setRestaurantDeliveryType(e.target.value)

    console.log(e.target.value)
  }

  const handleNavigate = () => {
    getProfileData();
    toggle();
  }

  // const initialData = {
  //   maximum_delivery_distance: user.maximum_delivery_distance,
  //   delivery_tax_by_km: user.delivery_tax_by_km,
  //   neighborhood: user.address.neighborhood,
  //   number: user.address.number,
  //   zip_code: user.address.zip_code,
  //   street: user.address.street,
  //   state: user.address.state,
  //   city: user.address.city,
    
  // };

  const getStatesData = useCallback(async () => {
    try {
      const response = await apiIbge.get('/localidades/estados');
      const parsedStates = response.data.map((item) => {
        return {
          label: item.nome,
          value: item.sigla,
        };
      });
      setStates(parsedStates);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  const getCitiesData = useCallback(async () => {
    try {
      console.log(selectedState);
      const response = await apiIbge.get(
        `/localidades/estados/${selectedState.value}/municipios`
      );

      console.log(response.data)
      const parsedCities = response.data.map((item) => {
        return {
          label: item.nome,
          value: item.nome,
        };
      });
      setCities(parsedCities);
      console.log(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [selectedState]);

  const handleAddDistances = async () => {
    await api.put('/restaurants/delivery-info', {
       delivery_distances: deliveryDistance
    })
  }

  const handleSubmit = useCallback(async (data) => {
    try {
      const createData = {
        country: 'BR',
        state: data.state,
        city: data.city,
        delivery_tax_price: data.delivery_tax,
        is_neighborhood_limited: data.is_neighborhood_limited,
      };
      if (data.is_neighborhood_limited) {
        createData.neighborhood = data.neighborhood;
      }
      await api.post('/restaurants/delivery-addresses', createData);

      await api.put('/restaurants/delivery-info', {
        is_delivery_by_distance: false
      })
      toast.success('Endereço salvo com sucesso!');
      handleNavigate()
    } catch (err) {
      toast.error('Erro ao salvar endereço');
    }
  }, []);

  const handleSubmitDistanceDelivery = useCallback(async (data) => {
    try {
     
      if (formRef.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        street: Yup.string()
          .required('Campo obrigatório'),
        number: Yup.string()
          .matches(/^[0-9]+$/, 'Somente números, sem espaços em branco')
          .required('Número obrigatório'),
        zip_code: Yup.string()
          .matches(/^[0-9]+$/, 'Somente números, sem espaços em branco')
          .required('CEP obrigatório'),
        neighborhood: Yup.string()
          .required('Campo obrigatório'),
        city: Yup.string()
          .required('Cidade é obrigatória'),
        state: Yup.string()
          .required('Estado é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const createData = {
        country: 'BR',
        state: data.state,
        city: data.city,
        neighborhood: data.neighborhood,
        street: data.street,
        number: data.number,
        zip_code: data.zip_code,      
       
      };
          
      await api.post('/restaurants/address', createData);
      await api.put('/restaurants/delivery-info', {
        is_delivery_by_distance: true,
        delivery_distances: deliveryDistance       
      })

      toast.success('Endereço salvo com sucesso!');

      const aleatoryId = v4();
      setCount(aleatoryId)
      getDeliveryDistances()

      handleNavigate();
    } catch (err) {
      toast.error('Erro ao salvar endereço');
      const errors = getValidationErrors(err);
      if (formRef.current) {
        formRef.current.setErrors(errors);
      }
    }
  }, [deliveryDistance]);

  useEffect(() => {
    try {
      getStatesData();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getStatesData]);

  useEffect(() => {
    try {
      getCitiesData();
    } catch (err) {
      toast.error('Erro ao carregar cidades');
    }
  }, [selectedState, getCitiesData]);

  return (
    <Container>
      
      <Header>
        <div>
          
          <h2>
            Delivery 
            <MdInfo color="orange" size={24} title="Aqui você encontra as opções disponíveis de delivery para seu restaurante. 
            Poderá escolher entregar por distância e configurar um limite até onde seu delivery irá abranger, ou poderá escolher entregar por endereço, 
            cadastrando os endereços que forem melhor para seu estabelecimento atender."/>  
          </h2>
             
          <p>Configure a modalidade de delivery do seu estabelecimento!</p>
          <FormGroup>
          <Label for="exampleSelectMulti">Como você gostaria de limitar sua entrega?</Label>
          <InputStrap type="select" name="select" id="exampleSelectMulti" onChange={(e)=>handleDeliveryTypeChange(e)}>
          <option>Selecione aqui ...</option>
            <option value="distance">Limitar por distância (KM)</option>
            <option value="address">Limitar por endereço</option>          
          </InputStrap>
          </FormGroup>

        </div>
        {/* <BackButton onClick={() => history.goBack()}>Voltar</BackButton> */}
      </Header>

      <Card>
        {restaurantDeliveryType === 'address' && (
          <Form onSubmit={handleSubmit}>
          <Content>
            <Row>
              <h4
                style={{ paddingLeft: 10, paddingBottom: 15, paddingTop: 30 }}
              >
                Endereços
              </h4>
              <Col md="12">
                <p style={{ textAlign: 'left' }}>
                  Cadastre os endereços e as taxas de entrega do seu
                  estabelecimento aqui!
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <Row>
                  <Col sm="12" lg="12">
                    <SelectInput
                      name="state"
                      label="Selecione o estado"
                      options={states}
                      onChange={(e) => {
                        setSelectedState({ value: e.value, label: e.label });
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" lg="12">
                    <SelectInput
                      name="city"
                      label="Selecione a cidade"
                      options={cities}
                      onChange={(e) =>
                        setSelectedCity({ value: e.value, label: e.label })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" lg="12">
                    <CheckboxInput
                      label="Limitar por bairro"
                      name="is_neighborhood_limited"
                      value={neighborhoodLimited}
                      defaultChecked
                      onChange={() => {
                        setNeighborhoodLimited(!neighborhoodLimited);
                      }}
                    />
                  </Col>
                </Row>
                {neighborhoodLimited ? (
                  <>
                    <Row>
                      <Col sm="12" lg="12">
                        <Input
                          label="Nome do Bairro"
                          name="neighborhood"
                          type="text"
                          placeholder="Nome do bairro"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" lg="12">
                        <h4>Observações</h4>
                        <p>
                          O usuário, no aplicativo, poderá selecionar somente na lista de
                          bairros que estiver cadastrada!
                        </p>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
                <Row>
                  <Col sm="12" lg="12">
                    <Input
                      label="Taxa de entrega"
                      name="delivery_tax"
                      type="number"
                      placeholder="R$x.xx"
                      step="0.01"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <SaveButton type="submit">Salvar</SaveButton>
          </Content>
        </Form>
        )}

        {restaurantDeliveryType === 'distance' && (
             
          <Form onSubmit={handleSubmitDistanceDelivery} ref={formRef}>
            <Content>
            <Row>
             <h4
               style={{ paddingLeft: 10, paddingBottom: 15, paddingTop: 30 }}
             >
               Entrega por distância
             </h4>
             <Col md="12">
               <p style={{ textAlign: 'left' }}>
                 Nesse método de entrega, precisamos que cadastre o endereço do estabelecimento, a distância máxima que seu estabelecimento aceitará pedidos e as taxas de entrega por quilômetros!
               </p>
             </Col>
           </Row>
           <Row>
              <Col sm="12" lg="12">
                <SelectInput
                  name="state"
                  label="Selecione o estado"
                  options={states}
                  onChange={(e) => {
                    setSelectedState({ value: e.value, label: e.label });
                  }}
                 
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="12">
                <SelectInput
                  name="city"
                  label="Selecione a cidade"
                  options={cities}
                  onChange={(e) =>
                    setSelectedCity({ value: e.value, label: e.label })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="8">
                <Input
                  label="Bairro"
                  name="neighborhood"
                  type="text"
                  placeholder="Bairro..."
                  
                />
              </Col>

              <Col sm="12" lg="4">
                <Input
                  label="CEP"
                  name="zip_code"
                  type="text"
                  placeholder="CEP..."
                />
              </Col>
            </Row>
            
            <Row>
              <Col sm="4" lg="8">
                <Input
                  label="Rua"
                  name="street"
                  type="text"
                  placeholder="Rua..."
                />
              </Col>
              <Col sm="2" lg="4">
                <Input
                  label="Número"
                  name="number"
                  type="text"
                  placeholder="Número..."
                />
              </Col>
            </Row>
{/* 
            <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Distância máxima de entrega (em km)"
                  name="maximum_delivery_distance"
                  type="text"
                  placeholder="Informe a distância"
                />
              </Col>
            </Row> */}
            <Row>
              <Table>
                <thead>
                  <tr>
                    <th>
                      Alcance (km)
                    </th>
                    <th>
                      Taxa (R$)
                    </th>
                    <th>
                      Tempo (mins.)
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>  
                 <tr style={{color: "green"}}>
                   <td>
                    Ex: Até 5km
                   </td>
                   <td>
                     R$ 10
                   </td>
                   <td>
                     40 mins
                   </td>
                 </tr>
                 
                  {deliveryDistance && deliveryDistance.length > 0 && (
                    deliveryDistance.map((item, id) => (
                      <tr>
                        <td>{`Até ${item.distance} km`}</td>
                        <td>{`R$ ${item.price} `}</td>
                        <td>{`${item.time} mins.`}</td>
                        <td><MdDelete size={20} color="#ff0000" title="Deletar alcance" onClick={()=>{handleDeleteDeliveryDistance(id)}}/></td>
                      </tr>               
                      
                    ))
                  )} 
                         
                  <tr>                
                    <td>   
                                        
                      <Input
                        name="distance"
                        type="text"
                        placeholder="km..."
                        value={distance}
                        onChange={(e)=>{setDistance(e.target.value)}}
                        />                     
                    </td>
                    <td>
                      <Input
                      name="tax_by_distance"
                      type="text"
                      placeholder="R$..."
                      value={taxByDistance}
                      onChange={(e)=>{setTaxByDistance(e.target.value)}}
                      />
                    </td>
                    <td>
                      <Input
                      name="time_by_distance"
                      type="text"
                      placeholder="Mins..."
                      value={timeByDistance}
                      onChange={(e)=>{setTimeByDistance(e.target.value)}}
                      />
                    </td> 
                    <td><MdAddCircle onClick={handleSetDeliveryDistance} size={24} title="Adicionar alcance" color="green"/></td>                   
                  </tr>
                </tbody>
              </Table>
             
                
             
            </Row>

              <SaveButton type="submit">Salvar</SaveButton>
            </Content>
          </Form>
        )}
      </Card>
    </Container>
  );
}

export default DeliveryConfig;
