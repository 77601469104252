import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import InputMask from "react-input-mask";
// import OpeningTimeSwitcher from '~/components/OpeningTimeSwitcher'

import formatTime from '~/services/formatTime'

import {v4} from 'uuid'

import SaveButton from '~/components/Buttons/SaveButton'

import { Container, Header, MethodsTable, ButtonArea } from './styles';

import api from '~/services/api';
import { useAuth } from '~/context/AuthContext';

function OpeningHours() {
  const { user } = useAuth();  

  const [openingHoursData, setOpeningHourData] = useState()
  const [opened, setIsOpened] = useState()
  
  const [time, setTime] = useState('')
  const startsWithTwo = time[0] === '2'

  const mask = [
    /[0-2]/,
    startsWithTwo ? /[0-3]/ : /[0-9]/,
    ':',
    /[0-5]/,
    /[0-9]/
  ]

  function setInitialHour(e){
    const objectFind = openingHoursData.find(item => item.id === Number(e.target.id))
      objectFind.open_time = `2021-01-09T${e.target.value}:00-03:00`    
  }

  function setFinalHour(e){    
    const objectFind = openingHoursData.find(item => item.id === Number(e.target.id))
    objectFind.close_time = `2021-01-09T${e.target.value}:00-03:00` 
  }

 
  function setIsOpening(value, event, id){
    const objectFind = openingHoursData.find(item => item.id === Number(id))

    objectFind.is_opening = value

    const aleatoryId = v4()

    setIsOpened(aleatoryId)

  }

  async function getSchedules(){
    const response = await api.get('restaurants/schedules')

    setOpeningHourData(response.data)
  }

  async function handleUpdateSchedules(){
    var x = new Date()
    var currentTime = x.getTimezoneOffset()

    try {
      await api.put('restaurants/schedules', {
        timezone_offset: currentTime,
        schedules: openingHoursData
      })
    
      toast.success('Horário atualizado com sucesso')

      getSchedules()
    } catch(e){
      toast.error("Erro ao atualizar horário")
      console.log(e.message)
    }
   
  }

  useEffect(()=>{
    try{
      getSchedules()
    } catch(e){
      console.log(e.message)
    }
  }, [])

  
  return (
    <Container>
      <Header>
        <div>
          <h2>Horário de funcionamento</h2>
          <p>Configure o horário de abertura e fechamento do seu estabelecimento.</p>
        </div>
      </Header>

      <MethodsTable borderless>
        <thead>
          <tr>
            <th>Dia</th>  
            <th>Aberto</th>
            <th>Início</th>
            <th>Fim</th>

          </tr>
        </thead>
        <tbody>
          {openingHoursData && openingHoursData.length > 0 && openingHoursData.map(
            item => (
              <tr key={item.id}>
              <td>{item.day}</td>
                <td>
                <Switch
                  id={item.id}
                  onChange={setIsOpening}
                  draggable={true}
                  checked={item.is_opening}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={20}
                  width={40}
                  handleDiameter={20}
                  activeBoxShadow="0 0 2px 3px #111"
                  boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                />
              </td> 
              <td>
                <InputMask mask="99:99" id={item.id} type="text" name="initialHour" maskPlaceholder={formatTime(item.open_time)} onChange={(e)=>{setInitialHour(e)}}/> hs. 
              </td>   
              <td>
                <InputMask mask="99:99" id={item.id} type="text" name="finalHour" maskPlaceholder={formatTime(item.close_time)} onChange={(e)=>{setFinalHour(e)}}/> hs.
              </td>       
            </tr>
            
          
            )
          )} 

            <tr>
              <td>
              <SaveButton onClick={handleUpdateSchedules} title="Salvar"/>
              </td>
            </tr>
     
        </tbody>

      </MethodsTable>

      
    </Container>
  );
}

export default OpeningHours;
