import React, { useRef } from 'react';
import { toast } from 'react-toastify';

import { Row, Col, Container } from 'reactstrap';
import { Wrapper, Header, Card, StyledForm } from './styles';

import history from '~/services/history';
import api from '~/services/api';

import Input from '~/components/Form/Input';
import CheckboxInput from '~/components/Form/Checkbox';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function StorePayment() {
  const formRef = useRef(null);

  async function handleSubmit(data) {
    try {
      await api.post('restaurants/payment-methods', {
        name: data.name,
        available: data.available,
      });

      toast.success('Forma de pagamento cadastrada com sucesso!');
    } catch (error) {
      // console.tron.log(error);
      toast.error(`Erro ao cadastrar forma de pagamento! ${error.message}`);
    }
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Cadastrar forma de pagamento</h2>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Container>
        <Row>
          <Col md="6 ml-auto mr-auto">
            <Card>
              <StyledForm ref={formRef} onSubmit={handleSubmit}>
                <Input
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Forma de pagamento"
                />
                <CheckboxInput
                  defaultChecked
                  label="Disponível"
                  name="available"
                />
                <SaveButton type="submit">Cadastrar</SaveButton>
              </StyledForm>
            </Card>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default StorePayment;
