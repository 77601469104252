export default {
  title: 'light',

  colors: {
    primary: '#FF008F',
    secondary: '#ff403d',
    tertiary: '#90C2E7',
    confirm: '#7fd1b9',
    info: '#eac435',
    white: '#fff',
    gray: '#97928F',
    lightGray: '#ddd',

    background: '#F0F0EF',
    text: '#333',
  },
};
