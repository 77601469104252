import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import { Modal, ModalBody } from 'reactstrap';

import { MdDelete, MdEdit, MdInfo } from 'react-icons/md';
import {
  Container,
  Header,
  ComplementsArea,
  Actions,
  Delete,
  CategoryItemHeader,
  CategoryItemHeaderTitle,
  CategoryItemBody, 
  CategoryName
} from './styles';

import RegisterButton from '~/components/Buttons/RegisterButton';
import Complements from '../Complements'
import CreateComplementCategory from '../ComplementsCategories/Create'
import EditComplementCategory from '../ComplementsCategories/Edit'
import CreateComplement from '../Complements/Create'

import api from '~/services/api';
import OutlineButton from '~/components/Buttons/OutlineButton';
import AssociateComplement from './AssociateComplement';
import DefaultButton from '~/components/Buttons/DefaultButton';

function ComplementCategories() {
  const [categories, setCategories] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalAssociateCategory, setModalAssociateCategory] = useState()
  const [modalCreateComplements, setModalCreateComplements] = useState()
  const [modalEditCategory, setModalEditCategory] = useState(false)
  const [modalNewCategory, setModalNewCategory] = useState()
  const [modalEditCategoryData, setModalEditCategoryData] = useState()
  const [modalNewComplement, setModalNewComplement] = useState()
  const [count, setCount] = useState('')
  const [isOpen, setIsOpen] = useState(true);

  const toggleComplements = () => setIsOpen(!isOpen);

  function toggle() {
    setModal(!modal);
  }

  function toggleNewCategory() {
    setModalNewCategory(!modalNewCategory);
   
  }

  function toggleNewComplement() {
    setModalNewComplement(!modalNewComplement);
  }

  function toggleModalEditCategory() {
    setModalEditCategory(!modalEditCategory);
  }

  function AssociateComplements(category){
    setModalAssociateCategory(category);
    toggle();
  }

  function createComplements(category){
    setModalCreateComplements(category);
    toggleNewComplement();
  }

  function handleChangeEditCategory(category){
    setModalEditCategoryData(category);
    toggleModalEditCategory();
  }

  const getCategories = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/product/complement-category'
      );

      setCategories(response.data);
    } catch (err) {
      toast.error('Falha ao carregar dados');
    }
  }, []);

  async function handleChangeComplementCategory(value, event, idx) {

    try{
      const id = Number(idx);
      await api.put(`/restaurants/product/complement-category/${id}`, {
        available: value
      });
     
      getCategories()
      toast.success('Categoria alterado com sucesso!')
    }catch(error){
      toast.error('Erro ao ativar/ desativar categoria')
    }
  }

  useEffect(() => {
    try {
      getCategories();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getCategories]);

  async function handleDelete(id) {
    try {
      if (
        window.confirm(
          'Tem certeza que deseja deletar esta categoria de Complemento?'
        )
      ) {
        await api.delete(`/restaurants/product/complement-category/${id}`);

        getCategories();

        toast.success('Categoria deletada com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar categoria');
    }
  }


  return (
    <Container>
   
      <Header>
        <div>
          <h2>Categoria de complementos</h2>
          <MdInfo size={24} color="orange" title="Caso seja sua primeira vez aqui, cadastre a categoria primeiro, e após isso, adicione o complemento desejado."/>
        </div>
       <RegisterButton title="Cadastrar categorias" onClick={toggleNewCategory}/>
       
      </Header>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalBody>
          <AssociateComplement category={modalAssociateCategory} toggle={toggle} getCategories={getCategories} setCount={setCount}/>        
        </ModalBody>
      </Modal>

      <Modal isOpen={modalNewCategory} toggle={toggleNewCategory} size="lg">
        <ModalBody>
          <CreateComplementCategory getCategories={getCategories} toggle={toggleNewCategory}/>         
      </ModalBody>
      </Modal>

      <Modal isOpen={modalNewComplement} toggle={toggleNewComplement} size="lg">
        <ModalBody>
          <CreateComplement category={modalCreateComplements} getCategories={getCategories} toggle={toggleNewComplement} setCount={setCount}/>         
        </ModalBody>
      </Modal>

      <Modal isOpen={modalEditCategory} toggle={toggleModalEditCategory} size="lg">
        <ModalBody>
          <EditComplementCategory category={modalEditCategoryData} getCategories={getCategories} toggle={toggleModalEditCategory} setCount={setCount}/>         
        </ModalBody>
      </Modal>

      <ComplementsArea>
       {categories.length ? (
         categories.map((category) => (
          <React.Fragment key={category.id}> 
          <CategoryName>{category.name}</CategoryName>
          <CategoryItemHeader>
            <div>
              <CategoryItemHeaderTitle>Pergunta</CategoryItemHeaderTitle>
              <p>{category.question}</p>
            </div>

            <div>
              <CategoryItemHeaderTitle>Limite</CategoryItemHeaderTitle>
              <p>{category.limit}</p>
            </div>

            <div>
              <CategoryItemHeaderTitle>Disponilidade</CategoryItemHeaderTitle>
              <p>      
                <Switch
                id={`${category.id}`}
                onChange={handleChangeComplementCategory}
                draggable={true}
                checked={category.available}
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={40}
                handleDiameter={20}
                activeBoxShadow="0 0 2px 3px #111"
                boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
              />
              </p>
            </div>

            <div>
              <CategoryItemHeaderTitle>Opcional?</CategoryItemHeaderTitle>
              <p>{category.optional ? <p>Sim</p> : <p>Não</p>}</p>
            </div>
             
            <div>
              <CategoryItemHeaderTitle>Grátis?</CategoryItemHeaderTitle>
              <p>{category.free_complements ? <p>Sim</p> : <p>Não</p>}</p>
            </div>
             
           <div>
              <CategoryItemHeaderTitle>Ações</CategoryItemHeaderTitle>
              <p>
                <Actions>              
                  
                  <MdEdit size={20} title="Editar Categoria" color="blue" onClick={()=>{handleChangeEditCategory(category)}}/>
              
                  <Delete onClick={() => handleDelete(category.id)}>
                    <MdDelete size={20} title="Deletar categoria"/>
                  </Delete>                
                </Actions>
              </p>
           </div>          
            
       
          </CategoryItemHeader>

            <OutlineButton text="Criar complemento" title="Cria complementos dentro desta categoria" onClick={()=>{createComplements(category)}}/>
       
            <DefaultButton message="Adicionar/ Remover complemento já existentes" title="Adiciona ou remove complementos nesta categoria" onClick={()=>{AssociateComplements(category)}}/>
            
        
              <Complements category={category} count={count} setCount={setCount} getCategories={getCategories}/>

                     
          </React.Fragment>
        ))
       ) : (
         <p>Não existem items cadastrados.</p>
       )}
          
        
      </ComplementsArea>
    </Container>
  );
}

export default ComplementCategories;
