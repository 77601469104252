import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Row, Col, Container } from 'reactstrap';
import { Wrapper, Header, Card, StyledForm } from './styles';

import api from '~/services/api';

import Input from '~/components/Form/Input';
import Checkbox from '~/components/Form/Checkbox';
import SaveButton from '~/components/Buttons/SaveButton';

function Store({toggle, getCategories}) {
  const formRef = useRef(null);

  async function handleSubmit(data) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
      });

      await schema.validate(
        { name: data.name },
        {
          abortEarly: false,
        }
      );

      await api.post('/restaurants/product-category', {
        name: data.name,
        preparation_time: data.time,
        available: data.available,
      });

      getCategories();

      toast.success('Categoria cadastrada com sucesso!');
      toggle()
     
    } catch (error) {
      // console.tron.log(error);
      toast.error(`Erro ao cadastrar categoria! ${error.message}`);
    }
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Cadastrar categoria de produtos</h2>
        </div>
      </Header>
      <Container>
        <Row>
          <Col md="6 ml-auto mr-auto">
            <Card>
              <StyledForm ref={formRef} onSubmit={handleSubmit}>
                <Input
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Nome da categoria"
                />
                <Input
                  label="Tempo de preparo"
                  name="time"
                  type="text"
                  placeholder="Tempo (em minutos)"
                />
                <Checkbox
                  defaultChecked
                  label="Categoria Disponível"
                  name="available"
                />
                <SaveButton type="submit">Cadastrar</SaveButton>
              </StyledForm>
            </Card>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Store;
