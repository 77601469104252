import React, { useState, useRef } from 'react';

import { AiOutlinePrinter, AiOutlineEye } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import { Container, Modal, ModalBody } from 'reactstrap';
import PrintBillsIndividual from '~/components/Prints/PrintBillsIndividual';
import EyeBillsIndividual from '~/components/Tables/CloseOrder/Eye/EyeBillsIndividual';

import {
  Pedido,
  OrderNumber,
  Time,
  ContentBody,
  Eye,
  TableNumber,
  PhoneNumber,
} from '../styles';
import { Print, DisplayNoneImprimir } from './styles';

import formatTime from '~/services/formatTime';
import formatPhone from '~/services/formatPhoneString';
import { useAuth } from '~/context/AuthContext';

export default function CloseIndividual({ item, handlePrintItem }) {
  const { user } = useAuth();
  const componentRef = useRef(null);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const userName = item.bill.buyer
    ? formatPhone(item.bill.buyer.phone)
    : item.bill.waiter.name;

  return (
    <Pedido>
      <ContentBody>
        <OrderNumber>#{item.bill.id}</OrderNumber>
        <TableNumber>{userName}</TableNumber>
        <Eye onClick={toggle}>
          <AiOutlineEye color="blue" size={20} />
        </Eye>
        <ReactToPrint
          trigger={() => (
            <Print>
              <AiOutlinePrinter color="black" size={20} />
            </Print>
          )}
          content={() => componentRef.current}
          onAfterPrint={() =>
            handlePrintItem({
              session_key: item.session_key,
              bill_id: item.bill.id,
            })
          }
        />
        <Time>{formatTime(item.bill.updatedAt)}</Time>
        <PhoneNumber>Mesa {item.table?.table_number}</PhoneNumber>
      </ContentBody>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeBillsIndividual item={item} />
          </Container>
        </ModalBody>
      </Modal>

      <DisplayNoneImprimir>
        <PrintBillsIndividual
          item={item}
          restaurant={user}
          ref={componentRef}
        />
      </DisplayNoneImprimir>
    </Pedido>
  );
}
