import styled from 'styled-components';
import { Table } from 'reactstrap';

export const Header = styled.div`
  justify-content: center;
  color: white;
  font-size: 20px;
  width: 100%;
  height: 60px;
  text-align: center;
  clear: both;

  span {
    color: black;
    padding-top: 10px;
  }
`;

export const Bloco = styled.div`
  margin: 20px auto;
  width: 45%;
  min-width: 200px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  margin-left: 20px auto;
  background: #ff875c;
  text-align: center;

  border-radius: 8px;
  border: solid 2px #ff875c;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
export const SadIcon = styled.div`
  width: 40px;
  height: 40px;
  margin: 10px;
  float: left;
  color: #00420f;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  display: flex;
`;

export const Info = styled.div`
  height: 40px;
  width: 30px;
  float: right;
  position: relative;
  margin-right: 20px;
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  text-align: right;
  align-itens: center;

  span {
    font-size: 18px;
    padding-top: 0px;
    margin-top: 0px;
    display: block;
    margin-top: -15px;
    color: #00420f;
  }
  span: last-child {
    border-top: 0.5px solid #00420f;
    width: 30px;
    font-size: 18px;
    padding-top: 0px;
    margin-top: 0px;
    display: block;
    color: #00420f;
  }
`;

export const Eye = styled.button`
  height: 30px;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  bottom: 0;
  margin-bottom: 10px;
  left: 20%;
  border: none;
  background: none;
`;

export const Total = styled.div`
  height: 20px;
  width: 160px;
  align-self: center;
  position: absolute;
  left: 44%;
  margin-left: -50px;
  margin-top: 10px;

  span {
    font-size: 16px;
    display: block;
    margin-top: 0px;
    text-align: center;
    align-items: center;
    color: #00420f;
  }
`;

export const CloseBill = styled.button`
  bottom: 0;
  right: 0;
  position: absolute;
  margin-bottom: 10px;
  right: 20%;
  text-align: center;
  justify-content: center;
  border: none;
  background: none;
`;

export const NoClients = styled.div`
  bottom: 0;
  right: 0;
  position: absolute;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  justify-content: center;
  background: none;
  color: #8c2500;

  span {
    font-size: 16px;
  }
`;

export const CardDescription = styled.div`
  width: 100%;
  padding: 5px 10px;
  font-size: 14px;
  text-align: center;
  color: #00420f;
`;

export const ReviewsTable = styled(Table)`
  width: 80%;
  align-items: center;
  border-collapse: separate;
  border-spacing: 0 20px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  padding: 0 20px;

  tbody {
    td {
      padding-left: 50px;
      text-align: left;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;
