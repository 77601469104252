import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    display: none;
    border: none;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.secondary};

    margin: 5px 10px;

    /* svg {
      font-size: 1.5rem;
      margin: 2rem;
      transition: 200s ease;
      color: #fff;
    }
    &:hover {
      background-color: ${(props) =>
        darken(0.2, `${props.theme.colors.secondary}`)};
    } */

    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 13rem;
      transition: transform 0.3s ease-in-out;
      transform: ${(props) =>
        props.collapsed ? 'translateX(-12rem)' : 'translateX(0)'};
    }
  }
`;

export const Logo = styled.div.attrs((props) => ({
  darkenBg: darken(0.1, props.theme.colors.primary),
  darkenRed: darken(0.2, props.theme.colors.secondary),
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 55px;

  img {
    width: auto;
    height: 30px;
    object-fit: contain;
  }

  button {
    margin: 0;
    display: block;
    background: none;
    color: ${(props) => props.theme.colors.secondary};
    transition: color 0.2s;
    transform: none;

    &:hover {
      color: ${(props) => props.darkenRed};
      background: none;
    }
  }
  padding: 0 10px;
`;
