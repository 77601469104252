import styled from 'styled-components';

export const Container = styled.div`
  margin: auto;
  width: 90%;
  background-color: #fff;
  border-radius: 7px;
  padding: 16px;
  margin-top: 26px;

  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
`;

export const ButttonArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 50px;
  margin-bottom: 20px;
`;

export const ReorderContainer = styled.div`
  width: 350px;
  margin-bottom: 16px;

  button {
    font-weight: bold;
    font-size: 14px;
    border-radius: 7px;
    border: none;
    color: #ff403d;
    padding: 10px 15px;
    background-color: transparent;
    text-decoration: underline;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const CategoryHeader = styled.div`
  margin: 20px;

  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 14px;
  padding-left: 14px;
  padding-bottom: 24px;

  border-bottom: 1px solid #33333310;

  div {
    display: flex;
    align-items: center;

    svg {
      cursor: pointer;
    }

    p {
      margin-left: 16px;
    }
  }

  p {
    font-size: 20px;
    margin-top: 14px;
  }
`;
