import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.background};
`;

export const Content = styled.div`
  height: 100vh;
  width: 100vw;
`;
