import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { toast } from 'react-toastify';
import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  startOfWeek,
  startOfMonth,
  addHours,
} from 'date-fns';
import { Form } from '@unform/web';
import { MdArrowDropDown } from 'react-icons/md';

import { Row, Col, Collapse } from 'reactstrap';
import { Input } from '@material-ui/core';
// import { TextField } from '@material-ui/core';
import { AiOutlinePrinter } from 'react-icons/ai';
import {
  Card,
  Container,
  Header,
  ReportHeader,
  DateDiv,
  DateSelectorDiv,
  HeaderCards,
  HeaderChart,
  Content,
  ReportHeaderChartDiv,
  HeaderLegend,
  HeaderChartFooter,
  Legends,
  CategoryLegend,
  SubmitDate,
  Print,
  PrintDiv,
  SelectDateButton,
  StyledLink,
} from './styles';
import history from '~/services/history';
import ReportCard from '~/components/Reports/ReportCard';
import ReportChartPie from '~/components/Reports/ReportChartPie';
import ReportCategory from '~/components/Reports/ReportCategory';
import ReportCategoryCanceled from '~/components/Reports/ReportCategoryCanceled';
import BackButton from '~/components/Buttons/BackButton';

import api from '~/services/api';

function ReportsProducts() {
  const formRef = useRef(null);

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const [inicialFormat, setInicialFormat] = useState(
    format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm')
  );
  const [finalFormat, setFinalFormat] = useState(
    format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm')
  );
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  // const [inicialDateSearch, setInicialDateSearch] = useState(
  //   format(addHours(new Date(inicialDate), 3), "yyyy-MM-dd'T'HH:mm:ss")
  // );
  // const [finalDateSearch, setFinalDateSearch] = useState(
  //   format(addHours(new Date(finalDate), 3), "yyyy-MM-dd'T'HH:mm:ss")
  // );

  // useEffect(() => {
  //   setInicialDateSearch(
  //     format(addHours(new Date(inicialDate), 6), "yyyy-MM-dd'T'HH:mm:ss")
  //   );
  //   setFinalDateSearch(
  //     format(addHours(new Date(finalDate), 6), "yyyy-MM-dd'T'HH:mm:ss")
  //   );
  // }, [inicialDate, finalDate]);

  function setToday() {
    setInicialDate(
      format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 27)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    setInicialFormat(inicialFormat);
    setFinalFormat(finalFormat);
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek() {
    setInicialDate(
      format(
        startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
        "yyyy-MM-dd'T'HH:mm:ss"
      )
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    setInicialFormat(inicialFormat);
    setFinalFormat(finalFormat);
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth() {
    setInicialDate(
      format(startOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfMonth(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    setInicialFormat(inicialFormat);
    setFinalFormat(finalFormat);
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
    const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
    setInicialFormat(inicialFormat);
    setFinalFormat(finalFormat);
    const phrase = `De ${inicialFormat} até ${finalFormat}`;

    setHourPhrase(phrase);
  }

  const [categories, setCategories] = useState([]);
  // const [totais, setTotais] = useState([]);
  const [total_restaurant_amount, setTotal_restaurant_amount] = useState(0);
  const [total_restaurant_price, setTotal_restaurant_price] = useState(0);
  const [total_delivery_tax, setTotal_delivery_Tax] = useState(0);
  const [total_delivery_price, setTotal_delivery_price] = useState(0);
  const [total_withdrawal_price, setTotal_withdrawal_price] = useState(0);
  const [total_renevenues, setTotalRenevenues] = useState(0);
  const [total_restaurant_orders, setTotal_restaurant_orders] = useState(0);

  const [
    total_restaurant_amount_canceled,
    setTotal_restaurant_amount_canceled,
  ] = useState(0);

  const [
    total_restaurant_price_canceled,
    setTotal_restaurant_price_canceled,
  ] = useState(0);

  const [payments, setPayments] = useState([]);
  const [totalBox] = useState(0);
  const [buyers, setBuyers] = useState(0);
  const [categoriesCanceled, setCategoriesCanceled] = useState([]);

  const colors = [
    '#3366cc',
    '#dc3912',
    '#ff9900',
    '#109618',
    '#990099',
    '#0099c6',
    '#dd4477',
    '#66aa00',
    '#b82e2e',
    '#316395',
    '#3366cc',
    '#994499',
    '#22aa99',
    '#aaaa11',
    '#6633cc',
    '#e67300',
    '#8b0707',
    '#651067',
    '#329262',
    '#5574a6',
    '#3b3eac',
    '#b77322',
    '#16d620',
    '#b91383',
    '#f4359e',
    '#9c5935',
    '#a9c413',
    '#2a778d',
    '#668d1c',
    '#bea413',
    '#0c5922',
    '#743411',
  ];

  const getCategories = useCallback(async () => {
    try {
      const response = await api.get('restaurants/reports/orders', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      setCategories(response.data.categoriesReport);
      setCategoriesCanceled(response.data.canceledCategoriesReport);
      setTotal_restaurant_price(response.data.total_restaurant_price);
      setTotal_restaurant_amount(response.data.total_restaurant_amount);
      setTotal_restaurant_orders(response.data.total_restaurant_orders);
      setTotal_restaurant_price_canceled(
        response.data.total_restaurant_canceled_price.toFixed(2)
      );
      setTotal_restaurant_amount_canceled(
        response.data.total_restaurant_canceled_amount
      );

      console.log(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  function random_color() {
    const cores = colors;
    const cor = cores[0];
    cores.splice(0, 1);
    return cor;
  }

  const CategorysToLegend = categories.map(function (categorie) {
    return {
      name: categorie.name,
      color: random_color(),
    };
  });

  const cores = [];
  CategorysToLegend.map((category) => cores.push(category.color));

  const dataChartPrice = categories.map(function (categorie) {
    const cor = CategorysToLegend.filter(
      (category) => category.name === categorie.name
    );
    return {
      id: categorie.name,
      label: categorie.name,
      value: categorie.total_sold_orders_price,
      color: cor[0].color,
    };
  });

  const dataChartAmount = categories.map(function (categorie) {
    const cor = CategorysToLegend.filter(
      (category) => category.name === categorie.name
    );
    return {
      id: categorie.name,
      label: categorie.name,
      value: categorie.total_sold_orders_amount,
      color: cor[0].color,
    };
  });

  const getBuyers = useCallback(async () => {
    try {
      const response = await api.get('restaurants/reports/buyers', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      setBuyers(response.data.total_buy_count);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  const getPayments = useCallback(async () => {
    const response = await api.get('restaurants/reports/cashier', {
      params: {
        start_date: `${format(
          addHours(new Date(inicialDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
        end_date: `${format(
          addHours(new Date(finalDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
      },
    });

    const { totals } = response.data.general;
    const { delivery, withdrawal } = response.data;

    const payments = {
      picpay: totals.picpay_payment || '0.00',
      dinheiro: totals.flags.dinheiro || '0.00',
      mastercard_debito: totals.flags.mastercard_debito || '0.00',
      mastercard_credito: totals.flags.mastercard_credito || '0.00',
      visa_credito: totals.flags.visa_credito || '0.00',
      visa_debito: totals.flags.visa_debito || '0.00',
      elo_debito: totals.flags.elo_debito || '0.00',
      elo_credito: totals.flags.elo_credito || '0.00',
      hipercard_debito: totals.flags.hipercard_debito || '0.00',
      hipercard_credito: totals.flags.hipercard_credito || '0.00',
      diners_club_debito: totals.flags.diners_club_debito || '0.00',
      diners_club_credito: totals.flags.diners_club_credito || '0.00',
      american_express_debito: totals.flags.american_express_debito || '0.00',
      american_express_credito: totals.flags.american_express_credito || '0.00',
      pix: totals.flags.pix || '0.00',
      picpay_offline: totals.flags.picpay_offline || '0.00',
      other_payments: totals.other_payments || '0.00',
    };

    // informações dos pedidos do delivery
    const {
      flags,
      other_payments,
      paytime_payment,
      picpay_payment,
    } = delivery.totals;
    const totalFlagsDelivery = Object.values(flags).reduce(
      (t, n) => t + Number(n),
      0
    );
    const totalOtherPaymentsDelivery =
      Number(other_payments) + Number(paytime_payment) + Number(picpay_payment);

    const totalPaymentsDelivery =
      (totalFlagsDelivery || 0) + totalOtherPaymentsDelivery;

    // informações dos pedidos do balcao
    const {
      flags: withdrawalFlags,
      other_payments: other_payments_withdrawal,
      paytime_payment: paytime_payment_withdrawal,
      picpay_payment: picpay_payment_withdrawal,
    } = withdrawal.totals;
    const totalFlagsWithdrawal = Object.values(withdrawalFlags).reduce(
      (t, n) => t + Number(n),
      0
    );
    const totalOtherPaymentsWithdrawal =
      Number(other_payments_withdrawal) +
      Number(paytime_payment_withdrawal) +
      Number(picpay_payment_withdrawal);
    const totalPaymentsWithdrawal =
      (totalFlagsWithdrawal || 0) + totalOtherPaymentsWithdrawal;

    setPayments(payments);
    setTotal_delivery_Tax(totals.delivery_tax);
    setTotal_delivery_price(totalPaymentsDelivery);
    setTotal_withdrawal_price(totalPaymentsWithdrawal);

    setTotalRenevenues(totalPaymentsDelivery + totalPaymentsWithdrawal);
  }, [inicialDate, finalDate]);

  useEffect(() => {
    getCategories();
    getBuyers();
    getPayments();
  }, [getCategories, getBuyers, getPayments]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Container>
      <div>
        <Header>
          <div>
            <h2>Relatório de Produtos</h2>
            <p>Confira todas suas vendas detalhadamente!</p>
          </div>
          <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
        </Header>
        <Card>
          <Row>
            <Col md="4">
              <Row>
                <p style={{ paddingLeft: 10 }}>{hourPhrase}</p>
              </Row>
              <Row>
                <PrintDiv>
                  <StyledLink
                    to={{
                      pathname: '/reports/print',
                      state: {
                        categories,
                        inicialFormat,
                        finalFormat,
                        total_restaurant_amount,
                        total_restaurant_price,
                        payments,
                        totalBox,
                        buyers,
                        categoriesCanceled,
                        total_restaurant_price_canceled,
                        total_restaurant_amount_canceled,
                      },
                    }}
                  >
                    <Print>
                      {' '}
                      <AiOutlinePrinter color="white" size={20} />
                    </Print>
                  </StyledLink>
                </PrintDiv>
              </Row>
            </Col>
            <Col md="8">
              <div style={{ float: 'right' }}>
                <SelectDateButton
                  className="btn-round mr-auto"
                  onClick={setToday}
                  color="info"
                >
                  Hoje
                </SelectDateButton>
                <SelectDateButton
                  className="btn-round mr-auto"
                  onClick={setWeek}
                  color="info"
                >
                  Essa semana
                </SelectDateButton>
                <SelectDateButton
                  className="btn-round mr-auto"
                  onClick={setMonth}
                  color="info"
                >
                  Esse mês
                </SelectDateButton>
                <SelectDateButton
                  className="btn-round mr-auto"
                  onClick={toggle}
                  color="info"
                >
                  Selecionar Período
                  <MdArrowDropDown color="white" size={20} />
                </SelectDateButton>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div style={{ float: 'right', marginRight: '15px' }}>
                <Collapse isOpen={isOpen}>
                  <Form onSubmit={handleChangeDate} ref={formRef}>
                    <DateSelectorDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Inicial"
                          type="datetime-local"
                          name="initialDate"
                          onChange={(e) => setInitialPre(e.target.value)}
                          defaultValue={defaultInicial}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Final"
                          type="datetime-local"
                          name="finalDate"
                          onChange={(e) => setFinalPre(e.target.value)}
                          defaultValue={defaultFinal}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <div>
                        <SubmitDate type="submit">Filtrar</SubmitDate>
                      </div>
                    </DateSelectorDiv>
                  </Form>
                </Collapse>
              </div>
            </Col>
          </Row>

          <ReportHeader>
            <HeaderCards>
              <ReportCard
                name="Faturamento"
                total={total_renevenues ? total_renevenues.toFixed(2) : 0.0}
                type="cash"
                color="#28a745"
              />
              <ReportCard
                name="Vendas no delivery"
                total={total_delivery_price.toFixed(2)}
                type="cash"
                color="green"
              />
              <ReportCard
                name="Vendas no balcão"
                total={total_withdrawal_price.toFixed(2)}
                type="cash"
                color="green"
              />
            </HeaderCards>
            <HeaderCards>
              <ReportCard
                name="Taxa de entrega"
                total={total_delivery_tax}
                type="cash"
                color="orange"
              />
              <ReportCard
                name="Total de Pedidos"
                total={total_restaurant_orders}
                type="takeat"
                color="red"
              />
            </HeaderCards>
            <HeaderLegend>
              <Legends>
                {CategorysToLegend.map((categorie) => (
                  <CategoryLegend color={categorie.color} key={categorie.name}>
                    <div />
                    <span>{categorie.name}</span>
                  </CategoryLegend>
                ))}
              </Legends>
            </HeaderLegend>
            <ReportHeaderChartDiv>
              <HeaderChart>
                <ReportChartPie
                  radius={0}
                  data={dataChartPrice}
                  colors={cores}
                />
              </HeaderChart>
              <HeaderChart>
                <ReportChartPie
                  radius={0.5}
                  data={dataChartAmount}
                  colors={cores}
                />
              </HeaderChart>
            </ReportHeaderChartDiv>
            <HeaderChartFooter>
              <div>
                <span>Arrecadação (R$)</span>
              </div>
              <div>
                <span>Quantidade</span>
              </div>
            </HeaderChartFooter>
          </ReportHeader>
          <Content>
            {categories.map((categorie) => (
              <ReportCategory
                key={categorie.name}
                categorie={categorie}
                color={CategorysToLegend.filter(
                  (category) => category.name === categorie.name
                ).map((category) => category.color)}
                total_price={total_restaurant_price}
                total_amount={total_restaurant_amount}
              />
            ))}

            <ReportCategoryCanceled
              categorie={categoriesCanceled}
              color="black"
              total_price={total_restaurant_price_canceled}
              total_amount={total_restaurant_amount_canceled}
            />
          </Content>
        </Card>
      </div>
    </Container>
  );
}

export default ReportsProducts;
