import React, { useState } from 'react';

import { AiOutlineEye } from 'react-icons/ai';

import { Container, Modal, ModalBody } from 'reactstrap';

import {
  Pedido,
  OrderNumber,
  Time,
  ContentBody,
  PhoneNumber,
  Actions,
  DeleteAndEditActions,
  SeeButton,
  TableNumber,
  DeliveryType,
  BuyerName,
  DataFormated
} from '../styles';

import formatTime from '~/services/formatTime';
// import formatPhone from '~/services/formatPhoneString';

import EyeModalOrders from '~/components/EyeModalOrders';
import formatDate from '~/services/formatDate';

export default function OrderCanceled({ item }) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // const userName = formatPhone(item.buyer.phone);
  const userName = item.buyer.phone;
  let paymentStatus = '';

  if (
    item.payment_status === 'paid' ||
    item.payment_status === 'completed' ||
    item.payment_status === 'succeeded'
  ) {
    paymentStatus = 'Pago';
  } else if (item.payment_status === 'canceled') {
    paymentStatus = 'Cancelado';
  } else if (item.payment_status === 'expired') {
    paymentStatus = 'Expirado';
  } else if (item.payment_status === 'waiting_payment' || 'pending') {
    paymentStatus = 'Pagamento Pendente';
  }

  return (
    <Pedido>
      <ContentBody>
        <OrderNumber>#{item.attendance_password.password_number}</OrderNumber>
        <TableNumber>
          <strong>{paymentStatus}</strong>
        </TableNumber>

        <Time>{formatTime(item.start_time)}</Time>
        <DataFormated>{formatDate(item.start_time)}</DataFormated>
        <BuyerName>{item.buyer.name}</BuyerName>
        <PhoneNumber>{userName}</PhoneNumber>
        <DeliveryType>{item.delivery_type === 'delivery' ? 'Delivery' : 'Retirar no balcão'}</DeliveryType>
        <Actions>
          <DeleteAndEditActions>
            <SeeButton onClick={toggle}>
              <div>
                <AiOutlineEye size={17} style={{ marginRight: 3 }} />
                Ver
              </div>
            </SeeButton>
          </DeleteAndEditActions>
        </Actions>
      </ContentBody>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeModalOrders item={item} />
          </Container>
        </ModalBody>
      </Modal>
    </Pedido>
  );
}
