import React, { useState, useEffect, useCallback } from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

import OrderProductCategoryContext from './context';

import { Container, Header, ProductCategoriesContainer } from './styles';
import BackButton from '~/components/Buttons/BackButton';

import history from '~/services/history';

import api from '~/services/api';
import ProductItem from './ProductItem';

function OrderProductCategory() {
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [products, setProducts] = useState([]);
  const location = useLocation();

  const { category } = location.state;

  const getProducts = useCallback(async () => {
    const response = await api.get(
      `/restaurants/products-custom-order/${category.id}`
    );

    setProducts(response.data.products);
    const options = [];

    for (let i = 1; i <= response.data.count; i += 1) {
      options.push({
        label: i,
        value: i,
      });
    }
    setCategoriesOptions(options);
  }, [category.id]);

  useEffect(() => {
    try {
      getProducts();
    } catch (err) {
      // console.tron.log(err);
      toast.error('Erro ao carregar dados');
    }
  }, [getProducts]);

  async function move(from_product_id, to_product_id, from, to) {
    try {
      await api.put('/restaurants/products-custom-order', {
        from_product_id,
        to_product_id,
        to_position: to,
        from_position: from,
      });
      setProducts(
        produce(products, (draft) => {
          const dragged = draft[from];

          draft.splice(from, 1);
          draft.splice(to, 0, dragged);
        })
      );
      toast.success('Posição Atualizada com sucesso!');
    } catch (err) {
      toast.error('Erro ao atualizar posição da categoria');
      getProducts();
    }
  }

  return (
    <OrderProductCategoryContext.Provider value={{ products, move }}>
      <Container>
        <Header>
          <div>
            <h2>Ordenar categoria: {category.name}</h2>
            <p>
              Ordene aqui como ficará os produtos dentro desta categoria, basta
              clicar e arrastar o card do produto para a posição desejada
            </p>
          </div>
          <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
        </Header>

        <ProductCategoriesContainer>
          {products.length > 0 ? (
            products.map((product, index) => (
              <ProductItem
                key={product.id}
                index={index}
                product={product}
                categoriesOptions={categoriesOptions}
                getCategories={getProducts}
              />
            ))
          ) : (
            <p>Nenhum produto nesta categoria</p>
          )}
        </ProductCategoriesContainer>
      </Container>
    </OrderProductCategoryContext.Provider>
  );
}

export default OrderProductCategory;
