import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { format, startOfDay, endOfDay, subHours, startOfWeek, startOfMonth, addHours } from 'date-fns';
import { Form } from "@unform/web";
import { MdArrowDropDown } from 'react-icons/md';
import {
  Row,
  Col,
  Collapse,
} from "reactstrap";
import { Input } from '@material-ui/core';

import {
  Card,
  Container,
  Header,
  BoxTotal,
  BoxOthers,
  DateDiv,
  DateSelectorDiv,
  SubmitDate,
  SelectDateButton,
} from './styles';

import api from '~/services/api';
import history from '~/services/history';
import BoxCard from '~/components/Box/BoxCard';
import BackButton from '~/components/Buttons/BackButton';

import { useAuth } from '~/context/AuthContext';

function Box() {
  const { user } = useAuth();

  const [paymentsPicpay, setPaymentsPicpay] = useState();
  const [paymentsPaytime, setPaymentsPaytime] = useState();
  const [creditVisa, setCreditVisa] = useState();
  const [debitVisa, setDebitVisa] = useState();
  const [creditMastercard, setCreditMastercard] = useState();
  const [debitMastercard, setDebitMastercard] = useState();
  const [creditElo, setCreditElo] = useState();
  const [debitElo, setDebitElo] = useState();
  const [creditHipercard, setCreditHipercard] = useState();
  const [debitHipercard, setDebitHipercard] = useState();
  const [creditDiners, setCreditDiners] = useState();
  const [debitDiners, setDebitDiners] = useState();
  const [creditAmericanexpress, setCreditAmericanexpress] = useState();
  const [debitAmericanexpress, setDebitAmericanexpress] = useState();
  const [otherPayments, setOtherPayment] = useState();
  const [money, setMoney] = useState();
  const [picpay_offline, setPicPayOffline] = useState();
  const [pixPayment, setPixPayment] = useState();

  const [total, setTotal] = useState(0);
  const [totalPicpay, setTotalPicpay] = useState(0);
  const [totalPaytime, setTotalPaytime] = useState(0);

  const formRef = useRef(null);

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  let inicialFormat = format(new Date(defaultInicial), "dd/MM/yyyy, HH:mm");
  let finalFormat = format(new Date(defaultFinal), "dd/MM/yyyy, HH:mm");
  let phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  // const [inicialDateSearch, setInicialDateSearch] = useState(format(addHours(new Date(inicialDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));
  // const [finalDateSearch, setFinalDateSearch] = useState(format(addHours(new Date(finalDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));

  // useEffect(() => {
  //   setInicialDateSearch(format(addHours(new Date(inicialDate), 6), "yyyy-MM-dd'T'HH:mm:ss"));
  //   setFinalDateSearch(format(addHours(new Date(finalDate), 6), "yyyy-MM-dd'T'HH:mm:ss"));  
  // }, [inicialDate, finalDate]);

  function setToday(){
    setInicialDate(format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfDay(subHours(new Date(), 27)), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek(){
    setInicialDate(format(startOfWeek(subHours(new Date(), 3), {weekStartsOn: 1}), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfWeek(subHours(new Date(), 3), {weekStartsOn: 1}), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth(){
    setInicialDate(format(startOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfMonth(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  let [initialPre, setInitialPre] = useState(inicialDate);
  let [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    setInicialDate(format(subHours(new Date(initialPre), 3), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(subHours(new Date(finalPre), 3), "yyyy-MM-dd'T'HH:mm:ss"));
    let inicialFormat = format(new Date(initialPre), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(new Date(finalPre), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    
    setHourPhrase(phrase);
  }

  const getPayments = useCallback(async () => {
    const response = await api.get('restaurants/reports/cashier', {
      params: {
        start_date: `${format(
          addHours(new Date(inicialDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
        end_date: `${format(
          addHours(new Date(finalDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
      },
    });

    const {totals} = response.data.general;

    const visaCredit = totals.flags.visa_credito
    const visaDebit = totals.flags.visa_debito
    const mastercardCredit = totals.flags.mastercard_credito
    const mastercardDebit = totals.flags.mastercard_debito
    const eloDebit = totals.flags.elo_debito
    const eloCredit = totals.flags.elo_credito
    const hipercardDebito = totals.flags.hipercard_debito
    const hipercardCredito = totals.flags.hipercard_credito
    const dinersclubDebito= totals.flags.diners_club_debito
    const dinersclubCredito= totals.flags.diners_club_credito
    const americanExpressDebito= totals.flags.american_express_debito
    const americanExpressCredito= totals.flags.american_express_credito
    const picpayOffline = totals.flags.picpay_offline
    const pix = totals.flags.pix 
    const moneyData = totals.flags.dinheiro
    const otherPaymentsData = totals.other_payments
    const picpayData = totals.picpay_payment

    setCreditVisa(visaCredit)
    setDebitVisa(visaDebit)
    setCreditMastercard(mastercardCredit)
    setDebitMastercard(mastercardDebit)
    setOtherPayment(otherPaymentsData)
    setMoney(moneyData)
    setPaymentsPicpay(picpayData)
    setCreditElo(eloCredit)
    setDebitElo(eloDebit)
    setCreditHipercard(hipercardCredito)
    setDebitHipercard(hipercardDebito)
    setCreditDiners(dinersclubCredito)
    setDebitDiners(dinersclubDebito)
    setCreditAmericanexpress(americanExpressCredito)
    setDebitAmericanexpress(americanExpressDebito)
    setPixPayment(pix)
    setPicPayOffline(picpayOffline)

    console.log(totals)

      
    const totalPayments =
    (Number(picpayData) || 0) + (Number(visaDebit) || 0) + (Number(visaCredit) || 0) + 
    (Number(mastercardDebit) || 0) + (Number(mastercardCredit) || 0) + 
    (Number(moneyData) || 0) + (Number(otherPaymentsData) || 0) + (Number(eloDebit) || 0) + (Number(eloCredit) || 0) + 
    (Number(hipercardDebito) || 0) + (Number(hipercardCredito) || 0) + (Number(dinersclubDebito) || 0) + (Number(dinersclubCredito) || 0) + 
    (Number(americanExpressDebito) || 0) + (Number(americanExpressCredito) || 0) + (Number(pix) || 0) + (Number(picpayOffline) || 0)
    ;

    setTotal(totalPayments && totalPayments.toFixed(2));
  }, [inicialDate, finalDate]);

  useEffect(() => {
    try {
      getPayments();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getPayments]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Container>
      <Header>
        <div>
          <h2>Relatório de Finanças</h2>
          <p>Acompanhe o caixa de seu estabelecimento!</p>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Card>
      <Row>
            <Col md="4">
              <p style={{paddingLeft: 10}}>{hourPhrase}</p>
            </Col>
            <Col md="8">
              <div style={{ float: "right" }}>
                <SelectDateButton className="btn-round mr-auto" onClick={setToday} color="info">
                  Hoje
                </SelectDateButton>
                <SelectDateButton className="btn-round mr-auto" onClick={setWeek} color="info">
                  Essa semana
                </SelectDateButton>
                <SelectDateButton className="btn-round mr-auto" onClick={setMonth} color="info">
                  Esse mês
                </SelectDateButton>
                <SelectDateButton className="btn-round mr-auto" onClick={toggle} color="info">
                  Selecionar Período
                  <MdArrowDropDown color="white" size={20} />
                </SelectDateButton>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div style={{ float: "right", marginRight: "15px" }}>
                <Collapse isOpen={isOpen}>
                  <Form onSubmit={handleChangeDate} ref={formRef}>
                    <DateSelectorDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Inicial"
                          type="datetime-local"
                          name="initialDate"
                          onChange={(e) => setInitialPre(e.target.value)}
                          defaultValue={defaultInicial}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Final"
                          type="datetime-local"
                          name="finalDate"
                          onChange={(e) => setFinalPre(e.target.value)}
                          defaultValue={defaultFinal}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <div>
                        <SubmitDate onClick="submit">Filtrar</SubmitDate>
                      </div>
                    </DateSelectorDiv>
                  </Form>
                </Collapse>
              </div>
            </Col>
          </Row>
        <BoxTotal>
        
          <BoxCard
            name="Total"
            total={total || 0}
            type="total"
            color="green"
          />
        </BoxTotal>
        <BoxOthers>
          {paymentsPicpay > 0 && (
            <BoxCard
              name="PicPay Online"
              total={paymentsPicpay || 0}
              type="picpay"
              color="#17a2b8"
            />)}
             {money && (
            <BoxCard
            name="Dinheiro"
            total={money || 0}
            type="cash"
            color="#17a2b8"
          />)}
          {creditVisa && (
            <BoxCard
            name="Visa Crédito"
            total={creditVisa || 0}
            type="credit"
            color="#17a2b8"
          />)}
          {debitVisa && (
            <BoxCard
            name="Visa Débito"
            total={debitVisa || 0}
            type="debit"
            color="#17a2b8"
          />)}
          {creditMastercard && (
            <BoxCard
            name="Mastercard Crédito"
            total={creditMastercard || 0}
            type="credit"
            color="#17a2b8"
          />)}
          {debitMastercard && (
            <BoxCard
            name="Mastercard Débito"
            total={debitMastercard || 0}
            type="debit"
            color="#17a2b8"
          />)}
          {debitElo && (
             <BoxCard
             name="Elo Débito"
             total={debitElo || 0}
             type="debit"
             color="#17a2b8"
           />
          )}
          {creditElo && (
             <BoxCard
             name="Elo Crédito"
             total={creditElo || 0}
             type="credit"
             color="#17a2b8"
           />
          )}
           {debitHipercard && (
             <BoxCard
             name="Hipercard Débito"
             total={debitHipercard || 0}
             type="debit"
             color="#17a2b8"
           />
          )}
           {creditHipercard && (
             <BoxCard
             name="Hipercard Crédito"
             total={creditHipercard || 0}
             type="credit"
             color="#17a2b8"
           />
          )}
           {debitDiners && (
             <BoxCard
             name="Dinersclub Débito"
             total={debitDiners || 0}
             type="debit"
             color="#17a2b8"
           />
          )}
          {creditDiners && (
             <BoxCard
             name="Dinersclub Débito"
             total={creditDiners || 0}
             type="credit"
             color="#17a2b8"
           />
          )}
          {debitAmericanexpress && (
             <BoxCard
             name="American Express Débito"
             total={debitAmericanexpress || 0}
             type="debit"
             color="#17a2b8"
           />
          )}
           {creditAmericanexpress && (
             <BoxCard
             name="American Express Crédito"
             total={creditAmericanexpress || 0}
             type="credit"
             color="#17a2b8"
           />
          )}

          {pixPayment && (
             <BoxCard
             name="Pix"
             total={pixPayment || 0}
             type="cash"
             color="#17a2b8"
           />
          )}  

          {picpay_offline && (
             <BoxCard
             name="PicPay Restaurante"
             total={picpay_offline || 0}
             type="cash"
             color="#17a2b8"
           />
          )} 
         
          {otherPayments > 0 && (
            <BoxCard
            name="Outros"
            total={otherPayments || 0}
            type="others"
            color="#17a2b8"
          />)}
          
        </BoxOthers>
      </Card>
    </Container>
  );
}

export default Box;
