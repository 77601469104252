import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

import { BsChevronDown } from 'react-icons/bs';

import {
  Header,
  Bloco,
  Footer,
  TagWhite,
  TotalProducts,
  TotalSold,
  Percent,
  Content,
  ProductsTable,
} from './styles';

export default function ReportCategory({
  categorie,
  color,
  total_price,
  total_amount,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { total_sold_orders_price, total_sold_orders_amount } = categorie;

  return (
    <Bloco>
      <Header color={color} onClick={toggle}>
        <TagWhite />
        <strong>{categorie.name}</strong>
        <Percent>
          <h5>
            <strong>
              {isNaN(
                (categorie.total_sold_orders_price * 100) / total_price
              ) ? (
                <div>0%</div>
              ) : (
                <div>
                  {(
                    (categorie.total_sold_orders_price * 100) /
                    total_price
                  ).toFixed(2)}
                  %
                </div>
              )}
            </strong>
          </h5>
          <span>
            <BsChevronDown size={20} />
          </span>
        </Percent>
      </Header>
      <Collapse isOpen={isOpen}>
        <Content>
          <ProductsTable borderless>
            <thead>
              <tr>
                <th>Quantidade</th>
                <th>Produto</th>
                <th>Preço</th>
                <th>% (Qnt)</th>
                <th>% (Arrecadado)</th>
                <th>Total Arrecadado</th>
              </tr>
            </thead>
            <tbody>
              {categorie.products ? (
                categorie.products.map((product) => (
                  <tr key={product.id}>
                    <td>{product.total_sold_product_amount}</td>
                    <td>{product.name}</td>
                    <td>R${product.price}</td>
                    <td>
                      {isNaN(
                        (product.total_sold_product_amount * 100) /
                          total_sold_orders_amount
                      ) ? (
                        <div>0%</div>
                      ) : (
                        <div>
                          {(
                            (product.total_sold_product_amount * 100) /
                            total_sold_orders_amount
                          ).toFixed(2)}
                          %
                        </div>
                      )}
                    </td>
                    <td>
                      {isNaN(
                        product.total_sold_product_price /
                          total_sold_orders_price
                      ) ? (
                        <div>0%</div>
                      ) : (
                        <div>
                          {(
                            (product.total_sold_product_price * 100) /
                            total_sold_orders_price
                          ).toFixed(2)}
                          %
                        </div>
                      )}
                    </td>
                    <td>R${product.total_sold_product_price}</td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </ProductsTable>
        </Content>
      </Collapse>
      <Footer>
        <TotalProducts>
          <span>
            {categorie.name === 'Pedidos Cancelados'
              ? 'Quantidade'
              : 'Vendidos'}
          </span>
          <h5>{categorie.total_sold_orders_amount}</h5>
        </TotalProducts>
        <TotalSold>
          <span>Total</span>
          <h5>R${categorie.total_sold_orders_price}</h5>
        </TotalSold>
      </Footer>
    </Bloco>
  );
}
