import styled from 'styled-components';

export const Header = styled.div`
  justify-content: center;
  color: white;
  font-size: 20px;
  width: 100%;
  height: 60px;
  text-align: center;
  clear: both;

  span {
    color: black;
    padding-top: 10px;
  }
`;

export const Bloco = styled.div`
  margin: 20px auto;
  width: 23%;
  min-width: 200px;
  height: 120px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  margin-left: 20px auto;
  background: #8affa4;
  text-align: center;

  border-radius: 8px;
  border: solid 2px #8affa4;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
export const TableNumber = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  color: #00420f;
  margin: 10px;
  float: left;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  display: flex;
`;

export const Icon = styled.div`
  height: 30px;
  width: 60px;
  float: right;
  position: relative;
  margin-right: 5px;
  margin-top: 28px;
  display: flex;
  flex-direction: column;

  span {
    font-size: 20px;
    padding-top: 0px;
    margin-top: 0px;
    display: block;
    margin-top: -15px;
    color: #00420f;
  }
`;
export const Eye = styled.div`
  height: 30px;
  width: 100%;
  align-self: center;
  text-align: center;
  justify-content: center;
  position: absolute;
  clear: both;
  bottom: 0;
  margin-bottom: 10px;
  background: none;
`;

export const EyeButton = styled.button`
  height: 30px;
  align-self: center;
  text-align: center;
  border: none;
  position: absolute;
  margin-left: -10px;
  background: none;
`;

export const Total = styled.div`
  height: 20px;
  width: 100px;
  align-self: center;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  margin-top: 10px;

  span {
    font-size: 20px;
    display: block;
    margin-top: 0px;
    text-align: center;
    align-items: center;
    color: #00420f;
  }
`;

export const CloseBill = styled.button`
  bottom: 0;
  right: 0;
  position: absolute;
  margin-bottom: 10px;
  right: 20%;
  text-align: center;
  justify-content: center;
  border: none;
  background: none;
`;
