import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Table } from 'reactstrap';

export const Container = styled.div`
  margin: 10px;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  width: 90%;
  min-width: 400px;
  margin: 0 auto;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const Content = styled.div`
  margin: 0px auto;
  width: 95%;
  padding: 10px;
`;

export const ButtonArea = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end; 
    margin-right: 100px;
   
`


export const MethodsTable = styled(Table)`
  width: 70%;
  min-width: 400px;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 20px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;



  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
     
    }
  }
  tbody {

    td {
      border-bottom: 1px solid #eee;

      input {
        width: 60px;
        border: 0px;
        border-bottom: 1px solid #33333330;
        transition: all 1s;
        text-align: center;

        :focus{
          border-bottom: 1px solid red;
        }
      }
    }
   
    tr:last-child {
      button {
        position: absolute;
        right: 16px;
        bottom: 8px;
      }

      border-bottom: none;
      td {
        border-bottom: none;
        
      }
    }
  }
`;
