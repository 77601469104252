import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const Wrapper = styled.div`
  margin: 10px;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  margin: auto;
  width: 80%;
  height: auto;
  padding: 30px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid #dee2e6;
  border-top: none;
`;

export const InputPhone = styled(InputMask)`
  height: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const Label = styled.span`
  margin-bottom: 5px;
`;

export const DivButton = styled.div`
  width: 100%;
  height: 30px;
  style: block;
  position: relative;
`;

export const Content = styled.div`
  margin: 0px auto;
  width: 95%;
  padding: 10px;
`;

export const NavDiv = styled.div`
  margin: auto;
  width: 80%;
  display: flex;
  flex-direction: row;
  clear: both;
  border-bottom: none;
`;

export const OtherOptions = styled.div`
  height: auto;
  padding-top: 40px;
`;

export const Logo = styled.div`
  /* margin: auto; */
  margin-left: 15px;
  padding-top: 40px;
  padding-bottom: 20px;
`;

export const LogoPaytime = styled.div`
  margin-left: 15px;
  padding-top: 0px;
  padding-bottom: 20px;
`;

export const LogoWpp = styled.div`
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 20px;
`;

export const LinkPicpay = styled.a`
  color: green;

  &:hover {
    text-decoration: none;
    color: green;
    border-bottom: 1px solid green;
  }
`;

export const LinkPaytime = styled.a`
  color: blue;

  &:hover {
    text-decoration: none;
    color: blue;
    border-bottom: 1px solid blue;
  }
`;

export const CheckboxInfo = styled.div`
  h3 {
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 10px;
  }
  span {
    font-weight: bold;
    margin-bottom: 5px;
  }
`;
