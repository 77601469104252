import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { toast } from 'react-toastify';
import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  startOfWeek,
  startOfMonth,
  addHours,
  isAfter,
} from 'date-fns';
import { Form } from '@unform/web';
import { MdArrowDropDown } from 'react-icons/md';
import { Row, Col, Collapse, Modal, ModalBody, Table } from 'reactstrap';
import { Input } from '@material-ui/core';
import {
  Card,
  Container,
  Header,
  ReportHeader,
  DateDiv,
  DateSelectorDiv,
  HeaderCards,
  HeaderChart,
  HeaderLegend,
  HeaderChartDiv,
  A,
  SubmitDate,
  SelectDateButton,
} from './styles';
import history from '~/services/history';
import ReportCard from '~/components/Reports/ReportCard';
import ReportChartPiePrincipal from '~/components/Reports/ReportChartPiePrincipal';
import BackButton from '~/components/Buttons/BackButton';
import api from '~/services/api';
import BuyersDetailsModal from '../../../components/BuyersDetailsModal';

function ReportsPrincipal() {
  const [categories, setCategories] = useState([]);
  const [totais, setTotais] = useState([]);
  const [buyers, setBuyers] = useState(0);
  const [total_delivery_tax, setTotal_delivery_Tax] = useState(0);

  const [clientModal, setClientModal] = useState(false);

  const formRef = useRef(null);

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  const [clients, setClients] = useState([]);
  const [ordersNotPending, setOrdersNotPending] = useState([]);

  // const [inicialDateSearch, setInicialDateSearch] = useState(
  //   format(addHours(new Date(inicialDate), 3), "yyyy-MM-dd'T'HH:mm:ss")
  // );
  // const [finalDateSearch, setFinalDateSearch] = useState(
  //   format(addHours(new Date(finalDate), 3), "yyyy-MM-dd'T'HH:mm:ss")
  // );

  function setToday() {
    setInicialDate(
      format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 27)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek() {
    setInicialDate(
      format(
        startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
        "yyyy-MM-dd'T'HH:mm:ss"
      )
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth() {
    setInicialDate(
      format(startOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfMonth(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
    const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
    const phrase = `De ${inicialFormat} até ${finalFormat}`;

    setHourPhrase(phrase);
  }

  const [ratings, setRatings] = useState(0);

  const colors = [
    '#3366cc',
    '#dc3912',
    '#ff9900',
    '#109618',
    '#990099',
    '#0099c6',
    '#dd4477',
    '#66aa00',
    '#b82e2e',
    '#316395',
    '#3366cc',
    '#994499',
    '#22aa99',
    '#aaaa11',
    '#6633cc',
    '#e67300',
    '#8b0707',
    '#651067',
    '#329262',
    '#5574a6',
    '#3b3eac',
    '#b77322',
    '#16d620',
    '#b91383',
    '#f4359e',
    '#9c5935',
    '#a9c413',
    '#2a778d',
    '#668d1c',
    '#bea413',
    '#0c5922',
    '#743411',
  ];

  function random_color() {
    const cores = colors;
    const cor = cores[0];
    cores.splice(0, 1);
    return cor;
  }

  const CategorysToLegend = categories.map(function (categorie) {
    return {
      name: categorie.name,
      color: random_color(),
    };
  });

  const cores = [];
  CategorysToLegend.map((category) => cores.push(category.color));

  const dataChart = categories.map(function (categorie) {
    const cor = CategorysToLegend.filter(
      (category) => category.name === categorie.name
    );
    return {
      id: categorie.name,
      label: categorie.name,
      value: categorie.total_sold_orders_price,
      color: cor[0].color,
    };
  });

  const getCategories = useCallback(async () => {
    try {
      const response = await api.get('restaurants/reports/orders', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      setCategories(response.data.categoriesReport);

      setTotais({
        total_restaurant_price: response.data.total_restaurant_price.toFixed(2),
        total_restaurant_amount: response.data.total_restaurant_amount,
        total_restaurant_orders: response.data.total_restaurant_orders,
      });
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  const getOrdersNotPending = useCallback(async () => {
    try {
      const response = await api.get(`/restaurants/orders`, {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      const orderNotPending = response.data.filter(
        (order) =>
          order.payment_approved_date !== null &&
          order.order_status !== 'pending' &&
          order.order_status !== 'canceled' &&
          order.order_status !== 'chargeback'
      );
      setOrdersNotPending(orderNotPending);
      // console.log(orderNotPending);
      let ordersTotalPrice = 0;

      orderNotPending.forEach((order) => {
        ordersTotalPrice += parseFloat(order.total_price_with_sms);
      });

      const clients = [];
      const vetor = orderNotPending;
      let clientsTotal = [];

      for (let i = 0; i < vetor.length; i++) {
        !clients.includes(vetor[i].buyer.phone) &&
          clients.push(vetor[i].buyer.phone);
      }

      for (let x = 0; x < clients.length; x++) {
        let total = 0;
        let count = 0;
        let lastDate = '';
        orderNotPending.forEach((order) => {
          if (order.buyer.phone === clients[x]) {
            total += parseFloat(order.total_price_with_sms);
            count++;
            lastDate =
              lastDate === ''
                ? order.payment_approved_date
                : isAfter(
                    new Date(order.payment_approved_date),
                    new Date(lastDate)
                  )
                ? order.payment_approved_date
                : lastDate;
          }
        });

        clientsTotal.push([
          clients[x],
          total.toFixed(2),
          count,
          ((total * 100) / ordersTotalPrice).toFixed(2),
          lastDate,
        ]);
      }

      function ordenarPorVendasClients(a, b) {
        return b[4] - a[4];
      }
      clientsTotal = clientsTotal.sort(ordenarPorVendasClients);
      setClients(clientsTotal);
    } catch (err) {
      console.log(err.message);
    }
  }, [inicialDate, finalDate]);

  function orderByCount() {
    const arrayState = clients;
    const clientsOrdered = arrayState.sort((a, b) => b[2] - a[2]);
    setClients(clientsOrdered);
  }

  function orderByPercent() {
    const arrayState = clients;
    const clientsOrdered = arrayState.sort((a, b) => b[3] - a[3]);
    setClients(clientsOrdered);
  }

  function orderByTotal() {
    const arrayState = clients;
    const clientsOrdered = arrayState.sort((a, b) => b[1] - a[1]);
    setClients(clientsOrdered);
  }

  const getBuyers = useCallback(async () => {
    try {
      const response = await api.get('restaurants/reports/buyers', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });

      setBuyers(response.data.total_buy_count);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  const getPayments = useCallback(async () => {
    const response = await api.get('restaurants/reports/cashier', {
      params: {
        start_date: `${format(
          addHours(new Date(inicialDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
        end_date: `${format(
          addHours(new Date(finalDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
      },
    });

    const { totals } = response.data.general;

    setTotal_delivery_Tax(totals.delivery_tax);
  }, [inicialDate, finalDate]);

  const getRatings = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/ratings', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      setRatings(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  useEffect(() => {
    getPayments();
  }, [getPayments]);

  useEffect(() => {
    getOrdersNotPending();
  }, [getOrdersNotPending]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    getBuyers();
    getRatings();
  }, [getBuyers, getRatings]);

  // useEffect(() => {
  //   setInicialDateSearch(
  //     format(addHours(new Date(inicialDate), 6), "yyyy-MM-dd'T'HH:mm:ss")
  //   );
  //   setFinalDateSearch(
  //     format(addHours(new Date(finalDate), 6), "yyyy-MM-dd'T'HH:mm:ss")
  //   );
  // }, [inicialDate, finalDate]);

  const rate = ratings.ratings_average ? ratings.ratings_average.toFixed(2) : 0;

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const toggleClientModal = () => setClientModal(!clientModal);

  return (
    <Container>
      <Header>
        <div>
          <h2>Relatórios: Página Principal</h2>
          <p>
            Dashboard onde você pode acompanhar os principais dados sobre seu
            estabelecimento!
          </p>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Card>
        <Row>
          <Col md="4">
            <p style={{ paddingLeft: 10 }}>{hourPhrase}</p>
          </Col>
          <Col md="8">
            <div style={{ float: 'right' }}>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setToday}
                color="info"
              >
                Hoje
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setWeek}
                color="info"
              >
                Essa semana
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setMonth}
                color="info"
              >
                Esse mês
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={toggle}
                color="info"
              >
                Selecionar Período
                <MdArrowDropDown color="white" size={20} />
              </SelectDateButton>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <div style={{ float: 'right', marginRight: '15px' }}>
              <Collapse isOpen={isOpen}>
                <Form onSubmit={handleChangeDate} ref={formRef}>
                  <DateSelectorDiv>
                    <DateDiv>
                      <Input
                        id="datetime-local"
                        label="Data Inicial"
                        type="datetime-local"
                        name="initialDate"
                        onChange={(e) => setInitialPre(e.target.value)}
                        defaultValue={defaultInicial}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DateDiv>
                    <DateDiv>
                      <Input
                        id="datetime-local"
                        label="Data Final"
                        type="datetime-local"
                        name="finalDate"
                        onChange={(e) => setFinalPre(e.target.value)}
                        defaultValue={defaultFinal}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DateDiv>
                    <div>
                      <SubmitDate onClick="submit">Filtrar</SubmitDate>
                    </div>
                  </DateSelectorDiv>
                </Form>
              </Collapse>
            </div>
          </Col>
        </Row>
        <ReportHeader>
          <HeaderCards>
            <A href="/reports/products">
              <ReportCard
                name="Venda de Produtos"
                total={totais.total_restaurant_price}
                type="cash"
                color="#28a745"
              />
            </A>

            <A href="/reports/financial">
              <ReportCard
                name="Taxa de entrega"
                total={total_delivery_tax}
                type="cash"
                color="orange"
              />
            </A>

            <A href="/reports/products">
              <ReportCard
                name="Total de Pedidos"
                total={totais.total_restaurant_orders}
                type="takeat"
                color="red"
              />
            </A>
            <A onClick={toggleClientModal}>
              <ReportCard
                name="Clientes"
                total={buyers}
                type="clients"
                color="blue"
              />
            </A>
            <Modal isOpen={clientModal} toggle={toggleClientModal} size="lg">
              <ModalBody>
                <BuyersDetailsModal
                  clients={clients}
                  orderByLastvisit={getOrdersNotPending}
                  orderByCount={orderByCount}
                  orderByTotal={orderByTotal}
                  orderByPercent={orderByPercent}
                />
              </ModalBody>
            </Modal>
            <A href="/reports/rate">
              <ReportCard
                name="Avaliações"
                type="rate"
                color="#ffb400"
                rate={rate}
              />
            </A>
          </HeaderCards>
          <HeaderChartDiv>
            <HeaderChart>
              <ReportChartPiePrincipal
                radius={0.2}
                data={dataChart}
                colors={cores}
              />
            </HeaderChart>
            <HeaderLegend>Arrecadação (R$)</HeaderLegend>
          </HeaderChartDiv>
        </ReportHeader>
      </Card>
    </Container>
  );
}

export default ReportsPrincipal;
