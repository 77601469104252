import firebase from 'firebase/app';
import 'firebase/messaging';

const config = {
  apiKey: "AIzaSyDGuHz2mqR9R4oobRegsEXOTKVqIdXEDXY",
  authDomain: "takeat-522b8.firebaseapp.com",
  databaseURL: "https://takeat-522b8.firebaseio.com",
  projectId: "takeat-522b8",
  storageBucket: "takeat-522b8.appspot.com",
  messagingSenderId: "739716097853",
  appId: "1:739716097853:web:9f1a7d40f258c918d18754",
  measurementId: "G-4WCHXSP07L"
};

firebase.initializeApp(config);

let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

/*
 * Documentation
 * https://firebase.google.com/docs/reference/js/firebase.messaging.Messaging
*/
export const firebaseToken = () => {
  if (messaging !== null) {
    return messaging.getToken();
  }
  return Promise.resolve(null);
};

export const onMessage = (listener) => {
  if (messaging !== null) {
    messaging.onMessage(listener);
  }
};

export const isSupported = messaging !== null;

