import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Row, Col, Container } from 'reactstrap';
import { Wrapper, Header, Card, StyledForm } from './styles';

import api from '~/services/api';

import Input from '~/components/Form/Input';
import Checkbox from '~/components/Form/Checkbox';

import SaveButton from '~/components/Buttons/SaveButton';

function Store({toggle, getCategories, category}) {

  const initialData = {
    name: category.name,
    time: category.preparation_time,
    available: category.available,
  };

  const formRef = useRef(null);

  async function handleUpdate(data) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
      });

      await schema.validate(
        { name: data.name },
        {
          abortEarly: false,
        }
      );

      await api.put(`/restaurants/product-category/${category.id}`, {
        name: data.name,
        preparation_time: data.time,
        available: data.available,
      });

      toast.success('Categoria atualizada com sucesso!');
      getCategories()
      toggle()
    } catch (error) {
      // console.tron.log(error);
      toast.error(`Erro ao atualizar categoria! ${error.message}`);
    }
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Cadastrar categoria de produtos</h2>
        </div>
        
      </Header>
      <Container>
        <Row>
          <Col md="6 ml-auto mr-auto">
            <Card>
              <StyledForm
                initialData={initialData}
                ref={formRef}
                onSubmit={handleUpdate}
              >
                <Input
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Nome da categoria"
                />
                <Input
                  label="Tempo de preparo"
                  name="time"
                  type="text"
                  placeholder="Tempo (em minutos)"
                />
                <Checkbox label="Categoria Disponível" name="available" />
                <SaveButton type="submit">Cadastrar</SaveButton>
              </StyledForm>
            </Card>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Store;
