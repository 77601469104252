import React, { useRef, useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import Switch from 'react-switch';

import { FiMenu } from 'react-icons/fi';

import { toast } from 'react-toastify';

import { MdDelete, MdCreate, MdCached } from 'react-icons/md';
import OrderProductCategoryContext from '~/pages/Products/Categories/context';
import api from '~/services/api';

import OutlineButton from '~/components/Buttons/OutlineButton';
import {
  Container,
  CategoryHeader,
  ButttonArea,
  Delete,
  ReorderContainer,
} from './styles';

import ProductsTables from '../../../pages/Products/ProductsTables';

function CategoryItem({
  category,
  user,
  getCategories,
  handleChangeCategory,
  handleChangeModalEditCategory,
  handleDelete,
  handleDeleteCategory,
  handleChangeModalProduct,
  index,
}) {
  const ref = useRef();
  const { move } = useContext(OrderProductCategoryContext);

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'CATEGORYCARD', index, product_category_id: category.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: 'CATEGORYCARD',
    hover(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      if (draggedIndex === targetIndex) {
        return;
      }

      const targetSize = ref.current.getBoundingClientRect();
      const targetCenter = targetSize.height / 2;
      const draggedOffset = monitor.getClientOffset();
      const draggedTop = draggedOffset.y - targetSize.top;
      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return;
      }
      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return;
      }
      move(item.product_category_id, category.id, draggedIndex, targetIndex);
      item.index = targetIndex;
    },
  });

  dragRef(dropRef(ref));

  async function reorderProducts() {
    try {
      await api.post(`/restaurants/products/reorder/${user.id}`);

      toast.success('Produtos reordenados com sucesso!');

      getCategories();
    } catch (err) {
      // console.tron.log(err);
      toast.error('Erro ao ordenar cardápio');
    }

    // window.location.reload();
  }

  return (
    <Container isDragging={isDragging} ref={ref}>
      <CategoryHeader>
        <div>
          <FiMenu
            title="Clique e arraste sobre outra categoria para ordenar"
            color="ff403d"
            size={22}
          />
          <p>{category.name}</p>
        </div>

        <div>
          <MdCreate
            size={20}
            color="#333333"
            alt="Editar Categoria"
            title="Editar Categoria"
            onClick={() => {
              handleChangeModalEditCategory(category);
            }}
          />

          <Delete onClick={() => handleDeleteCategory(category.id)}>
            <MdDelete size={20} title="Deletar Categoria" />
          </Delete>

          <div title="Ativar/ Desativar Categoria">
            <Switch
              id={`${category.id}`}
              onChange={handleChangeCategory}
              draggable
              checked={category.available}
              checkedIcon={false}
              uncheckedIcon={false}
              height={20}
              width={40}
              handleDiameter={20}
              activeBoxShadow="0 0 2px 3px #111"
              boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
            />
          </div>
        </div>
      </CategoryHeader>
      <ButttonArea>
        <OutlineButton
          text="Adicionar produtos"
          onClick={() => {
            handleChangeModalProduct(category);
          }}
        />
        <ReorderContainer>
          <button type="button" onClick={reorderProducts}>
            <MdCached /> Normalizar Ordenação
          </button>
        </ReorderContainer>
      </ButttonArea>

      <ProductsTables
        category={category}
        handleDelete={handleDelete}
        user={user}
        getCategories={getCategories}
      />
    </Container>
  );
}

export default CategoryItem;
