import React from 'react';

import {
  Container,
  Title,
  Filter,
  Restaurant,
  Body,
  Phone,
  Price,
  Items,
  Item,
  ItemName,
  Footer,
  PriceItem,
  NamePrice,
  AlreadyClosed,
  TotalDiv,
} from './styles';

class PrintReportProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: props.categories,
      teste: props.teste,
      total_restaurant_amount: props.total_restaurant_amount,
      total_restaurant_price: props.total_restaurant_price,
      inicialFormat: props.inicialFormat,
      finalFormat: props.finalFormat,
      payments: props.payments,
      totalBox: props.totalBox,
      buyers: props.buyers,
      restaurantName: props.restaurantName,
      categoriesCanceled: props.categoriesCanceled,
      total_restaurant_price_canceled: props.total_restaurant_price_canceled,
      total_restaurant_amount_canceled: props.total_restaurant_amount_canceled,
    };
  }

  render() {
    const {
      categories,
      inicialFormat,
      finalFormat,
      total_restaurant_price,
      total_restaurant_amount,
      categoriesCanceled,
      total_restaurant_price_canceled,
      total_restaurant_amount_canceled,
      buyers,
      payments,
      restaurantName,
    } = this.state;

    console.log(this.state);

    return (
      <Container>
        <Title>
          <strong>RELATÓRIO DE PRODUTOS</strong>
        </Title>
        <Filter>
          {inicialFormat} - {finalFormat}
        </Filter>
        <Restaurant>{restaurantName}</Restaurant>
        {categories.map((categorie) => (
          <div>
            <Body>
              <Phone>
                <strong>{categorie.name}</strong>
              </Phone>
              <Price>
                <strong>R${categorie.total_sold_orders_price}</strong>
              </Price>
            </Body>
            <Items>
              <Item>
                <NamePrice>
                  <ItemName>Total Pedidos</ItemName>
                  <PriceItem>{categorie.total_sold_orders_amount}</PriceItem>
                </NamePrice>
              </Item>
              {categorie.products.map(
                (product) =>
                  product.total_sold_product_amount !== 0 && (
                    <Item>
                      <NamePrice>
                        <ItemName>
                          {product.total_sold_product_amount}x {product.name}
                        </ItemName>
                        <PriceItem>
                          R$
                          {product.total_sold_product__service_price.toFixed(2)}
                        </PriceItem>
                      </NamePrice>
                    </Item>
                  )
              )}
              <Item>
                <NamePrice>
                  <ItemName>Porcentagem</ItemName>
                  <PriceItem>
                    {isNaN(
                      (categorie.total_sold_orders_price * 100) /
                        total_restaurant_price
                    ) ? (
                      <div>0%</div>
                    ) : (
                      <div>
                        {(
                          (categorie.total_sold_orders_price * 100) /
                          total_restaurant_price
                        ).toFixed(2)}
                        %
                      </div>
                    )}
                  </PriceItem>
                </NamePrice>
              </Item>
            </Items>
          </div>
        ))}
        <Body>
          <Phone>
            <strong>Pedidos Cancelados</strong>
          </Phone>
          <Price>
            <strong>R${total_restaurant_price_canceled}</strong>
          </Price>
        </Body>
        <Items>
          <Item>
            <NamePrice>
              <ItemName>Total Excluídos</ItemName>
              <PriceItem>{total_restaurant_amount_canceled}</PriceItem>
            </NamePrice>
          </Item>
          {categoriesCanceled &&
            categoriesCanceled.map(
              (canceledCategorie) =>
                canceledCategorie.products &&
                canceledCategorie.products.map((product) => (
                  <Item>
                    <NamePrice>
                      <ItemName>
                        {product.total_canceled_product_amount}x {product.name}
                      </ItemName>
                      <PriceItem>
                        R${product.total_canceled_product_price}
                      </PriceItem>
                    </NamePrice>
                  </Item>
                ))
            )}
        </Items>

        <TotalDiv>
          <Body>
            <Phone>
              <strong>TOTAL</strong>
            </Phone>
            <Price>
              <strong>R${total_restaurant_price.toFixed(2)}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>PRODUTOS PEDIDOS</strong>
            </Phone>
            <Price>
              <strong>{total_restaurant_amount}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>CLIENTES</strong>
            </Phone>
            <Price>
              <strong>{buyers}</strong>
            </Price>
          </Body>
        </TotalDiv>

        <AlreadyClosed>
          <h4>Formas de Pagamento</h4>
          <Body>
            <Phone>
              <strong>PicPay Online</strong>
            </Phone>
            <Price>
              <strong>R${payments.picpay}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>PicPay Restaurante</strong>
            </Phone>
            <Price>
              <strong>R${payments.picpay_offline}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>Dinheiro</strong>
            </Phone>
            <Price>
              <strong>R${payments.dinheiro}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>Mastercard Crédito</strong>
            </Phone>
            <Price>
              <strong>R${payments.mastercard_credito}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>Mastercard Débito</strong>
            </Phone>
            <Price>
              <strong>R${payments.mastercard_debito}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>Visa Crédito</strong>
            </Phone>
            <Price>
              <strong>R${payments.visa_credito}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>Visa Débito</strong>
            </Phone>
            <Price>
              <strong>R${payments.visa_debito}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>Elo Crédito</strong>
            </Phone>
            <Price>
              <strong>R${payments.elo_credito}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>Elo Débito</strong>
            </Phone>
            <Price>
              <strong>R${payments.elo_debito}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>Hipercard Crédito</strong>
            </Phone>
            <Price>
              <strong>R${payments.hipercard_credito}</strong>
            </Price>
          </Body>
          <Body>            
            <Phone>
              <strong>Hipercard Débito</strong>
            </Phone>
            <Price>
              <strong>R${payments.hipercard_debito}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>DinersClub Crédito</strong>
            </Phone>
            <Price>
              <strong>R${payments.diners_club_credito}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>DinersClub Débito</strong>
            </Phone>
            <Price>
              <strong>R${payments.diners_club_debito}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>AmericanExpress Crédito</strong>
            </Phone>
            <Price>
              <strong>R${payments.american_express_credito}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>AmericanExpress Débito</strong>
            </Phone>
            <Price>
              <strong>R${payments.american_express_debito}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>Pix</strong>
            </Phone>
            <Price>
              <strong>R${payments.pix}</strong>
            </Price>
          </Body>
                    
          <Body>
            <Phone>
              <strong>Outros</strong>
            </Phone>
            <Price>
              <strong>R${payments.other_payments}</strong>
            </Price>
          </Body>
        </AlreadyClosed>
        <Footer>
          <strong>Obrigado por escolher a Takeat!</strong>
        </Footer>

        <div />
      </Container>
    );
  }
}

export default PrintReportProducts;
