import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  height: auto;
`;

export const Table = styled.div`
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 25px;
  margin-right: auto;
`;

export const Bill = styled.div`
  padding-top: 5px;
  width: 110mm;
  text-align: center;
  font-size: 15px;
  margin-right: auto;
`;

export const Restaurant = styled.div`
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 20px;
  margin-right: auto;
  padding-bottom: 15px;
  border-bottom: solid black 1px;
`;

export const Body = styled.div`
  padding-top: 10px;
  width: 110mm;
  clear: both;
  height: auto;
  display: block;
  padding-bottom: 20px;
`;

export const Phone = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: 20px;
  padding-top: 10px;
  float: left;
`;

export const Price = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 20px;
  padding-top: 10px;
  float: right;
`;

export const Items = styled.div`
  width: 110mm;
  padding-top: 10px;
  style: block;
`;

export const Item = styled.div`
  padding-top: 10px;
  padding-left: 10px;
`;

export const NamePrice = styled.div`
  width: 100%;
  display: flex;
`;

export const ItemName = styled.div`
  font-size: 20px;
  width: 65%;
`;

export const PriceItem = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 20px;
  width: 35%;
  float: right;
`;

export const ComplementsTitle = styled.div`
  font-size: 25px;
`;

export const OrderTime = styled.div`
  width: 100%;
  font-size: 15px;
  padding-left: 10px;
  padding-top: 20px;
  align-self: center;
  align-text: center;
`;

export const Complements = styled.div`
  font-size: 15px;
  padding-left: 15px;
  width: 75%;
`;

export const PriceComplement = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 15px;
  width: 25%;
  float: right;
`;

export const Obs = styled.div`
  padding-top: 5px;
  font-size: 15px;
`;

export const Footer = styled.div`
  margin-top: 20px;
  padding-top: 10px;
  border-top: solid black 1px;
  font-size: 20px;
  text-align: center;
  width: 110mm;
`;

export const AlreadyClosed = styled.div`
  margin-top: 10px;
  border-top: solid black 1px;
  padding-bottom: 5px;
  font-size: 20px;
  text-align: center;
  width: 110mm;
`;

export const TotalDiv = styled.div`
  padding-top: 20px;
  margin-top: 40px;
  padding-bottom: 5px;
  font-size: 20px;
  text-align: center;
  width: 110mm;
  padding-bottom: 15px;
  display: block;
`;

export const TotalRow = styled.div`
  margin-top: 5px;
  width: 110mm;
  clear: both;
  display: block;
`;

export const TotalName = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: 20px;
  padding-top: 5px;
  float: left;
`;

export const TotalPrice = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 20px;
  padding-top: 5px;
  float: right;
`;

export const TotalName10 = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: 15px;
  padding-top: 5px;
  float: left;
`;

export const TotalPrice10 = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 15px;
  padding-top: 5px;
  float: right;
`;
