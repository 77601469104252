import styled from 'styled-components';
import { darken } from 'polished';
import { Link } from 'react-router-dom';
import { UncontrolledCollapse } from 'reactstrap';

export const Container = styled.div``;

export const NavbarItem = styled.nav.attrs((props) => ({
  darkenBg: darken(0.05, props.theme.colors.primary),
}))`
  border-right: 1px solid ${(props) => props.theme.colors.lightGray};
  background-color: ${(props) => props.theme.colors.white};
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 13rem;
  height: 100vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #555;
  }


  p {
    text-align: center;


  }

 

  /* &:hover {
    width: 13rem;
  } */

  @media (max-width: 768px) {
    transition: transform 0.3s ease-in-out;
    transform: ${(props) =>
      props.collapsed ? 'translateX(-100%)' : 'translateX(0)'};
  }

  ul {
    list-style: none;
    margin: 20px 0 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    li {
      transition: 500ms;
      padding: 0;
      margin: 0;
      width: 100%;

      button {
        border: none;
        background: none;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 3rem;
        color: ${(props) => props.theme.colors.text};

        &:hover {
          background: ${(props) => props.theme.colors.lightGray};
          cursor: pointer;
        }

        &:active,
        &:focus {
          border: none;
        }

        svg {
          margin-left: 15px;
          color: ${(props) => props.theme.colors.secondary};
          font-size: 30px;
        }

        div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          span {
            font-size: 18px;
            margin: 0 10px;
          }
          div {
            &::after {
              content: '';
              width: 0;
              height: 0;
              margin-left: auto;
              margin-right: 10px;
              align-self: center;
              border-top: 4px solid #111;
              border-right: 4px solid transparent;
              border-left: 4px solid transparent;
            }
          }
        }
      }
    }
  }
`;

export const RestaurantInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  margin: 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};

  span {
    margin-left: 10px;
    font-size: 16px;
  }

`;

export const Profile = styled.div`
  display: block;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  text-align: start;

  div {
    display: flex;
    margin: 10px auto;
    flex-direction: column;

    /* ${NavbarItem}:hover & {
      display: block;
    } */
  }

  span {
    margin-top: 5px;
    padding: 5px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  img {
    height: 60px;
    width: 100%;
    border: none;
    background: none;
    object-fit: contain;
  }

  a {
    margin-top: 10px;
    font-size: 16px;
    transition: color 0.5s;
    text-align: center;
    color: #aaa;

    &:hover {
      color: #111;
    }
  }
`;

export const StyledCollapse = styled(UncontrolledCollapse)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;

  a {
    font-size: 14px;
    color: ${(props) => props.theme.colors.gray};
    transition: color 200ms;
    margin: 5px 0;

    &:hover {
      text-decoration: none;
      color: ${(props) => props.theme.colors.text};
    }
  }
`;

export const IconImg = styled(Link)`
  float: left;
  margin-left: 15px;
  color: red;

  img {
    width: 25px;
    heigth: 50px;
    fill: red;
    color: red;
  }
`;

export const LinkMenu = styled(Link)`
  border: none;
  background: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 3rem;
  color: ${(props) => props.theme.colors.text};

  &:hover {
    background: ${(props) => props.theme.colors.lightGray};
    cursor: pointer;
    text-decoration: none;
    color: #333;
  }

  &:active,
  &:focus {
    border: none;
  }

  svg {
    margin-left: 15px;
    color: ${(props) => props.theme.colors.secondary};
    font-size: 30px;
  }

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 18px;
      margin: 0 10px;
    }
    div {
      &::after {
        content: '';
        width: 0;
        height: 0;
        margin-left: auto;
        margin-right: 10px;
        align-self: center;
        border-top: 4px solid #111;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
      }
    }
  }
`;

export const RestaurantInfoTitle = styled.p`
  margin-top: 48px;
`