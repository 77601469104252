import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';

import api from '~/services/api';
import history from '~/services/history';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@qrdashboard:token');
    const user = localStorage.getItem('@qrdashboard:user');

    if (token && user) {
      if (token !== null) {
        try {
          const { exp } = jwtDecode(token);
          const expirationTime = exp * 1000;
          if (Date.now() >= expirationTime) {
            localStorage.removeItem('@qrdashboard:token');
            localStorage.removeItem('@qrdashboard:user');

            return { token: null, user: null };
          }
        } catch (err) {
          console.log('invalid Token');
        }
      }
      api.defaults.headers.Authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return { token: null, user: null };
  });
  const [loading, setLoading] = useState(false);

  const signOut = useCallback(() => {
    localStorage.removeItem('@qrdashboard:token');
    localStorage.removeItem('@qrdashboard:user');

    setData({ user: null, token: null });

    history.push('/');
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    setLoading(true);
    try {
      const response = await api.post('/public/sessions/restaurants', {
        email,
        password,
      });

      const { token, user } = response.data;

      localStorage.setItem('@qrdashboard:token', token);
      localStorage.setItem('@qrdashboard:user', JSON.stringify(user));

      api.defaults.headers.Authorization = `Bearer ${token}`;

      setData({ token, user });
    } catch (err) {

      if (err.message ==='Network Error'){
        toast.error('Erro de comunicação, verifique sua conexão de internet!');
      } else {
        toast.error('Erro ao realizar login, verifique seus dados!');
      }
      

      console.log(err.message)
    }

    setLoading(false);
  }, []);

  const updateUser = useCallback(
    async (updateData) => {
      try {
        setLoading(true);
        const {
          username,
          fantasy_name,
          email,
          avatar_id,
          phone,
          cover_id,
          location,
          time_to_delivery,
          time_to_withdrawal,
          minimum_delivery_price,
          delivery_tax_by_km,
          address,
          schedules,
          notify_whatsapp,
          ...rest
        } = updateData;

        const profile = {
          username,
          fantasy_name,
          email,
          phone,
          location,
          time_to_delivery,
          time_to_withdrawal,
          minimum_delivery_price,
          delivery_tax_by_km,
          address,
          schedules,
          notify_whatsapp,
          ...(rest.oldPassword ? rest : {}),
        };
        if (avatar_id) {
          profile.avatar_id = Number(avatar_id);
        }
        if (cover_id) {
          profile.cover_id = Number(cover_id);
        }
        const response = await api.put('restaurants', profile);

        const profileUpdated = {
          ...response.data,
        };

        setData({
          token: data.token,
          user: profileUpdated,
        });

        toast.success('Perfil atualizado com Sucesso!');
        setLoading(false);
      } catch (err) {
        toast.error('Erro ao atualizar perfil, confira seus dados!');
        setLoading(false);
      }
    },
    [setData, data.token]
  );

  const getProfileData = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/show');

      setData({
        token: data.token,
        user: response.data,
      });
    } catch (err) {
      toast.error('Erro ao atualizar informações deste restaurante');
    }
  }, [setData, data.token]);

  const updateToken = useCallback(
    async (updateData) => {
      try {
        const {
          x_picpay_token,
          x_seller_token,
          paytime_seller_id,
          withdrawal_allowed,
        } = updateData;

        const profile = {
          x_picpay_token: x_picpay_token || null,
          x_seller_token: x_seller_token || null,
          paytime_seller_id,
          withdrawal_allowed,
        };

        const response = await api.put('restaurants', profile);

        const profileUpdated = {
          ...response.data,
        };
        setData({
          token: data.token,
          user: profileUpdated,
        });

        toast.success('Tokens atualizados com Sucesso!');
      } catch (err) {
        toast.error('Erro ao atualizar Tokens, confira seus dados!');
      }
    },
    [data.token]
  );

  const updateSMSInfo = useCallback(
    async (updateData) => {
      try {
        const {
          has_sms_service,
          is_sms_service_optional,
          client_pay_sms,
          whatsapp,
        } = updateData;

        const profile = {
          has_sms_service,
          is_sms_service_optional,
          client_pay_sms,
          whatsapp,
        };
        const response = await api.put('restaurants', profile);

        const profileUpdated = {
          ...response.data,
        };

        console.log(profileUpdated);
        console.log('atual em baixo');
        console.log(data.user);
        setData({
          token: data.token,
          user: profileUpdated,
        });

        toast.success('Configurações atualizadas com sucesso!');
      } catch (err) {
        toast.error('Erro ao atualizar configurações, confira seus dados!');
      }
    },
    [data.user, setData, data.token]
  );

  const changeRestaurantStatus = useCallback(
    async (status) => {
      try {
        const response = await api.put('restaurants/status', status);

        const user = {
          ...data.user,
          ...response.data,
        };

        setData({
          token: data.token,
          user,
        });

        if (user.opened) {
          toast.success('Restaurante aberto com sucesso');
        } else {
          toast.success('Restaurante fechado com sucesso');
        }

        return response.data;
      } catch (err) {
        toast.error('Erro ao atualizar, tente novamente!');
      }

      return data.user;
    },
    [data.user, setData, data.token]
  );

  const updateDeliveryStatus = useCallback(
    async (status) => {
      try {
        const response = await api.put('restaurants/status', status);

        const user = {
          ...data.user,
          ...response.data,
        };

        setData({
          token: data.token,
          user,
        });

        if (user.is_delivery_active) {
          toast.success('Delivery ativado com sucesso!');
        } else {
          toast.success('Delivery pausado com sucesso!');
        }

        return response.data;
      } catch (err) {
        toast.error('Erro ao atualizar, tente novamente!');
      }

      return data.user;
    },
    [data.user, setData, data.token]
  );

  const changeOpenStatus = useCallback(
    async (updatedData) => {
      const { opened, is_delivery_active } = updatedData;

      setData({
        token: data.token,
        user: {
          ...data.user,
          opened,
          is_delivery_active 
        },
      });

    },
    [data.user]
  );

  useEffect(() => {
    if (data.user) {
      getProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem('@qrdashboard:token', data.token);
  }, [data.token]);

  useEffect(() => {
    localStorage.setItem('@qrdashboard:user', JSON.stringify(data.user));
  }, [data.user]);

  const value = useMemo(
    () => ({
      signIn,
      user: data.user,
      signOut,
      updateUser,
      loading,
      changeRestaurantStatus,
      updateToken,
      updateSMSInfo,
      updateDeliveryStatus,
      getProfileData,
      changeOpenStatus 
    }),
    [
      signIn,
      data.user,
      signOut,
      updateUser,
      loading,
      changeRestaurantStatus,
      updateToken,
      updateSMSInfo,
      updateDeliveryStatus,
      getProfileData,
      changeOpenStatus 
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };
