import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';

import { Row, Col } from 'reactstrap';

import { Container, Card, Header, Content } from './styles';

import api from '~/services/api';
import history from '~/services/history';
import apiIbge from '~/services/apiIbge';
import Input from '~/components/Form/Input';
import SelectInput from '~/components/Form/SelectInput';
import CheckboxInput from '~/components/Form/Checkbox';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function DeliveryConfig({address, toggle, getAddresses}) {
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState({});
  const [cities, setCities] = useState([]);
  const [, setSelectedCity] = useState({});

  const [neighborhoodLimited, setNeighborhoodLimited] = useState(
    address.is_neighborhood_limited
  );

  const initialData = {
    state: { label: address.state, value: address.state },
    city: { label: address.city, value: address.city },
    neighborhood: address.neighborhood,
    delivery_tax: address.delivery_tax_price,
    is_active: address.is_active,
  };

  const getStatesData = useCallback(async () => {
    try {
      const response = await apiIbge.get('/localidades/estados');
      const parsedStates = response.data.map((item) => {
        return {
          label: item.nome,
          value: item.sigla,
        };
      });
      setStates(parsedStates);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  const getCitiesData = useCallback(async () => {
    try {
      const response = await apiIbge.get(
        `/localidades/estados/${selectedState.value}/distritos`
      );
      const parsedCities = response.data.map((item) => {
        return {
          label: item.nome,
          value: item.nome,
        };
      });
      setCities(parsedCities);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [selectedState]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        const parsedDeliveryTax = Number(data.delivery_tax.replace(',', '.'));
        const updateData = {
          state: data.state,
          city: data.city,
          delivery_tax_price: parsedDeliveryTax,
          is_neighborhood_limited: data.is_neighborhood_limited,
          is_active: data.is_active,
        };
        if (data.is_neighborhood_limited) {
          updateData.neighborhood = data.neighborhood;
        }
        await api.put(
          `/restaurants/delivery-addresses/${address.id}`,
          updateData
        );
        toast.success('Endereço salvo com sucesso!');
     
        getAddresses()
        toggle()

      } catch (err) {
        toast.error('Erro ao salvar endereço');
      }
    },
    [address.id]
  );

  useEffect(() => {
    try {
      getStatesData();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getStatesData, address.city, address.state, cities]);

  useEffect(() => {
    try {
      getCitiesData();
    } catch (err) {
      toast.error('Erro ao carregar cidades');
    }
  }, [selectedState, getCitiesData]);

  return (
    <Container>
      <Header>
        <div>
          <h2>Delivery</h2>
          <p>Configure a modalidade de delivery do seu estabelecimento!</p>
        </div>

      </Header>

      <Card>
        <Form onSubmit={handleSubmit} initialData={initialData}>
          <Content>
            <Row>
              <h4
                style={{ paddingLeft: 10, paddingBottom: 15, paddingTop: 30 }}
              >
                Endereços
              </h4>
              <Col md="12">
                <p style={{ textAlign: 'left' }}>
                  Cadastre os endereços e as taxas de entrega do seu
                  estabelecimento aqui!
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <Row>
                  <Col sm="12" lg="12">
                    <SelectInput
                      name="state"
                      label="Selecione o estado"
                      options={states}
                      onChange={(e) => {
                        setSelectedState({ value: e.value, label: e.label });
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" lg="12">
                    <SelectInput
                      name="city"
                      label="Selecione a cidade"
                      options={cities}
                      onChange={(e) =>
                        setSelectedCity({ value: e.value, label: e.label })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" lg="12">
                    <CheckboxInput
                      defaultChecked={address.is_neighborhood_limited}
                      label="Limitar por bairro"
                      name="is_neighborhood_limited"
                      value={neighborhoodLimited}
                      onChange={() => {
                        setNeighborhoodLimited(!neighborhoodLimited);
                      }}
                    />
                  </Col>
                </Row>
                {neighborhoodLimited ? (
                  <>
                    <Row>
                      <Col sm="12" lg="12">
                        <Input
                          label="Nome do Bairro"
                          name="neighborhood"
                          type="text"
                          placeholder="Nome do bairro"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" lg="12">
                        <h4>Observações</h4>
                        <p>
                          O usuário poderá selecionar somente na lista de
                          bairros que estiver cadastrada!
                        </p>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
                <Row>
                  <Col sm="12" lg="12">
                    <Input
                      label="Taxa de entrega"
                      name="delivery_tax"
                      type="number"
                      step="0.01"
                      placeholder="R$x.xx"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" lg="12">
                    <CheckboxInput
                      // defaultChecked={address.is_active}
                      label="Endereço ativo"
                      name="is_active"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <SaveButton type="submit">Salvar</SaveButton>
          </Content>
        </Form>
      </Card>
    </Container>
  );
}

export default DeliveryConfig;
