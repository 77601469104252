import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { useLocation } from 'react-router-dom';

import { Row, Col } from 'reactstrap';

import { Container, Card, Header, Content } from './styles';

import api from '~/services/api';
import history from '~/services/history';
import Input from '~/components/Form/Input';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function CepDeliveryEdit() {
  // const [states, setStates] = useState([]);
  // const [selectedState, setSelectedState] = useState({});
  // const [cities, setCities] = useState([]);
  // const [, setSelectedCity] = useState({});

  const location = useLocation();
  const { cep, zip_code } = location.state;

  const handleUpdate = useCallback(
    async (data) => {
      try {
        await api.put(`restaurants/delivery-address-zip-codes/${cep.id}`, {
          price: data.price,
        });
        history.goBack();
        toast.success('CEP atualizado.');
      } catch (err) {
        toast.error('Erro ao atualizar CEP.');
      }
    },
    [cep.id]
  );

  return (
    <Container>
      <Header>
        <div>
          <h2>Editar CEP {zip_code}</h2>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>

      <Card>
        <Form initialData={cep} onSubmit={handleUpdate}>
          <Content>
            <Row>
              <Col sm="12" lg="12">
                <Input label="Logradouro" name="street" type="text" disabled />
              </Col>
            </Row>
            <Row>
              <Col sm="6" lg="6">
                <Input label="Cidade" name="city" type="text" disabled />
              </Col>
              <Col sm="6" lg="6">
                <Input
                  label="Taxa de Entrega (R$)"
                  name="price"
                  type="number"
                  step="0.01"
                />
              </Col>
            </Row>

            <SaveButton type="submit">Salvar</SaveButton>
          </Content>
        </Form>
      </Card>
    </Container>
  );
}

export default CepDeliveryEdit;
