import React, {useEffect, useState} from 'react';
import { Table } from 'reactstrap';
import {MdArrowDropDown} from 'react-icons/md'
import formatCompleteDate from "../../services/formatCompleteDate";
import {v4} from 'uuid';

import {Container} from './styles'

function BuyersDetailsModal({clients, orderByLastvisit, orderByCount, orderByTotal, orderByPercent}) {
  const [clientModal, setClientModal] = useState(clients)
  const [count, setCount] = useState(0)

  useEffect(() => {
    setClientModal(clients);
  }, [setClientModal, clients, orderByLastvisit])


  function handleOrderByLastVisit(){
    orderByLastvisit();

    const aleatoryId = v4()
    setCount(aleatoryId)
  }

  function handleOrderByOrderCount(){
    orderByCount();

    const aleatoryId = v4()
    setCount(aleatoryId)
  }

  function handleOrderByTotal(){
    orderByTotal();

    const aleatoryId = v4()
    setCount(aleatoryId)
  }

  function handleOrderByPercent(){
    orderByPercent();

    const aleatoryId = v4()
    setCount(aleatoryId)
  }
  

  return (
    <Container>
    <Table>
    <thead>
      <tr>
        <th>Cliente</th>
        <th><button onClick={handleOrderByTotal}>Arrecadado <MdArrowDropDown/> </button> </th>
        <th><button onClick={handleOrderByOrderCount}>Pedidos <MdArrowDropDown/> </button></th>
        <th><button onClick={handleOrderByPercent}>Porcentagem <MdArrowDropDown/> </button></th>
        <th className="text-right"><button onClick={handleOrderByLastVisit}>Última Visita <MdArrowDropDown/> </button></th>
      </tr>
    </thead>
    <tbody>
      {clientModal && clientModal.length && clientModal.map((client) => 
        <tr>
          <td>{client[0]}</td>
          <td>R${parseFloat(client[1]).toFixed(2)}</td>
          <td>{client[2]}</td>
          <td>{client[3]}%</td>
          <td className="text-right">{formatCompleteDate(client[4])}</td>
        </tr>)}
    </tbody>
  </Table>
  </Container>
  );
}

export default BuyersDetailsModal;