import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';

// import { Container } from './styles';

// import { ordersGetRequest } from '~/store/modules/orders/actions';
// import { socketConnectionRequest } from '~/store/modules/socketio/actions';

// import { store } from '~/store';

// import { useSocket } from '~/context/socketioContext';
// import { useOrder } from '~/context/orderContext';

function Startup({ children }) {
  // const { socketConnect } = useSocket();
  // const { getAllOrders } = useOrder();
  // const dispatch = useDispatch();
  // const { profile } = useSelector((state) => state.user);
  // const { signed } = store.getState().auth;

  // useEffect(() => {
  //   if (signed) {
  //     getAllOrders();
  //   }
  // }, [signed]);

  // useEffect(() => {
  //   if (signed) {
  //     socketConnect();
  //   }
  // }, [signed]);
  return <>{children}</>;
}

export default Startup;
