import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';

import {
  Container,
  Table,
  Bill,
  Body,
  Phone,
  Price,
  Items,
  Item,
  ItemName,
  Complements,
  PriceItem,
  NamePrice,
  PriceComplement,
  AlreadyClosed,
  TotalDiv,
  TotalRow,
  TotalName,
  TotalPrice,
} from './styles';

import formatCompleteDate from '~/services/formatCompleteDate';
import formatPhone from '~/services/formatPhoneString';
import api from '~/services/api';

export default function EyeBillsTable({ item }) {
  const [pendingBills, setPendingBills] = useState([]);
  const [closedBills, setClosedBills] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [payments, setPayments] = useState([]);
  const [totalPaid, setTotalPaid] = useState([]);

  useEffect(() => {
    const pending = item.bills.filter((bill) => bill.status !== 'finished');
    setPendingBills(pending);

    const closed = item.bills.filter((bill) => bill.status === 'finished');

    setClosedBills(closed);
  }, [item.bills]);

  useEffect(() => {
    try {
      api
        .get('/restaurants/payments', {
          params: {
            table_session_id: item.id,
          },
        })
        .then((res) => {
          setPayments(res.data.payments);
          setTotalPaid(res.data.total_value);
        });
    } catch (error) {
      toast.error('Erro ao carregar pagamentos');
    }
  }, [item.id]);

  const date = useMemo(() => formatCompleteDate(item.updatedAt), [item]);

  return (
    <Container>
      <Table>
        <strong>MESA {item.table?.table_number}</strong>
      </Table>
      <Bill>Comanda #{item.id}</Bill>
      <Bill>Solicitado {date}</Bill>
      {pendingBills.map((bill) => {
        const userName = bill.buyer
          ? formatPhone(bill.buyer.phone)
          : bill.waiter.name;
        return (
          <>
            <Body key={bill.id}>
              <Phone>
                <strong>{userName}</strong>
              </Phone>
              <Price>
                <strong>R${bill.total_service_price}</strong>
              </Price>
            </Body>
            <Items>
              {bill.order_baskets.map((basket) => (
                <>
                  {basket.orders.map((order) => (
                    <Item key={order.id}>
                      <NamePrice>
                        <ItemName>
                          {order.amount}x {order.product.name}
                        </ItemName>
                        <PriceItem>R${order.total_price}</PriceItem>
                      </NamePrice>
                      {order.complement_categories.map((category) => (
                        <>
                          {category.order_complements.map((complement) => (
                            <div>
                              <NamePrice>
                                <Complements>
                                  - {complement.amount}x{' '}
                                  {complement.complement.name}{' '}
                                </Complements>
                                <PriceComplement>
                                  R${complement.complement.price}
                                </PriceComplement>
                              </NamePrice>
                            </div>
                          ))}
                        </>
                      ))}
                    </Item>
                  ))}
                </>
              ))}
              <Item>
                <NamePrice>
                  <ItemName>Serviços (10%)</ItemName>
                  <PriceItem>
                    R$
                    {(bill.total_service_price - bill.total_price).toFixed(2)}
                  </PriceItem>
                </NamePrice>
              </Item>
            </Items>
          </>
        );
      })}
      <AlreadyClosed>
        <Body>
          <h6>Comandas Individuais Já Fechadas</h6>
          {closedBills.map((bill) => {
            const userName = bill.buyer
              ? formatPhone(bill.buyer.phone)
              : bill.waiter.name;
            return (
              <Item>
                <Phone>
                  <strong>{userName}</strong>
                </Phone>
                <Price>
                  <strong>R${bill.total_service_price}</strong>
                </Price>
              </Item>
            );
          })}
        </Body>
      </AlreadyClosed>
      <TotalDiv>
        <TotalRow>
          <TotalName>
            <strong>TOTAL</strong>
          </TotalName>
          <TotalPrice>
            <strong>R${item.total_service_price}</strong>
          </TotalPrice>
        </TotalRow>

        <TotalRow>
          <TotalName>
            <strong>PAGO</strong>
          </TotalName>
          <TotalPrice>
            <strong>R${totalPaid}</strong>
          </TotalPrice>
        </TotalRow>
        <TotalRow>
          <TotalName>
            <strong>RESTANTE</strong>
          </TotalName>
          <TotalPrice>
            <strong>
              R${(item.total_service_price - totalPaid).toFixed(2)}
            </strong>
          </TotalPrice>
        </TotalRow>
      </TotalDiv>
    </Container>
  );
}
