import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { darken } from 'polished';

export const Container = styled.div`
  margin: 10px;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }

  svg {
    color: ${(props) => props.theme.colors.tertiary};
    margin-right: 10px;
  }
`;

export const ProductCategoriesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CategoryHeader = styled.div`
  margin-left: 50px;
  margin-bottom: 20px;
`;

export const ProductImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin: 10px 5px 10px 0;
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const ButtonContainer = styled.button`
  text-align: center;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.secondary};
  margin: 25px 0 0;
  color: #fff;
  height: 60px;
  width: 200px;
  padding: 0 15px;
  font-weight: bold;
  border: 0;
  border-radius: 8px;
  font-size: 16px;
  transition: background 0.2s;

  span {
    text-align: center;
    text-transform: uppercase;
  }

  &:hover {
    background: ${(props) => darken(0.3, props.theme.colors.secondary)};
  }

  svg {
    margin-right: 6px;
  }
`;
