import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

import { MdDelete, MdEdit } from 'react-icons/md';

import {
  Container,
  Header,
  StyledLink,
  WaitersTable,
  Delete,
  Actions,
} from './styles';

import RegisterButton from '~/components/Buttons/RegisterButton';

import api from '~/services/api';

function Waiter() {
  const [waiters, setWaiters] = useState([]);

  // const [totalWaiters, setTotalWaiters] = useState(0);

  const getWaiters = useCallback(async () => {
    const response = await api.get('/restaurants/waiters');

    setWaiters(response.data);
    // setTotalWaiters(response.data.count);
  }, []);

  useEffect(() => {
    try {
      getWaiters();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getWaiters]);

  async function handleDelete(id) {
    try {
      if (
        window.confirm('Tem certeza que deseja deletar o usuário deste garçom?')
      ) {
        await api.delete(`/restaurants/waiters/${id}`);

        getWaiters();

        toast.success('Usuário de garçom deletado com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar usuário de garçom');
    }
  }

  return (
    <Container>
      <Header>
        <div>
          <h2>Garçons</h2>
          <p>Gerencie os garçons de seu estabelecimento!</p>
        </div>
        <StyledLink to="/waiters/new">
          <RegisterButton>Adicionar</RegisterButton>
        </StyledLink>
      </Header>

      <WaitersTable borderless>
        <thead>
          <tr>
            <th>Número</th>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {waiters.map((waiter) => (
            <tr key={waiter.id}>
              <td>{waiter.id}</td>
              <td>{waiter.name}</td>
              <td>{waiter.email}</td>
              <td>
                <Actions>
                  <StyledLink
                    to={{
                      pathname: 'waiters/edit',
                      state: {
                        waiter,
                      },
                    }}
                  >
                    <MdEdit size={20} />
                  </StyledLink>
                  <Delete onClick={() => handleDelete(waiter.id)}>
                    <MdDelete size={20} />
                  </Delete>
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </WaitersTable>
    </Container>
  );
}

export default Waiter;
