import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import {v4} from 'uuid';

import { Container, Row, Col } from 'reactstrap';
import { Wrapper, Header, Card } from './styles';

import history from '~/services/history';
import api from '~/services/api';

import CheckboxInput from '~/components/Form/Checkbox';
import Input from '~/components/Form/Input';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function CreateComplement({category, getCategories, toggle, setCount }) {
  const formRef = useRef(null);
  const [complementId, setComplementId] = useState('')

  async function handleSubmit(data, { reset }) {
    const formattedPrice = parseFloat(data.price.replace(',', '.')).toFixed(2);

    try {
      const response = await api.post('restaurants/product/complements', {
        name: data.name,
        price: formattedPrice,
        limit: data.limit,
        available: data.available,
      });

      const { id } = response.data

      
      await api.post('restaurants/product/complement-category/add-complement', {
        complements: [id],
        complement_category_id: category.id
      })

      toast.success('Complemento cadastrado com sucesso!');
      reset();
      formRef.current.setData({ available: true });

      const aleatoryId = v4()

      setCount(aleatoryId)


      getCategories();
      toggle();
    } catch (error) {
      toast.error('Falha ao salvar Complemento');
    }
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Cadastrar Complementos</h2>
          <p>Aqui você cadastra o complemento diretamente na categoria escolhida. </p>
          <p>Para facilitar, deixaremos também, o mesmo disponível para ser inserido em outra categoria, posteriormente. </p>
        </div>
        
      </Header>
      <Card>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Nome do complemento"
                />
              </Col>
              <Col sm="12" lg="6">
                <Input
                  label="Preço"
                  name="price"
                  type="text"
                  placeholder="R$xx,xx"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Limite"
                  name="limit"
                  type="number"
                  placeholder="Limite individual"
                />
              </Col>
              <Col sm="12" lg="6">
                <CheckboxInput
                  defaultChecked
                  label="Disponível"
                  name="available"
                />
              </Col>
            </Row>
            <SaveButton type="submit">Cadastrar</SaveButton>
          </Container>
        </Form>
      </Card>
    </Wrapper>
  );
}

export default CreateComplement;
