import React, { useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { MdInsertPhoto } from 'react-icons/md';
import { Container, Row, Col } from 'reactstrap';
import {
  Wrapper,
  Header,
  Card,
  QrcodeContainer,
  DefaultAvatar,
  FormContainer,
} from './styles';

import history from '~/services/history';
import api from '~/services/api';

import Input from '~/components/Form/Input';
import InputMask from '~/components/Form/InputMask';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function CreateTable() {
  const [preview, setPreview] = useState({});
  const [qrcode, setQrcode] = useState('');
  const [saved, setSaved] = useState(false);

  const qrcodeRef = useRef();

  async function handleQrcode() {
    try {
      if (
        preview.url &&
        window.confirm('Tem certeza que deseja gerar um novo QRCode?')
      ) {
        await api.delete(`/qrcode/${preview.id}`);

        if (qrcode.length > 0) {
          const schema = Yup.object().shape({
            qrcode: Yup.string()
              .min(6, 'O código precisa ter 6 dígitos')
              .max(6, 'O código precisa ter 6 dígitos')
              .matches(/^[a-z0-9]+$/i, 'Código só pode ser alfanumérico'),
          });

          await schema.validate(
            { qrcode },
            {
              abortEarly: false,
            }
          );
          const response = await api.post('/qrcode', {
            code: qrcode,
          });

          setPreview(response.data);
        } else {
          const response = await api.post('qrcode');

          setPreview(response.data);
        }
        toast.success('QRCode criado com sucesso!');
      }
      if (!preview.url) {
        if (qrcode.length > 0) {
          const schema = Yup.object().shape({
            qrcode: Yup.string()
              .min(6, 'O código precisa ter 6 dígitos')
              .max(6, 'O código precisa ter 6 dígitos')
              .matches(/^[a-z0-9]+$/i, 'Código só pode ser alfanumérico'),
          });

          await schema.validate(
            { qrcode },
            {
              abortEarly: false,
            }
          );
          const response = await api.post('/qrcode', {
            code: qrcode,
          });

          setPreview(response.data);
        } else {
          const response = await api.post('/qrcode');

          setPreview(response.data);
        }

        toast.success('QRCode criado com sucesso!');
      }
      setSaved(false);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        // Validation failed
        toast.error(`Erro ao gerar QRCode! ${error}`);
      } else {
        toast.error(
          `Erro ao gerar novo QRCode, favor verificar seus dados e tentar novamente `
        );
        // toast.error(`Erro ao gerar novo QRCode ${error.message}`);
      }
    }
  }

  async function handleSubmit(data) {
    try {
      await api.post('restaurants/tables', {
        table_number: Number(data.table_number),
        table_code_id: preview.id,
      });

      setSaved(true);
      toast.success('Mesa criada com sucesso!');
      history.push('/tables');
    } catch (error) {
      toast.error('Falha ao criar mesa');
    }
  }
  useEffect(() => {
    qrcodeRef.current = preview;
    return async () => {
      if (!saved && preview.url) {
        const deletedQrcode = qrcodeRef.current;
        await api.delete(`/qrcode/${deletedQrcode.id}`);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Cadastrar Mesas</h2>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Card>
        <Form onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col sm="12">
                <QrcodeContainer>
                  {preview.url ? (
                    <img src={preview.url} alt="QR code da mesa" />
                  ) : (
                    <DefaultAvatar>
                      <MdInsertPhoto size={45} color="#ddd" />
                    </DefaultAvatar>
                  )}
                  <h4>Código atual da mesa</h4>
                  <span>{preview.key ? preview.key : 'Nenhum código'}</span>
                  <FormContainer>
                    <InputMask
                      mask="******"
                      maskChar=""
                      maskPlaceholder="______"
                      label="Novo código da mesa"
                      name="qrcode_key"
                      type="text"
                      value={qrcode}
                      onChange={(e) => {
                        setQrcode(e.target.value);
                      }}
                      style={{ textTransform: 'lowercase' }}
                    />
                    <p>
                      OBS: São aceitos apenas letras de a-z minúsculas e
                      números.
                    </p>
                    <span>
                      Caso queira um código aleatório, apenas clique em Gerar
                      QRCode
                    </span>
                    <button type="button" onClick={handleQrcode}>
                      Gerar QRCode
                    </button>
                  </FormContainer>
                </QrcodeContainer>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Input
                  label="Número da mesa"
                  name="table_number"
                  type="number"
                  placeholder="Número positivo maior que 0"
                />
              </Col>
            </Row>
            <SaveButton type="submit">Cadastrar</SaveButton>
          </Container>
        </Form>
      </Card>
    </Wrapper>
  );
}

export default CreateTable;
