import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { format, startOfDay, endOfDay, subHours, startOfWeek, startOfMonth, addHours } from 'date-fns';
import { Form } from "@unform/web";
import { MdArrowDropDown } from 'react-icons/md';
import {
  Row,
  Col,
  Collapse,
} from "reactstrap";
import { Input } from '@material-ui/core';

import {
  Card,
  Container,
  Header,
  ReportHeader,
  DateDiv,
  DateSelectorDiv,
  HeaderCards,
  SubmitDate,
  SelectDateButton,
} from './styles';
import history from '~/services/history';
import ReportCard from '~/components/Reports/ReportCard';
import ReportCardRate from '~/components/Reports/ReportCardRate';

import BackButton from '~/components/Buttons/BackButton';
import GoodCard from '~/components/Rate/GoodCard';
import BadCard from '~/components/Rate/BadCard';

import api from '~/services/api';

function ReportsRate() {
  const formRef = useRef(null);

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  let inicialFormat = format(new Date(defaultInicial), "dd/MM/yyyy, HH:mm");
  let finalFormat = format(new Date(defaultFinal), "dd/MM/yyyy, HH:mm");
  let phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  // const [inicialDateSearch, setInicialDateSearch] = useState(format(addHours(new Date(inicialDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));
  // const [finalDateSearch, setFinalDateSearch] = useState(format(addHours(new Date(finalDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));

  // useEffect(() => {
  //   setInicialDateSearch(format(addHours(new Date(inicialDate), 6), "yyyy-MM-dd'T'HH:mm:ss"));
  //   setFinalDateSearch(format(addHours(new Date(finalDate), 6), "yyyy-MM-dd'T'HH:mm:ss"));  
  // }, [inicialDate, finalDate]);

  function setToday(){
    setInicialDate(format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfDay(subHours(new Date(), 27)), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek(){
    setInicialDate(format(startOfWeek(subHours(new Date(), 3), {weekStartsOn: 1}), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfWeek(subHours(new Date(), 3), {weekStartsOn: 1}), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth(){
    setInicialDate(format(startOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfMonth(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  let [initialPre, setInitialPre] = useState(inicialDate);
  let [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
    let inicialFormat = format(new Date(initialPre), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(new Date(finalPre), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    
    setHourPhrase(phrase);
  }

  const [buyers, setBuyers] = useState(0);
  const [ratings, setRatings] = useState([]);

  const getRatings = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/ratings', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      setRatings(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  const getBuyers = useCallback(async () => {
    try {
      const response = await api.get('restaurants/reports/buyers', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      setBuyers(response.data.total_buyers);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  useEffect(() => {
    getRatings();
    getBuyers();
  }, [getBuyers, getRatings]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const rate = ratings.ratings_average ? ratings.ratings_average.toFixed(2) : 0;
  const percentRating = ((ratings.ratings_count * 100) / buyers).toFixed(0);
  const comment = `${
    isNaN(percentRating) ? 0 : percentRating
  }% dos clientes avaliaram`;

  return (
    <Container>
      <Header>
        <div>
          <h2>Relatório de Avaliações</h2>
          <p>Acompanhe as avaliações recebidas pelo seu estabelecimento!</p>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Card>
      <Row>
            <Col md="4">
              <p style={{paddingLeft: 10}}>{hourPhrase}</p>
            </Col>
            <Col md="8">
              <div style={{ float: "right" }}>
                <SelectDateButton className="btn-round mr-auto" onClick={setToday} color="info">
                  Hoje
                </SelectDateButton>
                <SelectDateButton className="btn-round mr-auto" onClick={setWeek} color="info">
                  Essa semana
                </SelectDateButton>
                <SelectDateButton className="btn-round mr-auto" onClick={setMonth} color="info">
                  Esse mês
                </SelectDateButton>
                <SelectDateButton className="btn-round mr-auto" onClick={toggle} color="info">
                  Selecionar Período
                  <MdArrowDropDown color="white" size={20} />
                </SelectDateButton>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div style={{ float: "right", marginRight: "15px" }}>
                <Collapse isOpen={isOpen}>
                  <Form onSubmit={handleChangeDate} ref={formRef}>
                    <DateSelectorDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Inicial"
                          type="datetime-local"
                          name="initialDate"
                          onChange={(e) => setInitialPre(e.target.value)}
                          defaultValue={defaultInicial}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Final"
                          type="datetime-local"
                          name="finalDate"
                          onChange={(e) => setFinalPre(e.target.value)}
                          defaultValue={defaultFinal}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <div>
                        <SubmitDate onClick="submit">Filtrar</SubmitDate>
                      </div>
                    </DateSelectorDiv>
                  </Form>
                </Collapse>
              </div>
            </Col>
          </Row>
        <ReportHeader>
          <HeaderCards>
            <ReportCardRate
              name="Média de Avaliações"
              type="rate"
              color="#ffb400"
              rate={rate}
            />
          </HeaderCards>
        </ReportHeader>
        <ReportHeader>
          <HeaderCards>
            <ReportCard
              name="Número de Avaliações"
              total={ratings.ratings_count}
              type="rateCount"
              color="#f97c22"
              comment={comment}
            />
            <ReportCard
              name="Clientes"
              total={buyers}
              type="clients"
              color="blue"
            />
          </HeaderCards>
        </ReportHeader>
        <ReportHeader>
          <HeaderCards>
            <GoodCard ratings={ratings} />
            <BadCard ratings={ratings} />
          </HeaderCards>
        </ReportHeader>
      </Card>
    </Container>
  );
}

export default ReportsRate;
