import React, { useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import {v4} from 'uuid'

import { Container, Row, Col } from 'reactstrap';
import { Wrapper, Header, Card, Head, CheckboxInfo } from './styles';

import history from '~/services/history';
import api from '~/services/api';

import Select from '~/components/Form/SelectInput';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function AssociateComplement({category, toggle, getCategories, setCount}) {
  const [complements, setComplements] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const complementsInitial = category.complements.map((complement) => ({
    value: complement.id,
    label: complement.name,
  }));
 

  // const getComplementsByCategoryId = useCallback(async () => {
  //   const response = await api.get(`/restaurants/product/complement-category/${category.id}`);

  //   const data = response.data.complements.map((complement) => ({
  //     value: complement.id,
  //     label: complement.name,
  //   }));

  //   setComplementsCategory(data);
  // }, []);
  

  const getComplements = useCallback(async () => {
    const response = await api.get('/restaurants/product/complements');

    const data = response.data.map((complement) => ({
      value: complement.id,
      label: complement.name,
    }));

    setComplements(data);
  }, []);

  useEffect(() => {
    try {
      getComplements();
      
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getComplements]);

  // useEffect(() => {
  //   getComplementsByCategoryId();
  // }, [getComplementsByCategoryId])

  async function handleSubmit(data) {
    try {
      const response = await api.put(
        `restaurants/product/complement-category/${category.id}`,
        {          
          complements: data.complements,
         
        }
      ); 

      const aleatoryId = v4()

      setCount(aleatoryId)

      getCategories()
      setSelectedCategory(response.data.id);
      toggle()
      // getCategories()

      // window.location.reload()
      

      await toast.success('Categoria cadastrada com sucesso!');
     
     
    } catch (error) {
      if (selectedCategory) {
        await api.delete(
          `/restaurants/product/complement-category/${selectedCategory}`
        );
      }
      toast.error('Falha ao salvar categoria');
   
    }
  }

  return (
    <Wrapper>

      <Header>
        <div>
          <h2>Associar complementos</h2>
          <p>Associe complementos existentes na categoria escolhida.</p>
        </div>
        {/* <BackButton onClick={() => history.goBack()}>Voltar</BackButton> */}
      </Header>
      <Card>
        <Form onSubmit={handleSubmit}>
          <Container>           
               
            
            <Select
              name="complements"
              isMulti
              options={complements}
              label="Selecione os complementos"
              defaultValue={complementsInitial}
            />
            <SaveButton type="submit">Salvar</SaveButton>
          </Container>
        </Form>
      </Card>
    </Wrapper>
  );
}

export default AssociateComplement;