import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  height: auto;
`;

export const Table = styled.div`
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 25px;
  margin-right: auto;
`;

export const Order = styled.div`
  padding-top: 5px;
  width: 110mm;
  text-align: center;
  font-size: 15px;
  margin-right: auto;
  padding-bottom: 15px;
  border-bottom: solid black 1px;
`;

export const Restaurant = styled.div`
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 20px;
  margin-right: auto;
  padding-bottom: 15px;
  border-bottom: solid black 1px;
`;

export const Body = styled.div`
  width: 110mm;
  clear: both;
`;

export const Phone = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: 15px;
  padding-top: 10px;
  float: left;
`;

export const Time = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 15px;
  padding-top: 10px;
  float: right;
`;

export const Price = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 15px;
  float: right;
`;

export const Items = styled.div`
  width: 110mm;
  padding-top: 20px;
  style: block;
`;

export const Item = styled.div`
  padding-top: 15px;
  padding-left: 10px;
`;

export const ItemName = styled.div`
  font-size: 15px;
`;

export const ComplementsTitle = styled.div`
  font-size: 13px;
`;

export const Complements = styled.div`
  font-size: 12px;
  padding-left: 15px;
`;

export const ComplementsPrice = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 12px;
  float: right;
`;

export const Obs = styled.div`
  font-size: 13px;
`;


export const TotalDiv = styled.div`
  padding-top: 5px;
  margin-top: 20px;
  border-top: solid black 1px;
  padding-bottom: 5px;
  font-size: 20px;
  text-align: center;
  width: 110mm;
  padding-bottom: 15px;
`;

export const TotalRow = styled.div`
  margin-top: 5px;
  width: 110mm;
  clear: both;
  display: block;
`;

export const TotalName = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: 20px;
  padding-top: 5px;
  float: left;
`;

export const TotalPrice = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 20px;
  padding-top: 5px;
  float: right;
`;