import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { TiStarburstOutline, TiCancel } from 'react-icons/ti';
import { MdDone } from 'react-icons/md';
import { Wrapper, Header, LugarPedidos, BtnWhatsapp } from './styles';

import LogoWhatsapp from '~/assets/img/icone_whats.png';

import { useOrder } from '~/context/orderContext';
import { useAuth } from '~/context/AuthContext';

import history from '~/services/history';
import api from '~/services/api';

import UpdateButton from '~/components/Buttons/UpdateButton';
import BackButton from '~/components/Buttons/BackButton';
import CardOrdersAndamento from '~/components/Orders/Cards/CardOrdersAndamento';
import CardOrdersNew from '~/components/Orders/Cards/CardOrdersNew';
import CardOrdersCanceled from '~/components/Orders/Cards/CardOrdersCanceled';
import CardOrdersFinished from '~/components/Orders/Cards/CardOrdersFinished';
import CardOrdersDone from '~/components/Orders/Cards/CardOrdersDone';
import CardOrdersHistory from '~/components/Orders/Cards/CardOrdersHistory';

function Orders() {
  const {
    pendingBaskets,
    acceptedBaskets,
    finishedBaskets,
    canceledBaskets,
    doneBaskets,
    loading,
    getAllOrders,
  } = useOrder();

  const { user } = useAuth();

  const [activeTab, setActiveTab] = useState('1');

  async function handlePrintItem(id, userName, item) {
    try {
      await api.put(`restaurants/orders/${id}`, {
        order_status: 'accepted',
      });
      // dispatch(ordersGetRequest());

      const products = item.orders.map((product) => {
        return `%0a${product.amount}x - ${product.product.name} - R$ ${product.total_price}`;
      });

      const userAdress =
        `${item.delivery_address?.street || ''} ${
          item.delivery_address?.number || ''
        } - ${item.delivery_address?.city || ''} - ${
          item.delivery_address?.state || ''
        } - ${item.delivery_address?.zip_code || ''}` || '';

      const userPaymentMethod = item.payment_method || '';

      if (user.notify_whatsapp) {
        window.open(
          `https://api.whatsapp.com/send?phone=${userName}&text="Olá! Aqui é do ${user.fantasy_name}. O seu pedido já foi aceito e está em preparo!%0a%0a*Itens:*${products}%0a%0aTotal com taxa (se houver): R$${item.total_price}%0a%0a*Endereço:*%0a${userAdress}%0a%0a*Pagamento:*%0a${userPaymentMethod}"`
        );
      }

      getAllOrders();
      toast.success('Pedido aceito!');
    } catch (err) {
      toast.error('Não foi possível atualizar o pedido! tente novamente');
      console.log(err);
    }
  }

  async function handleUpdate() {
    getAllOrders();
  }

  async function handleItemFinished(id) {
    try {
      await api.put(`restaurants/orders/${id}`, {
        order_status: 'finished',
      });
      // dispatch(ordersGetRequest());
      getAllOrders();
      toast.success('Pedido entregue!');
    } catch (err) {
      toast.error('Não foi possível atualizar o pedido! Tente novamente');
    }
  }

  async function handleItemDone(id) {
    try {
      await api.put(`restaurants/orders/${id}`, {
        order_status: 'done',
      });
      // dispatch(ordersGetRequest());
      getAllOrders();
      toast.success('Pedido pronto!');
    } catch (err) {
      toast.error('Não foi possível atualizar o pedido! tente novamente');
    }
  }

  async function handleCancelItem(id) {
    try {
      await api.put(`restaurants/orders/cancel/${id}`);
      // dispatch(ordersGetRequest());
      getAllOrders();
      toast.success('Pedido cancelado!');
    } catch (err) {
      toast.error('Não foi possível atualizar o pedido! tente novamente');
    }
  }

  function handleOpenWhatspp() {
    window.open(
      'https://api.whatsapp.com/send?phone=%22+5527996549956%22&text=%22*Preciso%20de%20ajuda%20no%20sistema*%22'
    );
  }

  return (
    <Wrapper style={{}}>
      <Header>
        <div>
          <h2>Acompanhar Pedidos</h2>
          <UpdateButton onClick={handleUpdate} />
        </div>
        {/* <BackButton onClick={() => history.goBack()}>Voltar</BackButton> */}
      </Header>

      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === '1' ? 'active' : ''}
            onClick={() => setActiveTab('1')}
          >
            <span>
              <TiStarburstOutline color="grey" size={20} />{' '}
            </span>
            Acompanhar Pedidos
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={activeTab === '2' ? 'active' : ''}
            onClick={() => setActiveTab('2')}
          >
            <span>
              <MdDone color="grey" size={20} />{' '}
            </span>
            Pedidos Entregues
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={activeTab === '3' ? 'active' : ''}
            onClick={() => setActiveTab('3')}
          >
            <span>
              <TiCancel color="grey" size={20} />{' '}
            </span>
            Pedidos Cancelados
          </NavLink>
        </NavItem>

        {/* <NavItem>
          <NavLink
            className={activeTab === '4' ? 'active' : ''}
            onClick={() => setActiveTab('4')}
          >
            <span>
              <TiCancel color="grey" size={20} />{' '}
            </span>
            Histórico de Pedidos
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <LugarPedidos>
            <CardOrdersNew
              handlePrintItem={handlePrintItem}
              handleCancelItem={handleCancelItem}
              baskets={pendingBaskets}
              loading={loading}
            />
            <CardOrdersAndamento
              baskets={acceptedBaskets}
              handleItemDone={handleItemDone}
              handleCancelItem={handleCancelItem}
              loading={loading}
            />
            <CardOrdersDone
              baskets={doneBaskets}
              handleItemFinished={handleItemFinished}
              handleCancelItem={handleCancelItem}
              loading={loading}
            />
          </LugarPedidos>
        </TabPane>

        <TabPane tabId="2">
          <LugarPedidos>
            <CardOrdersFinished
              baskets={finishedBaskets}
              loading={loading}
              handleUpdate={handleUpdate}
            />
          </LugarPedidos>
        </TabPane>

        <TabPane tabId="3">
          <LugarPedidos>
            <CardOrdersCanceled baskets={canceledBaskets} loading={loading} />
          </LugarPedidos>
        </TabPane>

        <TabPane tabId="4">
          <LugarPedidos>
            <CardOrdersHistory baskets={canceledBaskets} loading={loading} />
          </LugarPedidos>
        </TabPane>
      </TabContent>
      <BtnWhatsapp onClick={handleOpenWhatspp}>
        <div>Fale conosco</div>
        <img src={LogoWhatsapp} alt="Logo Whatsapp" />
      </BtnWhatsapp>
    </Wrapper>
  );
}

export default Orders;
