import styled from 'styled-components';

export const Header = styled.div`
  background-color: #f0b402;
  padding: 20px 0px;
  justify-content: center;
  color: white;
  font-size: 20px;
  height: 70px;
  text-align: center;
`;

export const Bloco = styled.div`
  margin: 20px auto;
  width: 32%;
  min-width: 200px;
  height: 500px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  margin-left: 20px auto;
  background: white;
  border-radius: 8px;
  border: solid 2px #f0b402;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
`;
