import React, { useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  FormGroup,
  FormText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import logoPaytime from 'assets/img/paytime.png';
import logoPicpay from 'assets/img/picpay-logo.png';
import logoWhatsapp from 'assets/img/wpp.png';

import {
  Wrapper,
  Header,
  Card,
  DivButton,
  Content,
  NavDiv,
  Logo,
  LinkPicpay,
  LinkPaytime,
  LogoPaytime,
  LogoWpp,
  CheckboxInfo,
} from './styles';

import api from '~/services/api';
import history from '~/services/history';

import AvatarInput from '~/components/Form/AvatarInput';
import BGInput from '~/components/Form/BGInput';
import Input from '~/components/Form/Input';
import CheckboxInput from '~/components/Form/Checkbox';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';
import ChangePasswordButton from '~/components/Buttons/ChangePasswordButton';
import OpeningHours from '~/components/OpeningHours'
import PaymentMethod from '~/pages/PaymentMethods';
import { useAuth } from '~/context/AuthContext';

function Profile() {
  const { user, updateUser, updateToken, updateSMSInfo } = useAuth();

  const [oldAvatarId, setOldAvatarId] = useState(0);
  const [oldCoverId, setOldCoverId] = useState(0);
  const [activeTab, setActiveTab] = useState('1');
  // eslint-disable-next-line no-unused-vars
  const [initialData, setInitialData] = useState(() => {
    if (user) {
      const parsedData = {
        ...user,
        ...(user.picpay_token && {
          x_picpay_token: user.picpay_token.x_picpay_token,
          x_seller_token: user.picpay_token.x_seller_token,
        }),
      };
      return parsedData;
    }
    return {};
  });

  console.log(user);
  // const initialData = {
  //   username: user.username,
  //   fantasy_name: user.fantasy_name,
  //   email: user.email,
  //   phone: user.phone,
  //   has_sms_service: user.has_sms_service,
  //   is_sms_service_optional: user.is_sms_service_optional,
  //   client_pay_sms: user.client_pay_sms,
  //   x_picpay_token: user.picpay_token ? user.picpay_token.x_picpay_token : '',
  //   x_seller_token: user.picpay_token ? user.picpay_token.x_seller_token : '',
  //   paytime_seller_id: user.paytime_seller_id,
  //   location: user.location,
  //   whatsapp: user.whatsapp,

  //   avatar: {
  //     id: user.avatar ? user.avatar.id : null,
  //     url: user.avatar ? user.avatar.url : null,
  //   },

  //   cover: {
  //     id: user.cover ? user.cover.id : null,
  //     url: user.cover ? user.cover.url : null,
  //   },
  // };

  // modal de mudar senha
  const [modal, setModal] = useState(false);
  const [smsService, setSmsService] = useState(initialData.has_sms_service);
  const [isNotifyWhatsapp, setIsNotifyWhatsapp] = useState(initialData.notify_whatsapp)
  const [passPanel, setPassPanel] = useState(
    initialData.is_sms_service_optional
  );
  const [wppCheck, setWppCheck] = useState(initialData.whatsapp === '');
  const [takeOff, setTakeOff] = useState(initialData.withdrawal_allowed === '');
  const checked = true;

  const toggle = () => setModal(!modal);

  function changeSmsService() {
    smsService ? setSmsService(false) : setSmsService(true);
  }

  function changeNotifyWhatsapp(){
    setIsNotifyWhatsapp(!isNotifyWhatsapp)
  }

  function changeWpp() {
    setWppCheck(!wppCheck);
  }

  function changeTakeOff() {
    setTakeOff(!takeOff);
  }

  function changePassPanel() {
    passPanel ? setPassPanel(false) : setPassPanel(true);
    passPanel && setSmsService(true);
  }

  // submit do profile
  async function handleSubmitProfile(data) {
    try {
      const updateProfile = {
        name: data.name,
        fantasy_name: data.fantasy_name,
        email: data.email,
        phone: data.phone,
        location: data.location,
        avatar_id: data.avatar,
        cover_id: data.cover,
       
      };

      updateUser(updateProfile);

      if (oldAvatarId !== null && oldAvatarId !== Number(data.avatar)) {
        await api.delete(`restaurants/files/${oldAvatarId}`);
      }

      if (oldCoverId !== null && oldCoverId !== Number(data.cover)) {
        await api.delete(`restaurants/files/${oldCoverId}`);
      }

      toast.success('Perfil do restaurante atualizado com sucesso!');
    } catch (err) {
      toast.error('Falha ao atualizar o perfil do restaurante.');
    }
  }

  async function handleSubmitSMS(data) {
    let updateSMS = {};

    if (data.whatsapp === '' && wppCheck === false) {
      updateSMS = {
        is_sms_service_optional: data.is_sms_service_optional,
        has_sms_service: data.has_sms_service,
        client_pay_sms: data.client_pay_sms,
      };
    } else if (wppCheck) {
      updateSMS = {
        is_sms_service_optional: data.is_sms_service_optional,
        has_sms_service: data.has_sms_service,
        client_pay_sms: data.client_pay_sms,
        whatsapp: '',
      };
    } else {
      updateSMS = {
        is_sms_service_optional: data.is_sms_service_optional,
        has_sms_service: data.has_sms_service,
        client_pay_sms: data.client_pay_sms,
        whatsapp: data.whatsapp,
      };
    }

    updateSMSInfo(updateSMS);

    try { 
      const updateUserProfile = {
        notify_whatsapp: data.is_notify_whatsapp
      }

      updateUser(updateUserProfile);
    } catch(error) {
      toast.error('Falha ao atualizar o perfil do restaurante.');
    }
  }

  async function handleSubmitTokens(data) {
    const updateTokens = {
      x_picpay_token: data.x_picpay_token,
      x_seller_token: data.x_seller_token,
      paytime_seller_id: data.paytime_seller_id,
      withdrawal_allowed: data.withdrawal_allowed,
    };

    updateToken(updateTokens);
  }

  // submit de mudar senha
  async function handleSubmitChangePassword(data) {
    try {
      await api.put('restaurants/', {
        email: data.email,
        password: data.password,
        oldPassword: data.oldPassword,
        confirmPassword: data.confirmPassword,
      });
      toast.success('Senha atualizada com sucesso!');
    } catch (err) {
      toast.error('Falha ao alterar senha.');
    }
  }

  useEffect(() => {
    setOldAvatarId(user.avatar ? user.avatar.id : null);
    setOldCoverId(user.cover ? user.cover.id : null);
  }, [user]);

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Perfil do Restaurante</h2>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>

      <NavDiv>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === '1' ? 'active' : ''}
              onClick={() => setActiveTab('1')}
            >
              Perfil
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={activeTab === '2' ? 'active' : ''}
              onClick={() => setActiveTab('2')}
            >
              Horário de atendimento
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={activeTab === '3' ? 'active' : ''}
              onClick={() => setActiveTab('3')}
            >
              Configurações Avançadas
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={activeTab === '4' ? 'active' : ''}
              onClick={() => setActiveTab('4')}
            >
              Configurações de Pagamento
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '5' ? 'active' : ''}
              onClick={() => setActiveTab('5')}
            >
              Métodos de Pagamento
            </NavLink>
          </NavItem>
        </Nav>
      </NavDiv>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Card>
            <Form initialData={initialData} onSubmit={handleSubmitProfile}>
              <Content>
                <Row>
                  {user.is_priority && user.is_single_restaurant === false ? (
                    <>
                      <Col sm="3">
                        <AvatarInput name="avatar" />
                      </Col>
                      <Col sm="9">
                        <BGInput name="cover" />
                      </Col>
                    </>
                  ) : (
                    <Col sm="12">
                      <div style={{ textAlign: 'center' }}>
                        <AvatarInput name="avatar" />
                      </div>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col lg="6">
                    <Input
                      label="Nome restaurante"
                      name="username"
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      label="Nome de exibição"
                      name="fantasy_name"
                      type="text"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <Input label="E-mail" name="email" type="text" />
                  </Col>

                  <Col lg="6">
                    <InputMask mask="(99) 99999-9999">
                      <Input
                        label="Telefone"
                        name="phone"
                        type="text"
                        maskChar=""
                        placeholder="(xx) xxxxx-xxxx"
                      />
                    </InputMask>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <Input
                      label="Localização"
                      name="location"
                      type="text"
                      placeholder="Ex: Praça 1, 2º Piso"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="3" className="ml-auto">
                    <DivButton>
                      <ChangePasswordButton onClick={toggle} />
                    </DivButton>
                  </Col>
                </Row>
                <Row>
                  <Col lg="2" className="ml-auto">
                    <SaveButton type="submit" />
                  </Col>
                </Row>
              </Content>
            </Form>
          </Card>

          <Modal isOpen={modal} toggle={toggle}>
            <Form
              initialData={initialData}
              onSubmit={handleSubmitChangePassword}
            >
              <ModalHeader toggle={toggle}>Alterar Senha</ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col lg="6">
                      <Input label="E-mail" name="email" type="text" />
                    </Col>
                    <Col lg="6">
                      <Input
                        label="Senha atual"
                        name="oldPassword"
                        type="Password"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <Input
                        label="Nova Senha"
                        name="password"
                        type="password"
                      />
                    </Col>
                    <Col lg="6">
                      <Input
                        label="Confirmar Nova Senha"
                        name="confirmPassword"
                        type="Password"
                      />
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" type="submit" onClick={toggle}>
                  Salvar
                </Button>
                <Button color="danger" onClick={toggle}>
                  Cancelar
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        </TabPane>

        <TabPane tabId="2">
          <Row>
            <Col md="12">
              <Card>
                <OpeningHours />
              </Card>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="3">
          <Card>
            <Form initialData={initialData} onSubmit={handleSubmitSMS}>
              <Row>
                <LogoWpp>
                  <img
                    src={logoWhatsapp}
                    style={{ width: 141, height: 44 }}
                    alt="react-logo"
                  />
                </LogoWpp>
              </Row>
              <Row>
                <Col sm="12" style={{ textAlign: 'left' }}>
                  <FormText color="default" tag="span">
                    Se o cliente quiser falar com o restaurante, usamos o
                    WhatsApp para fazer essa conexão. Insira um número que tenha
                    WhatsApp para o cliente conseguir se comunicar com o
                    estabelecimento.
                  </FormText>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  {wppCheck ? (
                    <Input
                      name="whatsapp"
                      type="text"
                      maskChar=""
                      placeholder="+55 (xx) xxxxx-xxxx"
                      disabled
                    />
                  ) : (
                    <InputMask mask="+55 (99) 99999-9999">
                      <Input
                        name="whatsapp"
                        type="text"
                        maskChar=""
                        placeholder="+55 (xx) xxxxx-xxxx"
                      />
                    </InputMask>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  {wppCheck ? (
                    <CheckboxInput
                      label="Desativar serviço de WhatsApp"
                      checked={wppCheck}
                      name="wpp_cb"
                      onChange={changeWpp}
                    />
                  ) : (
                    <CheckboxInput
                      label="Desativar serviço de WhatsApp"
                      name="wpp_cb"
                      onChange={changeWpp}
                    />
                  )}
                </Col>
              </Row>
              <Row>
              <h4
                  style={{ paddingLeft: 10, paddingBottom: 15, paddingTop: 30 }}
                >
                  Aviso de aceite ao cliente
                </h4>

                <Col md="12">
                  <p style={{ textAlign: 'left' }}>
                    O aviso de aceite, caso ativado, permite o sistema de abrir a página do whatsapp web, assim que o pedido for aceito, diretamente com o numero do cliente, para que o restaurante possa avisá-lo.
                  </p>
                </Col>

     
              </Row>
              <Row>
                    <Col sm="12" lg="12">
                      <CheckboxInput
                        label="Ativar aviso ao cliente"
                        name="is_notify_whatsapp"
                        checked={isNotifyWhatsapp}
                        onChange={changeNotifyWhatsapp}
                      />
                    </Col>
                  </Row>
              <Row>
                <h4
                  style={{ paddingLeft: 10, paddingBottom: 15, paddingTop: 30 }}
                >
                  SMS
                </h4>
                <Col md="12">
                  <p style={{ textAlign: 'left' }}>
                    O serviço de SMS é a opção do seu estabelecimento enviar uma
                    SMS para o celular do cliente assim que o pedido fica
                    pronto. O serviço pode ser pago pelo seu estabelecimento ou
                    pelo cliente.{' '}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm="12" lg="6">
                  <Row>
                    <Col sm="12" lg="12">
                      <CheckboxInput
                        label="Painel de Senha Eletrônica"
                        name="is_sms_service_optional"
                        value
                        onChange={changePassPanel}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" lg="6">
                      {passPanel ? (
                        <CheckboxInput
                          label="Disponível"
                          name="has_sms_service"
                          value={smsService}
                          onChange={changeSmsService}
                        />
                      ) : (
                        <CheckboxInput
                          label="Disponível"
                          name="has_sms_service"
                          checked={checked}
                          disabled
                        />
                      )}
                    </Col>
                  </Row>
                  {smsService ? (
                    <div>
                      <FormGroup tag="fieldset">
                        <Row>
                          <Col sm="12" lg="6">
                            <CheckboxInput
                              label="Pago pelo Cliente"
                              name="client_pay_sms"
                              value
                            />
                          </Col>
                        </Row>
                      </FormGroup>{' '}
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col sm="12" lg="6">
                  <CheckboxInfo>
                    <h4> Legenda</h4>
                    <div>
                      <span>Painel de Senha Eletrônico</span>
                      <p>
                        Se o seu estabelecimento tiver painel de senha
                        eletrônico, marque a opção ao lado. Se sim, o cliente
                        escolhe se quer receber SMS, se não, o serviço de SMS é
                        obrigatório.
                      </p>
                    </div>
                    <div>
                      <span>Disponível</span>
                      <p>Seu estabelecimento oferece a opção de SMS.</p>
                    </div>
                    <div>
                      <span>Pago pelo Cliente</span>
                      <p>
                        Se a opção escolhida for de pagamento pelo cliente, o
                        total do pedido do cliente será acrescido de R$0,10. Se
                        não, o serviço será pago pelo estabelecimento, e será
                        cobrado R$0,10 a cada pedido.
                      </p>
                    </div>
                  </CheckboxInfo>
                </Col>
              </Row>
              <Row>
                <Col md="2" className="ml-auto">
                  <SaveButton type="submit" />
                </Col>
              </Row>
            </Form>
          </Card>
        </TabPane>

        <TabPane tabId="4">
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Form initialData={initialData} onSubmit={handleSubmitTokens}>
                    <Row>
                      <h4
                        style={{
                          paddingLeft: 10,
                          paddingBottom: 15,
                          paddingTop: 5,
                        }}
                      >
                        Pagar na Retirada
                      </h4>
                      <Col md="12">
                        <p style={{ textAlign: 'left' }}>
                          Se o seu estabelecimento aceita pagar pelo pedido na
                          retirada, marque a opção a baixo. A Takeat não se
                          responsabiliza com o não comparecimento do cliente
                          nesta modalidade de pagamento.{' '}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        {takeOff ? (
                          <CheckboxInput
                            label="Aceita Pagar na Retirada"
                            checked
                            name="withdrawal_allowed"
                            onChange={changeTakeOff}
                          />
                        ) : (
                          <CheckboxInput
                            label="Aceita Pagar na Retirada"
                            name="withdrawal_allowed"
                            onChange={changeTakeOff}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <LogoPaytime
                        style={{
                          paddingTop: 20,
                        }}
                      >
                        <img
                          src={logoPaytime}
                          style={{ width: 200, height: 44 }}
                          alt="react-logo"
                        />
                      </LogoPaytime>
                    </Row>
                    <Row>
                      <Col sm="12" style={{ textAlign: 'left' }}>
                        <p>
                          Para receber os pagamentos feitos por cartão de
                          crédito, temos parceria com a empresa de pagamentos
                          digitais Paytime, e para receber os pagamentos feitos
                          por essa plataforma, o estabelecimento deve possuir
                          cadastro na plataforma da Paytime. O SELLER ID será
                          fornecido pela Paytime após a validação do cadastro do
                          estabelecimento.
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: 15 }}>
                      <Col sm="12">
                        <Input
                          label="Código"
                          type="text"
                          name="paytime_seller_id"
                        />
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: 15 }}>
                      <Col sm="8">
                        <LinkPaytime
                          href="https://takeat.paytime.com.br"
                          target="_blank"
                        >
                          Clique aqui para realizar o cadastro na Paytime
                        </LinkPaytime>
                      </Col>
                    </Row>

                    <Row>
                      <Logo>
                        <img
                          src={logoPicpay}
                          style={{ width: 150, height: 49 }}
                          alt="react-logo"
                        />
                      </Logo>
                    </Row>
                    <Row>
                      <Col sm="12" style={{ textAlign: 'left' }}>
                        <p>
                          Para receber os pagamentos feitos pelo PicPay, o
                          estabelecimento deve possuir cadastro no PicPay
                          e-commerce. Ambos os tokens serão fornecidos pelo
                          PicPay após a validação do cadastro do
                          estabelecimento.
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: 15 }}>
                      <Col sm="12">
                        <Input
                          label="x-picpay-token"
                          type="text"
                          name="x_picpay_token"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Input
                          label="x-seller-token"
                          type="text"
                          name="x_seller_token"
                        />
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: 15 }}>
                      <Col sm="8">
                        <LinkPicpay
                          href="https://ecommerce.picpay.com/register/"
                          color="success"
                          target="_blank"
                        >
                          Clique aqui para realizar o cadastro no Picpay
                        </LinkPicpay>
                      </Col>
                      <Col md="2" className="ml-auto">
                        <SaveButton type="submit" />
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="5">
          <Row>
            <Col md="12">
              <Card>
                <PaymentMethod />
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </Wrapper>
  );
}

export default Profile;
