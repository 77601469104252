import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import produce from 'immer';

import { v4 } from 'uuid';

import { Modal, ModalBody } from 'reactstrap';
import OrderProductCategoryContext from './context';
import api from '~/services/api';

import ProductCategoryHeader from '~/components/Products/ProductCategoryHeader';
import CategoryItem from '~/components/Products/CategoryItem';

import CreateProduct from '../Create';
import EditCategory from './Edit';

import { useAuth } from '~/context/AuthContext';

import { Container } from './styles';

function ProductCategory() {
  const { user } = useAuth();
  const [categories, setCategories] = useState([]);

  const [modalCreateProduct, setModalCreateProduct] = useState(false);
  const [modalEditCategory, setModalEditCategory] = useState(false);
  const [modalProduct, setModalProduct] = useState();
  const [modalEditCategoryData, setModalEditCategoryData] = useState();

  const getCategories = useCallback(async () => {
    const response = await api.get('/restaurants/products');

    setCategories(response.data);
  }, []);

  async function move(from_category_id, to_category_id, from, to) {
    try {
      await api.put('/restaurants/product-categories-order', {
        from_product_category_id: from_category_id,
        to_product_category_id: to_category_id,
        to_position: to,
        from_position: from,
      });
      setCategories(
        produce(categories, (draft) => {
          const dragged = draft[from];

          draft.splice(from, 1);
          draft.splice(to, 0, dragged);
        })
      );
      toast.success('Posição Atualizada com sucesso!');
      getCategories();
    } catch (err) {
      toast.error('Erro ao atualizar posição da categoria');
      getCategories();
    }
  }

  function toggleModalCreateProduct() {
    setModalCreateProduct(!modalCreateProduct);
  }

  function toggleModalEditCategory() {
    setModalEditCategory(!modalEditCategory);
  }

  function handleChangeModalProduct(category) {
    setModalProduct(category);
    setModalCreateProduct(!modalCreateProduct);
  }

  function handleChangeModalEditCategory(category) {
    setModalEditCategoryData(category);
    setModalEditCategory(!modalEditCategory);
  }

  useEffect(() => {
    try {
      getCategories();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getCategories]);

  async function handleDelete(id) {
    try {
      if (window.confirm('Tem certeza que deseja deletar este produto?')) {
        await api.delete(`/restaurants/products/${id}`);

        getCategories();

        toast.success('Produto deletado com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar produto');
    }
  }

  async function handleChangeCategory(value, event, idx) {
    const id = Number(idx);
    try {
      await api.put(`restaurants/product-category-available/${id}`, {
        available: value,
      });
      toast.success('Categoria alterada com sucesso!');
      getCategories();
    } catch (err) {
      toast.error('Erro ao ativar/ desativar categoria!');
    }
  }

  async function handleDeleteCategory(id) {
    try {
      if (window.confirm('Tem certeza que deseja deletar esta Categoria?')) {
        await api.delete(`/restaurants/product-category/${id}`);

        getCategories();

        toast.success('Categoria deletada com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar categoria');
    }
  }

  return (
    <OrderProductCategoryContext.Provider value={{ categories, move }}>
      <Container>
        <Modal
          isOpen={modalCreateProduct}
          toggle={toggleModalCreateProduct}
          size="lg"
        >
          <ModalBody>
            <CreateProduct
              category={modalProduct}
              toggle={toggleModalCreateProduct}
              getCategory={getCategories}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalEditCategory}
          toggle={toggleModalEditCategory}
          size="lg"
        >
          <ModalBody>
            <EditCategory
              category={modalEditCategoryData}
              toggle={toggleModalEditCategory}
              getCategories={getCategories}
            />
          </ModalBody>
        </Modal>
        <ProductCategoryHeader getCategories={getCategories} />

        {categories &&
          categories.length > 0 &&
          categories.map((category, index) => (
            <CategoryItem
              key={v4()}
              category={category}
              getCategories={getCategories}
              user={user}
              handleChangeModalEditCategory={handleChangeModalEditCategory}
              handleChangeCategory={handleChangeCategory}
              handleDelete={handleDelete}
              handleChangeModalProduct={handleChangeModalProduct}
              handleDeleteCategory={handleDeleteCategory}
              index={index}
            />
          ))}
      </Container>
    </OrderProductCategoryContext.Provider>
  );
}

export default ProductCategory;
