import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import ReactToPrint from 'react-to-print';
import { AiOutlinePrinter } from 'react-icons/ai';

import { MdDelete, MdEdit } from 'react-icons/md';

import {
  Container,
  Header,
  StyledLink,
  TablesTable,
  ProductImage,
  Delete,
  Actions,
  DisplayNoneImprimir,
  Print,
  StatusTag,
} from './styles';

import PrintComponent from '~/components/PrintComponent';
import RegisterButton from '~/components/Buttons/RegisterButton';

import api from '~/services/api';

function Tables() {
  const componentRef = useRef(null);
  const [tables, setTables] = useState([]);

  const getTables = useCallback(async () => {
    const response = await api.get('/restaurants/tables');

    setTables(response.data);
    // setTotalItems(response.data.count);
  }, []);

  useEffect(() => {
    try {
      getTables();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getTables]);

  async function handleDelete(id) {
    try {
      if (window.confirm('Tem certeza que deseja deletar esta mesa?')) {
        await api.delete(`/restaurants/tables/${id}`);

        getTables();

        toast.success('Mesa deletada com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar mesa');
    }
  }

  return (
    <Container>
      <Header>
        <div>
          <h2>Mesas</h2>
          <p>Gerencie as mesas de seu estabelecimento</p>
        </div>
        <StyledLink to="/tables/new">
          <RegisterButton>Adicionar</RegisterButton>
        </StyledLink>
      </Header>

      <TablesTable borderless>
        <thead>
          <tr>
            <th>Número</th>
            <th>Status</th>
            <th>Código</th>
            <th>QRCode</th>
            <th>Imprimir</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {tables.map((table) => (
            <tr key={table.id}>
              <td>{table?.table_number}</td>
              <td>
                {table.status === 'ongoing' ? (
                  <StatusTag color="#ffc107">
                    <span>Em Uso</span>
                  </StatusTag>
                ) : (
                  <StatusTag color="green">
                    <span>Disponível</span>
                  </StatusTag>
                )}
              </td>
              <td>{table.table_code ? table.table_code.key : 'Sem código'}</td>
              <td>
                {table.table_code ? (
                  <ProductImage
                    src={table.table_code.url}
                    alt="Foto do QRCode"
                  />
                ) : (
                  <p>Sem QRCode</p>
                )}
              </td>
              <td>
                <ReactToPrint
                  trigger={() => (
                    <Print>
                      <AiOutlinePrinter color="white" size={20} />
                    </Print>
                  )}
                  content={() => componentRef.current}
                />

                <DisplayNoneImprimir>
                  <PrintComponent ref={componentRef}>
                    {table.table_code ? (
                      <img src={table.table_code.url} alt="Qrcode" />
                    ) : (
                      <p>Nenhum QRCode para imprimir</p>
                    )}
                  </PrintComponent>
                </DisplayNoneImprimir>
              </td>
              <td>
                <Actions>
                  <StyledLink
                    to={{
                      pathname: 'tables/edit',
                      state: {
                        table,
                      },
                    }}
                  >
                    <MdEdit size={20} />
                  </StyledLink>
                  <Delete onClick={() => handleDelete(table.id)}>
                    <MdDelete size={20} />
                  </Delete>
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </TablesTable>
      {/* <Pagination
        hideFirstLastPages
        activePage={page}
        itemsCountPerPage={6}
        totalItemsCount={totalItems}
        pageRangeDisplayed={(totalItems / 6) * 2}
        onChange={(e) => handleChangePage(e)}
      /> */}
    </Container>
  );
}

export default Tables;
