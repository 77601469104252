import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReactToPrint from 'react-to-print';
import { toast } from 'react-toastify';
import { AiOutlinePrinter, AiOutlineEye } from 'react-icons/ai';
import { FaHands, FaCashRegister  } from 'react-icons/fa';
import { Container, Modal, ModalBody, ModalFooter } from 'reactstrap';
import {
  Pedido,
  OrderNumber,
  Time,
  ContentBody,
  PhoneNumber,
  Actions,
  DeleteAndEditActions,
  SeeButton,
  TableNumber,
  InsertPaymentButton,
  DeliveryType,
  BuyerName,
  DataFormated
} from '../styles';
import { Print, DisplayNoneImprimir, ModalPaymentBody } from './styles';
import PaymentForm from '~/components/PaymentForm';
import api from '~/services/api';

import formatTime from '~/services/formatTime';
import formatDate from '~/services/formatDate';
// import formatPhone from '~/services/formatPhoneString';

import EyeModalOrders from '~/components/EyeModalOrders';
import PrintNewOrderComponent from '~/components/PrintNewOrderComponent';

import { useAuth } from '~/context/AuthContext';

export default function OrderFinished({ item, handleUpdate }) {
  const [paid, setPaid] = useState(0);
  const [left, setLeft] = useState(0);
  const [porcent, setPorcent] = useState(0);
  const [payments, setPayments] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalPaymentForm, setModalPaymentForm] = useState(false);
  function togglePaymentForm() {
    setModalPaymentForm(!modalPaymentForm);
  }

  const { user } = useAuth();
  const componentRef = useRef(null);

  // const userName = formatPhone(item.buyer.phone);
  const userName = item.buyer.phone;
  let paymentStatus = '';

  if (
    item.payment_status === 'paid' ||
    item.payment_status === 'completed' ||
    item.payment_status === 'succeeded'
  ) {
    paymentStatus = 'Pago';
  } else if (item.payment_status === 'canceled') {
    paymentStatus = 'Cancelado';
  } else if (item.payment_status === 'expired') {
    paymentStatus = 'Expirado';
  } else if (item.payment_status === 'waiting_payment' || 'pending') {
    paymentStatus = 'Pagamento Pendente';
  }

  const getPayments = useCallback(async () => {
    try {
      const response = await api.get(`restaurants/payments/${item.id}`);

      const parsedData = response.data.map((data) => ({
        id: data.id,
        value: data.payment_value,
        payment_method: data.payment_method.name,
      }));
      setPayments(parsedData);
           
      const total = response.data.reduce((acum, curr) => acum + Number(curr.payment_value) , 0);
      setPaid(total);

      const localLeft = item.total_price_with_sms - total;
      setLeft(parseFloat(localLeft.toFixed(2)));

      const porcentPaid = (total / item.total_price_with_sms) * 100;
      setPorcent(parseFloat(porcentPaid.toFixed(2)));
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [item.id, item.total_price_with_sms, porcent]);

  useEffect(() => {
    try {

      getPayments();
    
    } catch (error) {
      toast.error('Erro ao carregar informações');
    }
  }, [getPayments, item]);

  return (
    <Pedido>
      {console.log(item)}
      <ContentBody>
        <OrderNumber>#{item.attendance_password.password_number}</OrderNumber>
        <TableNumber>
          <strong>{`${paymentStatus} ${porcent}%`}</strong>
         
        </TableNumber>
        <Time>{formatTime(item.start_time)}</Time>
        <DataFormated>{formatDate(item.start_time)}</DataFormated>
        <BuyerName>{item.buyer.name}</BuyerName>
        <PhoneNumber>{userName}</PhoneNumber>
        <DeliveryType>{item.delivery_type === 'delivery' ? 'Delivery' : 'Retirar no balcão'}</DeliveryType>
        <Actions>
          <DeleteAndEditActions>
            <SeeButton onClick={toggle}>
              <div>
                <AiOutlineEye size={17} style={{ marginRight: 3 }} />
                Ver
              </div>
            </SeeButton>
            <InsertPaymentButton onClick={togglePaymentForm}>
              <div>
              <FaCashRegister size={17} style={{marginRight: 3}} />
              Caixa
              </div>             
            </InsertPaymentButton>
          </DeleteAndEditActions>
        </Actions>
      </ContentBody>

      <Modal isOpen={modalPaymentForm} toggle={togglePaymentForm} size="lg">
            <ModalBody>
              <ModalPaymentBody>
                <PaymentForm
                  session={item}
                />
                
              </ModalPaymentBody>
            </ModalBody>
          </Modal>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeModalOrders item={item} />
          </Container>
        </ModalBody>
        <ModalFooter>
          <ReactToPrint
            trigger={() => (
              <Print>
                <AiOutlinePrinter color="black" size={20} />
              </Print>
            )}
            content={() => componentRef.current}
          />
        </ModalFooter>
      </Modal>
      <DisplayNoneImprimir>
        <PrintNewOrderComponent
          restaurant={user}
          item={item}
          ref={componentRef}
          paymentStatus={paymentStatus}
        />
      </DisplayNoneImprimir>
    </Pedido>
  );
}
