// The idea of this function is to verify whether the current platform is mobile (mainly android) or not. Theres no standard way of doing that.
// Useful links:
// https://coderwall.com/p/i817wa/one-line-function-to-detect-mobile-devices-with-javascript
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent

const isMobile = function() {
  if (navigator.userAgent.includes('Android')) return true;
  if (navigator.platform.includes('Android'))  return true;
  return (typeof window.orientation !== "undefined") ||
         (navigator.userAgent.indexOf('IEMobile') !== -1);
};

export default isMobile;

