import React, {
  createContext,
  useState,
  useContext,
} from 'react';

import { toast } from 'react-toastify';

import { useAuth } from '~/context/AuthContext';
import { useOrder } from '~/context/orderContext';
import { useNotification } from '~/context/notificationContext';

import isMobile from '~/utils/isMobile';

import { onMessage, isSupported } from '../../firebaseInit';

const FCMContext = createContext();

const is_mobile = isMobile();

const FCMProvider = ({ children }) => {
  const { getAllOrders } = useOrder();
  const { changeOpenStatus } = useAuth();
  const { sendNotification } = useNotification();

  const [listening, setListening] = useState(false);

  if (!listening) {
    if (!isSupported) {
      setListening(true);
      return;
    }

    if (is_mobile) {
      if (('serviceWorker' in navigator) && navigator.serviceWorker) {
        navigator.serviceWorker.ready.then((registration) => {

          //console.log('sw ready', registration);
          navigator.serviceWorker.onmessage = (payload) => {
            //console.log('sw firebase msg', payload);
            const { data } = payload.data;
            const icon = './favicon.png';

            if (data.type === 'new-order') {
              const notificationTitle = 'Um novo pedido chegou em seu restaurante!';
              const notificationOptions = { body: data.text, icon };
              registration.showNotification(notificationTitle, notificationOptions);
            }
            else if (data.type === 'notification') {
              const notificationTitle = 'Solicitação de fechamento de conta!!';
              const notificationOptions = { body: data.text, icon };
              registration.showNotification(notificationTitle, notificationOptions);
            }

          }
        });

        setListening(true);
      }
      return;
    }

    onMessage((payload) => {
      //console.log('firebase msg', payload);

      const { data } = payload;

      if (data.type === 'new-order') {
        getAllOrders();
        sendNotification({
          title: 'Um novo pedido chegou em seu restaurante! ',
          body: data.text,
          //tag: 'new-order',
        });
      }
      else if (data.type === 'restaurant-open') {
        const info = JSON.parse(data.notification);
        changeOpenStatus(info);

        toast.info(
          <p>
            <b>Seu restaurante foi aberto.</b> <br/>
            Esta página será recarregada em 10 segundos.
          </p>, {
          autoClose: 10000,
        });

        setTimeout(() => { window.location.reload(true); }, 10000);
      }
      else if (data.type === 'restaurant-close') {
        const info = JSON.parse(data.notification);
        changeOpenStatus(info);

        toast.info(
          <p>
            <b>Seu restaurante foi fechado.</b> <br/>
            Esta página será recarregada em 10 segundos.
          </p>, {
          autoClose: 10000,
        });

        setTimeout(() => { window.location.reload(true); }, 10000);
      }
    });

    setListening(true);
  }

  return <FCMContext.Provider> {children} </FCMContext.Provider>;
};

function useSocket() {
  const context = useContext(FCMContext);

  if (!context) {
    throw new Error('useSocket must be used within an SocketioProvider');
  }

  return context;
}

export { FCMProvider, useSocket };
